import React from "react";
import GTM from "../../GTM";

const labels = {
    firstName: "First Name",
    lastName: "Last Name",
    ssn: "SSN",
    claimNumber: "Claim Number",
    leaveNumber: "Leave Number",
    eeid: "EEID",
};

function EmployeeSearchFilterTags({
    filters,
    removeFilter,
    relatedOrganizations,
}) {
    return (
        <div
            className="tags"
            style={{
                marginBottom: relatedOrganizations.length > 0 ? 20 : 0,
                marginLeft: !relatedOrganizations.length > 0 ? 16 : 0,
            }}
        >
            {Object.keys(filters)
                .filter((k) => filters[k] != "")
                .map((key) => (
                    <Tag
                        key={key}
                        index={key}
                        removeFilter={removeFilter}
                        filters={filters}
                        label={labels[key]}
                        value={filters[key]}
                    />
                ))}
        </div>
    );
}

function Tag({ index, removeFilter, label, value, filters }) {
    const gtm = new GTM(null);

    return (
        <span
            className="tag px-10p py-5p  border-1 br-4p"
            style={{
                marginLeft:
                    Object.entries(filters).filter(
                        ([keys, value]) => value !== ""
                    ).length > 1
                        ? 16
                        : 0,
            }}
            tabIndex={0}
        >
            <span>
                {label}:<b> {value}</b>
            </span>

            <a
                onClick={() => {
                    removeFilter(index);
                    gtm.Event(
                        "searching an employee from an organization",
                        "button",
                        "click",
                        "close",
                        "employee search",
                        "",
                        "close_filter"
                    );
                }}
                className="cursor-pointer ml-10p text-secondary"
            >
                <i className="fa-solid fa-xmark"></i>
            </a>
        </span>
    );
}

export default EmployeeSearchFilterTags;
