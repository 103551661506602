import React, { useEffect } from "react";
import { useGetEmployeeLeaveDetailsQuery } from "../../slices/employeesApiSlice";
import LeaveSummary from "./LeaveSummary";
import { useDispatch } from "react-redux";
import { addRelatedNos } from "./../../slices/leaveClaimRecordsSlice";
import { recordsType } from "../../constants";
import LeaveRecordDetails from "./LeaveRecordDetails";
import RequestFailed from "../../components/alerts/RequestFailed";
import Loader from "../../components/ui/Loader";
import NoRecords from "../../components/alerts/NoRecords";
import { logout } from "./../../slices/authSlice";

function LeaveRecord({
    leaveNo,
    organizationId,
    organizationAccess,
    employeeId,
    print,
    fetchingLeave, // Callback prop from parent
}) {
    const dispatch = useDispatch();

    const {
        data: employeeLeaveDetails = null,
        isError,
        isLoading,
        isFetching,
        isUninitialized,
        error,
    } = useGetEmployeeLeaveDetailsQuery({
        leaveNo,
        organizationId,
        employeeId,
    });

    useEffect(() => {
        if (error && error.status === 401) {
            dispatch(logout());
        }
    }, [error]);

    useEffect(() => {
        if (employeeLeaveDetails === null) {
            return;
        }

        const leaveSummary =
            employeeLeaveDetails.LeaveSummary.length === 1
                ? employeeLeaveDetails.LeaveSummary[0]
                : null;

        if (leaveSummary && leaveSummary.RelatedSTDClaims) {
            const relatedSTDClaims = leaveSummary.RelatedSTDClaims.split(",");

            dispatch(
                addRelatedNos({
                    nos: relatedSTDClaims,
                    type: recordsType.CLAIM,
                })
            );
        }
    }, [employeeLeaveDetails]);

    useEffect(() => {
        if (!print) fetchingLeave(isFetching); // Call the callback function
    }, [isFetching]);

    return (
        <div id={"leave-container-" + leaveNo}>
            {isFetching && <Loader loading={isLoading} />}
            {isError && <RequestFailed label="leave error" />}

            {!isUninitialized &&
                !isFetching &&
                !isError &&
                employeeLeaveDetails.Header.length === 0 && (
                    <NoRecords
                        attribute1="claim leave"
                        attribute3="claimleave_error"
                        message="No Records Found"
                        label="leave record"
                    />
                )}

            {employeeLeaveDetails !== null &&
                !isFetching &&
                !isError &&
                employeeLeaveDetails.Header.length > 0 && (
                    <>
                        <LeaveSummary
                            leaveDetailsEmp={employeeLeaveDetails}
                            organizationAccess={organizationAccess}
                        />

                        <LeaveRecordDetails
                            leaveDetailsEmp={employeeLeaveDetails}
                            print={print}
                            leaveNo={leaveNo}
                        />
                    </>
                )}
        </div>
    );
}

export default React.memo(LeaveRecord);
