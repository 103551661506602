import React from "react";

function SectionSubheading({ className, subheading, ...rest }) {
    return (
        <div {...rest}>
            <h3 className={"section-subheading  " + className} tabIndex={0}>
                {subheading}
            </h3>
        </div>
    );
}

export default SectionSubheading;
