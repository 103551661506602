import moment from "moment";
import config from "../config";
import { noDataPlaceholder, dateFormat } from "../constants";
import logos from "../constants/index";

export function createEnum(values) {
    const enumObject = {};

    for (const val of values) {
        enumObject[val] = val;
    }

    return Object.freeze(enumObject);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

export function unixTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    return moment(date).format("MM/DD/YYYY hh:mm A");
}

export function dateToLocalTimestamp(timestamp) {
    const date = new Date(timestamp);
    return moment(date).local()._d;
}

export function formatHours(hour) {
    if (!hour || isNaN(hour)) return "";
    return parseFloat(hour).toFixed(2);
}

export function numberFormat(value) {
    if (value === null) return "$0.00";

    if (value !== null && isNaN(value)) return value;

    if (typeof value === "string") value = parseFloat(value);

    let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return formatter.format(value);
}

export const sort = (rows, sortBy, asc = true) => {
    const rowsToSort = [...rows];

    return rowsToSort.sort((rowA, rowB) => {
        const aField = rowA[sortBy].toLowerCase();
        const bField = rowB[sortBy].toLowerCase();

        let comparison = 0;

        if (aField > bField) {
            comparison = 1;
        } else if (aField < bField) {
            comparison = -1;
        }

        return asc ? comparison : comparison * -1;
    });
};

export const removeLeadingZeros = (numString) => {
    const n = parseInt(numString);
    return n.toString();
};

export const formatDate = (date) => {
    const d = moment(date);

    if (d.isValid()) {
        return d.format(dateFormat);
    }

    return date;
};

export const checkNullValue = (dataProperty, placeHolder) => {
    if (dataProperty != null && dataProperty != "") return dataProperty;
    else return placeHolder;
};

export const formattedDate = (dateobject, _noDataPlaceholder) => {
    return dateobject
        ? moment(dateobject).format("MM/DD/YYYY")
        : _noDataPlaceholder;
};

export const checkNull = (dateobject, _noDataPlaceholder) => {
    return dateobject ? dateobject : _noDataPlaceholder;
};

export const checkWhiteSpace = (dateobject, noDataPlaceholder) => {
    return dateobject === " " || null ? noDataPlaceholder : dateobject;
};

export const getLogoutRedirectUrl = (user, returnUrl) => {
    if (user?.isUserInternal) {
        // Internal user login page redirect URL
        return config.REACT_APP_ISERVICES + ":81/LanAuthentication";
    }

    if (returnUrl) return returnUrl;

    // Default Login Page - iServices
    return config.REACT_APP_ISERVICES;
};

export const separateByDelimiter = (arr, delimiter) => {
    if (arr != null) {
        let a = arr.split(delimiter);
        return a.join(" " + delimiter + " ");
    } else return "";
};

export const formatClaimDate = (d) => {
    return d ? moment(d).format(dateFormat) : noDataPlaceholder;
};

export const filterDuplicatesFromArray = (arr) => {
    return arr.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
};

export const sortedRelatedOrgsArray = (arr) => {
    const options = [];
    arr?.forEach((item) => {
        const option = {
            value: item.ORGANIZATIONID,
            label: item.ORGNIZATIONNAME,
        };

        options.push(option);
    });

    return [...options].sort((a, b) => a.label.localeCompare(b.label));
};

export const getOrganizationLogo = (organizationName) => {
    let show = false;
    let logo = null;

    if (typeof organizationName === "string") {
        const name = organizationName.toLowerCase();

        // Costco
        if (name.includes("costco")) {
            show = true;
            logo = logos.costco;
        }
    }

    return {
        show,
        logo,
    };
};

export const getReportBreadcrumbLinks = (org, emp) => {
    const links = [
        {
            path: "/search",
            name: "Search",
        },
        {
            path: "/reports",
            name: "Reports",
        },
    ];

    if (org !== null) {
        const orgId = org.ORGANIZATIONID;

        const organizationLink = {
            path: `/organization/${orgId}/employees/search`,
            name: "Employee",
        };

        links.splice(1, 0, organizationLink);

        if (emp !== null && emp.path && emp.organizationId == orgId) {
            const employeeLink = {
                path: emp.path,
                name: "Claim & Leave",
            };

            links.splice(2, 0, employeeLink);
        }
    }

    return links;
};

export const getApiUrl = () => {
    const persistRoot = JSON.parse(localStorage.getItem("persist:root"));

    if (persistRoot) {
        const authData = JSON.parse(persistRoot.auth);
        const isInternal = authData.user?.isUserInternal;

        return isInternal
            ? config.REACT_APP_API_URL
            : config.REACT_APP_EXTERNAL_PREFERENCE_API_URL;
    }
};

export const getDisclaimerDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const hour = today.getHours();
    const minute = (today.getMinutes() < 10 ? "0" : "") + today.getMinutes();
    const time = timeConversion(hour, minute);
    return ` ${month}/${date}/${year}  ${time}`;
};

export const timeConversion = (hour, minute) => {
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour ? hour : 12;
    return `${hour}:${minute} ${ampm}`;
};

export const getPreviousDays = (date, day) => {
    return moment(date).subtract(day, "days").format("YYYY-MM-DD");
};

export const getPreviousDaysStart = (date, duration) => {
    return moment(date)
        .subtract(1, duration)
        .startOf(duration)
        .format("YYYY-MM-DD");
};

export const getPreviousDaysEnd = (date, duration) => {
    return moment(date)
        .subtract(1, duration)
        .endOf(duration)
        .format("YYYY-MM-DD");
};

export const getPresentDayFormat = (date) => {
    return moment(date).format("YYYY-MM-DD");
};

export const getDateTimeFormate=(date)=>{
    return (        
         moment(date).format("YYYY-MM-DD")  + "T00:00:00Z"         
        );
}

export const getPreviousDaysWithTimeFormat = (date, day) => {
    return (
        moment(date).subtract(day, "days").format("YYYY-MM-DD") + "T00:00:00Z"
    );
};

export const isCostcoUser = (userSelectedOrganizations, id) => {
    const organizationName =
        userSelectedOrganizations[
            id
        ]?.related[0]?.ORGANIZATIONGROUPNAME?.toUpperCase();
    return organizationName?.includes("COSTCO");
};

export function getSelectedIsfOrganizationIds(
    userSelectedOrganizationReports,
    reportId,
    selectedRelatedOrganizations,
    totalParentOrganizations
) {
    let selectedIsfOrganizationIds = [];
    let organizationGroupId = null;
    let relatedOrganizationNames = null;
    let organizationName = null;

    if (userSelectedOrganizationReports[reportId]?.related.length > 0 && totalParentOrganizations !== 1) {
        organizationName =
            userSelectedOrganizationReports[reportId]?.organization
                .ORGNIZATIONNAME;
        // Check if selectedRelatedOrganizations is an array before calling map
        if (Array.isArray(selectedRelatedOrganizations)) {
            selectedIsfOrganizationIds = selectedRelatedOrganizations.map(
                (org) => org.organizationIDISF
            );
            let labels = selectedRelatedOrganizations.map((item) => item.label);
            relatedOrganizationNames = labels.join(", ");
            organizationGroupId =
                selectedRelatedOrganizations[0]?.organizationGroupId;
            organizationName =
                userSelectedOrganizationReports[reportId].organization
                    .ORGANIZATIONGROUPNAME;
        }
    } else {
        selectedIsfOrganizationIds = [
            userSelectedOrganizationReports[reportId]?.organization
                ?.ORGANIZATIONIDISF,
        ];
        organizationName =
            userSelectedOrganizationReports[reportId]?.organization
                ?.ORGNIZATIONNAME;
    }

    return {
        selectedIsfOrganizationIds,
        organizationGroupId,
        relatedOrganizationNames,
        organizationName,
    };
}
export const disabilityCoverage = [
    { value: "STD", label: "STD" },
    { value: "SI-STD", label: "SI-STD" },
    { value: "LTD", label: "LTD" },
    { value: "SI-LTD", label: "SI-LTD" },
];
export const paymentCoverage = [
    { value: "STD", label: "STD" },
    { value: "SI-STD", label: "SI-STD" },
    { value: "LTD", label: "LTD" },
    { value: "SI-LTD", label: "SI-LTD" },
];
export const absenceCoverage = [
    { value: "STD", label: "STD" },
    { value: "SI-STD", label: "SI-STD" },
    { value: "LTD", label: "LTD" },
    { value: "SI-LTD", label: "SI-LTD" },
    { value: "Leave", label: "Leave" },
];
export const leaveCoverage = [{ value: "Leave", label: "Leave" }];
export const lifeCoverage = [{ value: "Life", label: "Life" }];
export const disabilityStatus = [
    { value: "New", label: "New" },
    { value: "Open", label: "Open" },
    { value: "Closed", label: "Closed" },
];
export const paymentStatus = [
    { value: "Paid", label: "Paid" },
    { value: "Reversal", label: "Reversal" },
    { value: "Stop", label: "Stop" },
    { value: "Repayment", label: "Repayment" },
    { value: "Advised", label: "Advised" },
    { value: "Active", label: "Active" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Replaced", label: "Replaced" },
];
export const LAST_7_DAYS = "Last 7 days";
export const PREVIOUS_DAY = "Previous day";
export const PREVIOUS_MONTH = "Last month ";
export const PREVIOUS_QUARTER = "Last quarter ";
export const CUSTOM_DATE = "Date range  ";
export const DATAVIEWTYPE = "Absence,Disability,Life,Payments,Leave,Changes";
export const STANDARD_REPORT_DATE_TIME = "0001-01-01T00:00:00";
export const INCLUDE_HISTORICAL_LEAVE = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
];
export const PERIOD_SCHEDULE_TYPE = [
    { value: "Continuous", label: "Continuous" },
    { value: "Intermittent", label: "Intermittent" },
    { value: "Reduced", label: "Reduced" },
];
export const PERIOD_STATUS = [
    { value: "Approved", label: "Approved" },
    { value: "Cancelled", label: "Cancelled" },
    {
        value: "Fully Adjudicated - Approved",
        label: "Fully Adjudicated - Approved",
    },
    {
        value: "Fully Adjudicated - Denied",
        label: "Fully Adjudicated - Denied",
    },
    {
        value: "Fully Adjudicated - Mixed Plan Decisions",
        label: "Fully Adjudicated - Mixed Plan Decisions",
    },
    { value: "In Review", label: "In Review" },
    { value: "Partially Adjudicated", label: "Partially Adjudicated" },
    { value: "Pending", label: "Pending" },
    { value: "Withdrawn", label: "Withdrawn" },
];

export const PROTECTION_STATUS = [
    { value: "Approved", label: "Approved" },
    {
        value: "Pending",
        label: "Pending",
        subStatuses: [
            { value: "Pending Evidence", label: "Pending Evidence" },
            { value: "Accepted", label: "Accepted" },
            { value: "Designated: Accepted", label: "Designated: Accepted" },
            { value: "Undecided", label: "Undecided" },
            { value: "Pending", label: "Pending" },
        ],
    },
    {
        value: "Denied",
        label: "Denied",
        subStatuses: [
            { value: "Denied", label: "Denied" },
            { value: "Rejected", label: "Rejected" },
            { value: "Designated: Rejected", label: "Designated: Rejected" },
        ],
    },
    { value: "Approved - Non Count", label: "Approved - Non Count" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Denied - ADA", label: "Denied - ADA" },
    { value: "Inactive - ADA", label: "Inactive - ADA" },
];
export const LAST_7_DAYS_LABEL = "Last 7 days ";
export const OWNER_TYPE = "Standard";
export const TODAY = "Today";
export const PRIOR_BUSINESS_DAY = "Prior Business Day";
export const ONE_WEEK = "1 Week";
export const getPreviousMonthWithTimeFormat = (date) => {    
    return (        
    moment(date,"MM/DD/YYYY").utc().format('YYYY-MM-DD')  + "T00:00:00Z"         
    );
};
export const getButtonDisabledStatus = (viewNameChange,viewNameValue,descriptionChange,descriptionValue) => {    
    const isViewNameValid =
        viewNameChange &&
        viewNameValue?.length >= 4 &&
        viewNameValue?.length <= 50;
    const isDescriptionValid =
        descriptionChange &&
        descriptionValue?.length >= 4 &&
        descriptionValue?.length <= 100;
    return !(isViewNameValid && isDescriptionValid);
};