import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import appSlice from "./slices/appSlice";
import apiSlice from "./slices/apiSlice";
import historySlice from "./slices/historySlice";
import userApiSlice from "./slices/userApiSlice";
import enterpriseInformationApiSlice from "./slices/enterpriseInformationApiSlice";
import userPreferenceApiSlice from "./slices/userPreferenceApiSlice";
import tableauUserApiSlice from "./slices/tableauUserApiSlice";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import leaveClaimRecordsSlice from "./slices/leaveClaimRecordsSlice";
import organizationSlice from "./slices/organizationSlice";
import viewReportSlice from "./slices/viewReportSlice";
import preferenceSlice from "./slices/preferenceSlice";
import userSlice from "./slices/userSlice";
import SetTransform from "./transforms/SetTransform";
import saveReportViewSlice from "./slices/saveReportViewSlice";
import sessionSlice from "./slices/sessionSlice";
import helpContentSlice from "./slices/helpContentSlice";

// Add apiSlice.reducerPath to the blacklist to disable client side API caching

const persistConfig = {
    key: "root",
    storage,
    blacklist: [
        userApiSlice.reducerPath,
        "leaveClaimRecords",
        "app",
        "userDetails",
        "preferenceSlice",
        "auth",
        "saveReportView",
        userPreferenceApiSlice.reducerPath,
        enterpriseInformationApiSlice.reducerPath,
        "helpContent",
        "reportOrganization"
    ],
    transforms: [SetTransform],
};
// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

const reducers = combineReducers({
    auth: authSlice,
    api: apiSlice.reducer,
    enterpriseInformationApi: enterpriseInformationApiSlice.reducer,
    UserPreferences: userPreferenceApiSlice.reducer,
    preferenceSlice: preferenceSlice,
    UserReports: tableauUserApiSlice.reducer,
    history: historySlice,
    leaveClaimRecords: leaveClaimRecordsSlice,
    app: appSlice,
    userApi: userApiSlice.reducer,
    organizations: organizationSlice,
    reportOrganization: viewReportSlice,
    userDetails: userSlice,
    saveReportView: saveReportViewSlice.reducer,
    sessionSlice: sessionSlice.reducer,
    helpContent: helpContentSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        })
            .concat(
                apiSlice.middleware,
                enterpriseInformationApiSlice.middleware
            )
            .prepend(listenerMiddleware.middleware);
    },
});

export default store;
