import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config";
import { cacheDataForInSeconds } from "../constants";

const apiSlice = createApi({
    reducerPath: "api",
    keepUnusedDataFor: cacheDataForInSeconds ?? 300,
    refetchOnMountOrArgChange: cacheDataForInSeconds ?? 300,
    baseQuery: fetchBaseQuery({
        baseUrl: config.REACT_APP_API_URL,
        credentials: "include",
        prepareHeaders: (headers) => {
            headers.set("Accept", "application/json");
            headers.set("Content-Type", "application/json");

            const apiKey = process.env.REACT_APP_API_KEY;
            if (apiKey) headers.set("apikey", apiKey);

            return headers;
        },
    }),
    endpoints: () => ({}),
});

export default apiSlice;
