class Employee {
    _format = (data) => {
        return {
            Organization: data.OrganizationName,
            OrganizationId: data.OrganizationId,
            PolicyId: "",
            SSN: data.SSN,
            EEID: data.EmployeeID,
            OpenLeave: data.OpenLeaveNumber,
            OpenClaim: data.OpenClaimNumber,
            Coverage: data.Coverage,
            LeaveReason: data.LeaveReason,
            FirstName: data.FirstName,
            MiddleName: data.MiddleName,
            LastName: data.LastName,
            DateOfBirth: data.DateOfBirth,
            IsClaimOrLeave: data.IsClaimOrLeave,
            SSNState: "TRUNCATED",
            MasterPartyId: data.MasterPartyId,
        };
    };

    format = (data) => {
        const list = [];

        data.forEach((el) => {
            list.push(this._format(el));
        });

        return list;
    };
}

export default new Employee();
