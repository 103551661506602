import React, { useEffect } from "react";
import "./SearchView.css";
import { useSelector, useDispatch } from "react-redux";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import OrganizationSearch from "./OrganizationSearch";
import RequestFailed from "../../components/alerts/RequestFailed";
import { clearHistory } from "../../slices/historySlice";
import MyProgressBar from "../../components/ProgressBar";
import GTM from "../../GTM";

function SearchOrganization(props) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { config } = useSelector((state) => state.app);

    const {
        parentOrganizations: organizations,
        policies,
        loaded,
        isLoading,
        error,
    } = useSelector((state) => state.userDetails);

    let previousUrls = props.previousUrl;
    let page_referrer = previousUrls[previousUrls.length - 1];

    const gtm = new GTM(null);
    let employeePage = /organization\/[^\/]+\/employees\/search/s;
    let claimLeavePage = /organization\/[^\/]+\/employees\/[^\/]+/gm;

    const page_referrer_url =
        page_referrer === "/login"
            ? config.REACT_APP_ISERVICES + ":81/LanAuthentication"
            : config.REACT_APP_BASE_URL + page_referrer;

    let page_referrer_title = "";

    if (user.isUserInternal && String(page_referrer)?.includes("/login")) {
        page_referrer_title = "internal";
    } else if (
        String(page_referrer_url)?.match(employeePage) !== null &&
        !String(page_referrer_url)?.includes("/login")
    ) {
        page_referrer_title = "employee search";
    } else if (String(page_referrer_url)?.match(claimLeavePage) !== null) {
        page_referrer_title = "claim & leave";
    } else if (
        (String(page_referrer)?.includes("/login") &&
            String(page_referrer_url)?.includes("uaservices")) ||
        String(page_referrer_url)?.includes("services")
    ) {
        page_referrer_title = "iservices";
    } else {
        page_referrer_title = "search";
    }

    useEffect(() => {
        const userName = "";
        const userId = user ? user.userId : "";
        const userRole = user.isUserInternal === true ? "internal" : "external";

        gtm.PageView(
            "search",
            userName,
            userId,
            userRole,
            window.location.href,
            `${config.REACT_APP_BASE_URL + page_referrer}`,
            page_referrer_title,
            ""
        );
    }, [10000]);

    const stateHistory = useSelector((state) => state.history);

    useEffect(() => {
        // Clear Employee Search Persisted State
        if (Object.keys(stateHistory).length > 0) dispatch(clearHistory());
    }, []);

    return (
        <>
            <Breadcrumb
                links={[
                    {
                        name: "Search",
                    },
                ]}
            />

            {isLoading && <MyProgressBar text="Loading" />}

            {!isLoading && !loaded && error && (
                <RequestFailed label="search error" />
            )}

            {loaded && (
                <OrganizationSearch
                    organizations={organizations}
                    policies={policies}
                />
            )}
        </>
    );
}

export default SearchOrganization;
