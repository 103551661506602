import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import { useSelector, useDispatch } from "react-redux";
import useOutsideClick from "../../hooks/useOutsideClick";

function Sidebar({ open, closeSidebar, toggleRef }) {
    const { user, returnUrl } = useSelector((state) => state.auth);
    const { config } = useSelector((state) => state.app);
    const sidebarRef = React.useRef(null);
    const sidebarOutsideClick = useOutsideClick(sidebarRef, toggleRef);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (sidebarOutsideClick === true) closeSidebar();
    }, [sidebarOutsideClick]);

    const location = useLocation();
    let url = location.pathname;
    return (
        <div ref={sidebarRef}>
            <div
                className="d-flex flex-column flex-shrink-0 sidebar"
                style={{
                    transform: open ? "translateX(0%)" : "translateX(-100%)",
                    boxShadow: open
                        ? "425px 105px 0px 105px rgba(0, 0, 0, 0.65)"
                        : "",
                }}
            >
                <div className="sidebar-spacer"></div>
                <ul className="nav nav-pills nav-flush flex-column ">
                    <li
                        className={
                            (url.includes("/search") ? "bg-target " : "") +
                            "sidebar-text"
                        }
                        data-testid="Search"
                        onClick={() => {
                            navigate("/search");
                            closeSidebar();
                        }}
                    >
                        Search
                    </li>

                    <li
                        className={
                            (url.includes("/dashboard") ? "bg-target " : "") +
                            "sidebar-text"
                        }
                        data-testid="dashboard"
                        onClick={() => {
                            navigate("/dashboard");
                            closeSidebar();
                        }}
                    >
                        Dashboard
                    </li>
                    {user?.isUserInternal && (
                        <li
                            className="sidebar-text"
                            data-testid="iservices"
                            onClick={() => {
                                const redirectUrl =
                                    config.REACT_APP_ISERVICES +
                                    ":81/LanAuthentication";

                                window.open(redirectUrl, "_blank");
                                closeSidebar();
                            }}
                        >
                            iServices
                        </li>
                    )}
                    {returnUrl !== null &&
                        !user?.isUserInternal &&
                        !returnUrl?.includes("my.unum.com") && (
                            <li
                                className="sidebar-text"
                                onClick={() => {
                                    let redirectUrl = returnUrl;

                                    if (
                                        redirectUrl.includes(
                                            "services.unum.com"
                                        )
                                    ) {
                                        redirectUrl =
                                            config.REACT_APP_ISERVICES +
                                            "/_fwWelc/Welcome.aspx";
                                    }

                                    window.open(redirectUrl, "_blank");
                                    closeSidebar();
                                }}
                            >
                                iServices
                            </li>
                        )}
                    <li
                        className="sidebar-text"
                        data-testid="CLI"
                        onClick={() => {
                            if (user?.isUserInternal) {
                                window.open(
                                    config.REACT_APP_CLI_INTERNAL,
                                    "_blank"
                                );
                            } else {
                                window.open(config.REACT_APP_CLI, "_blank");
                            }
                            closeSidebar();
                        }}
                    >
                        CLI
                    </li>
                    {returnUrl && returnUrl.includes("my.unum.com") && (
                        <li
                            className="sidebar-text"
                            data-testid="MyUnum"
                            onClick={() => {
                                window.location.replace(
                                    config.REACT_APP_MY_UNUM,
                                    "_blank"
                                );
                                closeSidebar();
                            }}
                        >
                            MyUnum For Clients
                        </li>
                    )}

                    <li className=" d-flex sidebar-text">
                        <i className="fa-solid fa-circle-user icon"></i>
                        <div
                            onClick={() => {
                                dispatch(logout());
                                closeSidebar();
                            }}
                        >
                            Sign out | {user?.userName}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
