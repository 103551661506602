import {
    filterDuplicatesFromArray,
    removeLeadingZeros,
} from "../utils/helpers";

class OrganizationRepository {
    getOrganizationById = (organizations, id) => {
        for (let i = 0; i < organizations.length; i++) {
            if (organizations[i].ORGANIZATIONID === id) {
                return organizations[i];
            }
        }

        return null;
    };

    getUniqueOrganizations = (organizations) => {
        const list = [];
        const ids = [];

        organizations.forEach((el) => {
            if (!ids.includes(el.organizationId)) {
                ids.push(el.organizationId);
                list.push(el);
            }
        });

        return list;
    };

    getPolicies = (organizations) => {
        const list = [];

        organizations.forEach((el) => {
            let policyIds = [];
            const organizationId = el.ORGANIZATIONID;

            if (el.POLICYID) {
                policyIds = el.POLICYID.split(",");
            }

            policyIds.forEach((policyId) => {
                list.push({
                    policyId: removeLeadingZeros(policyId),
                    organizationId: organizationId,
                });
            });
        });

        return list;
    };

    getParentOrganizations = (organizations) => {
        const parentOrganizations = [];
        const parentOrganizationIds = [];
        const subOrganizations = [];

        organizations.forEach((org) => {
            parentOrganizationIds.push(org.ORGANIZATIONIDISF);

            if (org.ORGANIZATIONGROUPID) {
                subOrganizations.push({
                    id: org.ORGANIZATIONGROUPID,
                    organization: org,
                });
            } else {
                parentOrganizations.push(org);
            }
        });

        subOrganizations.forEach((subOrg) => {
            if (!parentOrganizationIds.includes(subOrg.id)) {
                parentOrganizations.push(subOrg.organization);
            }
        });

        return parentOrganizations;
    };
    getRelatedOrganizationsFromIds = (organizations, ids) => {
        let selected;
        if (ids !== null) {
            selected = organizations.filter(
                (org) => org.ORGANIZATIONID?.toString() === ids[0]
            );
        }
        if (selected.length === 1 && selected[0].ORGANIZATIONGROUPID === null) {
            return selected;
        }
        return organizations.filter(
            (org) =>
                org?.ORGANIZATIONGROUPID === selected[0]?.ORGANIZATIONGROUPID
        );
    };
    getOrganizationFromISFId = (organizations, isfId) => {
        let selected;

        if (isfId !== null) {
            if (typeof isfId === "string") {
                // Split the string into an array using comma as a delimiter
                isfId = isfId.split(",");
            }

            // Filter organizations that match any of the values in the array
            selected = organizations.filter((org) =>
                isfId.includes(org.ORGANIZATIONIDISF?.toString())
            );
        }

        return selected;
    };

    getRelatedOrganizations = (organizations, selected) => {
        if (selected.ORGANIZATIONGROUPID !== null) {
            return organizations.filter(
                (org) =>
                    org.ORGANIZATIONGROUPID === selected.ORGANIZATIONGROUPID
            );
        }

        return organizations.filter(
            (org) => org.ORGANIZATIONGROUPID === selected.ORGANIZATIONIDISF
        );
    };

    getSelectedOrganizationIds = (selectedOrganizations) => {
        const ids = [];

        for (let key of Object.keys(selectedOrganizations)) {
            const o = selectedOrganizations[key];

            if (o.organization) {
                ids.push(o.organization.ORGANIZATIONID);

                o.related.forEach((r) => {
                    ids.push(r.ORGANIZATIONID);
                });
            }
        }

        return filterDuplicatesFromArray(ids);
    };
}

export default new OrganizationRepository();
