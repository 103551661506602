import React from "react";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import Modal from "react-bootstrap/Modal";

export default function WarningPage({ onShow, onSave ,onDontSave}) {
    const sendSaveEvent = () => {
        onSave();
    };

    const sendDontSave = () => {
        onDontSave();
    };

    return (
        <>
            <Modal
                size="sm"
                show={onShow}
                backdrop="static"
                keyboard={false}
                centered
                className="preference"
                dialogClassName="custom-modal-size"
            >
                <Modal.Header>
                    <SectionHeading heading="Warning" />
                    <i className="fa-solid fa-circle-xmark cursor-pointer" onClick={sendDontSave}></i>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <span data-testid="warning-message">
                            Your settings have not been saved, <br />
                            how do you wish to proceed?
                        </span>
                    </div>
                    <div className="preference-button pt-16p">
                        <div className="d-contents">
                            <Button
                                data-testid="Don't save"
                                text="Don't save"
                                outline={true}
                                onClick={sendDontSave}
                            />

                            <Button text="Save" onClick={sendSaveEvent} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
