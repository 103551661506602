import React, { useDeferredValue, useState, useRef, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useSelector, useDispatch } from "react-redux";
import OrganizationAutocompleteDropdown from "../components/OrganizationAutocompleteDropdown";
import Button from "../components/ui/Button";
import OrganizationRepository from "../repositories/OrganizationRepository";
import { removeLeadingZeros } from "../utils/helpers";
import Select from "react-select";
import OrganizationResult from "../views/searchview/OrganizationResult";
import NoRecords from "../components/alerts/NoRecords";
import PropTypes from "prop-types";
import {
    addSelectedOrganizationForReports,
    setSelectedOrgainzationsIdForReports,
    setSelectedRelatedOrganizationsForReports,
} from "../slices/viewReportSlice";
import {
    setPreferenceChanged,
    setOrganisationPreference,
} from "../slices/preferenceSlice";
import GTM from "../GTM";

const list = [
    { value: "organizationName", label: "Organization" },
    { value: "party/policy", label: "Party ID / Policy Number" },
];

function OrganizationSearchBaseComponent({
    organizations,
    organizationSelected,
    onSelectedRelatedOrganizationsChange,
    allOrganizations,
    organizationPreference,
    totalParentOrganizations,
    userSelectedOrganizations,
    organizationsCount,
    policies,
    clearButtonText,
}) {
    const dispatch = useDispatch();
    const gtm = new GTM(null);
    const { selectedOrganizationsId: id } = useSelector(
        (state) => state.organizations
    );

    const [searchType, setSearchType] = useState(list[0]);
    const [searchValue, setSearchValue] = useState("");
    const deferredSearchValue = useDeferredValue(searchValue);

    const [relatedOrganizations, setRelatedOrganizations] = useState([]);
    const [relatedOrganizationOptions, setRelatedOrganizationOptions] =
        useState([]);
    const [selectedRelatedOrganizations, setSelectedRelatedOrganizations] =
        useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);

    const [dropdownOrganizations, setDropdownOrganizations] = useState([]);
    const organizationInputRef = useRef();
    const [filteredOrganizations, setFilteredOrganizations] = useState([]);
    const [filterTimestamp, setFilterTimestamp] = useState(null);
    const [isFiltered, setIsFiltered] = useState(false);
    const url = window.location.pathname;

    const gtmEvent = (category, action, label, attribute3, attribute4) => {
        gtm.Event(
            url === "/reportingcapabilities" ? "run reports" : "preference",
            category,
            action,
            label,
            "search",
            "",
            attribute3,
            attribute4
        );
    };
    const { dlselectedIsfOrganizationId } = useSelector((state) => state.auth);

    useEffect(() => {
        //Set Orgs drop down values from user preference.
        setOrganizationDrpDownFromPreference();
        if (id in userSelectedOrganizations && totalParentOrganizations === 1) {
            const selectedOrgData = userSelectedOrganizations[id];
            setRelatedOrganizations(selectedOrgData.related);
        }
    }, [id, totalParentOrganizations, userSelectedOrganizations]);

    useEffect(() => {
        if (dlselectedIsfOrganizationId.length > 0) {
            const dlSelectedOrganization =
                OrganizationRepository.getOrganizationFromISFId(
                    allOrganizations,
                    dlselectedIsfOrganizationId
                );

            if (dlSelectedOrganization[0].ORGANIZATIONGROUPID === null) {
                selectOrganization(dlSelectedOrganization[0]);
            }
            if (dlSelectedOrganization[0].ORGANIZATIONGROUPID !== null) {
                setRelatedOrganizations(dlSelectedOrganization);
                const options = dlSelectedOrganization.map((item) => ({
                    value: item.ORGANIZATIONID,
                    label: item.ORGNIZATIONNAME,
                    organizationIDISF: item.ORGANIZATIONIDISF,
                    organizationGroupId: item.ORGANIZATIONGROUPID,
                }));
                dispatch(setSelectedRelatedOrganizationsForReports(options));
                dispatch(
                    addSelectedOrganizationForReports({
                        id: dlSelectedOrganization[0]?.ORGANIZATIONID,
                        organization: dlSelectedOrganization[0],
                        related:
                            dlSelectedOrganization?.length > 0 &&
                            dlSelectedOrganization[0]?.ORGANIZATIONGROUPID !==
                                null
                                ? dlSelectedOrganization
                                : [],
                    })
                );

                dispatch(
                    setSelectedOrgainzationsIdForReports(
                        dlSelectedOrganization[0]?.ORGANIZATIONID
                    )
                );
            }
        }
    }, [dlselectedIsfOrganizationId]);

    const handleSearchValueChange = ({ target: input }) => {
        setSearchValue(input.value);

        if (searchType?.value === "organizationName") {
            const currentSearchValue = input.value.trim().toLowerCase();

            if (currentSearchValue === "") {
                setDropdownOrganizations([]);
                return;
            }

            const filtered = organizations.filter((organization) => {
                return organization.ORGNIZATIONNAME?.toLowerCase().startsWith(
                    currentSearchValue
                );
            });

            const sortedOption = [...filtered].sort((a, b) =>
                a.ORGNIZATIONNAME.localeCompare(b.ORGNIZATIONNAME)
            );

            setDropdownOrganizations(sortedOption);
        }
    };
    const organizationNameSearched = () => {
        if (deferredSearchValue === "") {
            setDropdownOrganizations([]);
            return;
        }
        const filtered = organizations.filter((organization) => {
            return organization.ORGNIZATIONNAME.toLowerCase().startsWith(
                deferredSearchValue.toLowerCase()
            );
        });
        const sortedOption = [...filtered].sort((a, b) =>
            a.ORGNIZATIONNAME.localeCompare(b.ORGNIZATIONNAME)
        );
        setFilteredOrganizations(sortedOption);
    };

    const policyNumberSearched = () => {
        const policy = policies.filter(
            (p) => p.policyId === removeLeadingZeros(searchValue)
        );
        if (policy.length > 0) {
            const organization = policy.map((policy) =>
                organizations.find(
                    (o) => o.ORGANIZATIONID === policy.organizationId
                )
            );

            if (!organization) {
                setFilteredOrganizations([]);
                return;
            }

            const sortedOrganizations = organization.sort((a, b) => {
                return a.ORGNIZATIONNAME > b.ORGNIZATIONNAME ? 1 : -1;
            });

            setFilteredOrganizations(sortedOrganizations);
            sortedOrganizations.length
                ? setIsFiltered(true)
                : setIsFiltered(false);
        } else {
            setFilteredOrganizations([]);
        }
    };

    const handleOrganizationSearch = () => {
        setIsFiltered(true);
        if (searchType?.value === "organizationName") {
            organizationNameSearched();
        } else {
            policyNumberSearched();
        }
        setFilterTimestamp(new Date().getTime());
        gtmEvent("button", "click", "search", "field_interaction", "");
    };

    const isOrgCountOne = () => {
        const preferenceStoredOrg =
            OrganizationRepository.getRelatedOrganizationsFromIds(
                allOrganizations,
                organizationPreference
            );

        if (
            (preferenceStoredOrg &&
                preferenceStoredOrg.length === 1 &&
                preferenceStoredOrg[0].ORGANIZATIONGROUPID === null) ||
            !organizationsCount ||
            relatedOrganizations.length === 1
        ) {
            return true;
        }
    };

    const {
        selectedOrganizationsReports: userSelectedOrganizationReports,
        selectedOrganizationsIdReports: reportId,
        selectedRelatedOrganizationsForReports: selectedRelatedOrganizationsForReports,
    } = useSelector((state) => state.reportOrganization);
    
    useEffect(() => {
        const selected = [];
        if (
            organizationPreference &&
            organizationPreference.length > 0 &&
            organizationPreference.toString() !== ""
        ) {
            relatedOrganizations.forEach((item) => {
                const option = {
                    value: item.ORGANIZATIONID,
                    label: item.ORGNIZATIONNAME,
                    organizationIDISF: item.ORGANIZATIONIDISF,
                    organizationGroupId: item.ORGANIZATIONGROUPID,
                };

                if (
                    organizationPreference.includes(String(item.ORGANIZATIONID))
                ) {
                    selected.push(option);
                }
            });
        }
        if (selected.length > 0) {
            if (selectedRelatedOrganizationsForReports.length > 0) {
                dispatch(setSelectedRelatedOrganizationsForReports(selectedRelatedOrganizationsForReports));
                setSelectedRelatedOrganizations(selectedRelatedOrganizationsForReports);
            } else {
                dispatch(setSelectedRelatedOrganizationsForReports(selected));
                setSelectedRelatedOrganizations(selected);
            }
        }
        const option = userSelectedOrganizationReports?.[organizationPreference]?.organization;
        
        if (selectedOrganization === null && option !== undefined) {
            selectOrganization(option);
        }
    }, [organizationPreference, relatedOrganizations]);

    // set related organization dropdown
    useEffect(() => {
        const options = [];
        const selected = [];

        relatedOrganizations?.forEach((item) => {
            const option = {
                value: item.ORGANIZATIONID,
                label: item.ORGNIZATIONNAME,
                organizationIDISF: item.ORGANIZATIONIDISF,
                organizationGroupId: item.ORGANIZATIONGROUPID,
            };

            options.push(option);
            if (selectedOrganizationId === item.ORGANIZATIONID) {
                selected.push(option);
            }
        });

        const sortedOption = [...options].sort((a, b) =>
            a.label.localeCompare(b.label)
        );
        setRelatedOrganizationOptions(sortedOption);

        if (selected.length > 0) {
            if (selectedRelatedOrganizationsForReports.length > 0) {
                dispatch(setSelectedRelatedOrganizationsForReports(selectedRelatedOrganizationsForReports));
                setSelectedRelatedOrganizations(selectedRelatedOrganizationsForReports);
            } else {
                dispatch(setSelectedRelatedOrganizationsForReports(selected));
                setSelectedRelatedOrganizations(selected);
            }
        }
    }, [
        relatedOrganizations,
        organizationsCount,
        selectedOrganizationId,
        isOrgCountOne(),
    ]);

    const handleRelatedOrganizations = (selectedOptions) => {
        setSelectedRelatedOrganizations(selectedOptions);
        onSelectedRelatedOrganizationsChange(selectedOptions);
        dispatch(setSelectedRelatedOrganizationsForReports(selectedOptions));
    };

    const setOrganizationDrpDownFromPreference = () => {
        if (
            organizationPreference &&
            organizationPreference.length <= 1 &&
            organizationPreference[0] === ""
        ) {
            return;
        }
        const preferenceStoredOrg =
            OrganizationRepository.getRelatedOrganizationsFromIds(
                allOrganizations,
                organizationPreference
            );

        //For non related orgs
        if (
            preferenceStoredOrg &&
            preferenceStoredOrg.length === 1 &&
            preferenceStoredOrg[0].ORGANIZATIONGROUPID === null
        ) {
            selectOrganization(preferenceStoredOrg[0]);
        }
        //For related org
        if (
            preferenceStoredOrg &&
            preferenceStoredOrg[0]?.ORGANIZATIONGROUPID !== null && preferenceStoredOrg.length >0
        ) {
            setRelatedOrganizations(preferenceStoredOrg);

            let dropDownValuesToSet = preferenceStoredOrg
                .filter((org) => {
                    if (
                        organizationPreference.includes(
                            org.ORGANIZATIONID.toString()
                        )
                    ) {
                        return org.ORGNIZATIONNAME;
                    }
                    return null;
                })
                .map((i) => {
                    return {
                        label: i.ORGNIZATIONNAME,
                        value: i.ORGANIZATIONID,
                        organizationIDISF: i.ORGANIZATIONIDISF,
                        organizationGroupId: i.ORGANIZATIONGROUPID,
                    };
                });
            setSelectedOrganization(dropDownValuesToSet);
            setSelectedRelatedOrganizations(dropDownValuesToSet);
            onSelectedRelatedOrganizationsChange(dropDownValuesToSet);
            dispatch(
                addSelectedOrganizationForReports({
                    id: preferenceStoredOrg[0]?.ORGANIZATIONID,
                    organization: preferenceStoredOrg[0],
                    related:
                        preferenceStoredOrg?.length > 0 &&
                        preferenceStoredOrg[0]?.ORGANIZATIONGROUPID !== null
                            ? preferenceStoredOrg
                            : [],
                })
            );

            dispatch(
                setSelectedOrgainzationsIdForReports(
                    preferenceStoredOrg[0]?.ORGANIZATIONID
                )
            );
        }
    };

    const setIsPreferenceChangedValue = (value) => {
        dispatch(setPreferenceChanged(value));
    };
    
    const selectOrganization = (organization) => {
        const related = OrganizationRepository.getRelatedOrganizations(
            allOrganizations,
            organization
        );

        setRelatedOrganizations(related);

        setSelectedOrganization(organization.ORGNIZATIONNAME);
        setSelectedOrganizationId(organization.ORGANIZATIONID);
        setDropdownOrganizations([]);
        organizationSelected(
            clearButtonText === "Clear selection"
                ? organization
                : organization.ORGANIZATIONID
        );
        setIsFiltered(false);
        dispatch(
            addSelectedOrganizationForReports({
                id: organization.ORGANIZATIONID,
                organization: organization,
                related: related,
            })
        );

        dispatch(
            setSelectedOrgainzationsIdForReports(organization.ORGANIZATIONID)
        );
        
        dispatch(
            setOrganisationPreference({
                organizationPreference: organization.ORGANIZATIONID,
            })
        );
        setIsPreferenceChangedValue(false);
       
        
    };


    let className;

    if (
        selectedRelatedOrganizations.length >= 1 &&
        selectedRelatedOrganizations.length !== relatedOrganizations.length
    ) {
        if (
            selectedRelatedOrganizations.length >= 1 &&
            selectedRelatedOrganizations.length < 7
        ) {
            className = "test";
        } else {
            className = "modify";
        }
    } else {
        className = ",";
    }

    const clearPreference = () => {
        if (!organizationsCount || relatedOrganizations.length === 1) {
            setSearchType(list[0]);
            setSearchValue("");
            setSelectedOrganization(null);
            setDropdownOrganizations([]);
            setSelectedRelatedOrganizations([]);
            setRelatedOrganizations([]);
            organizationSelected([]);
            onSelectedRelatedOrganizationsChange([]);
            dispatch(setPreferenceChanged(false));
            dispatch(setSelectedRelatedOrganizationsForReports([]));
            dispatch(
                setOrganisationPreference({
                    organizationPreference: [""],
                })
            );
        } else {
            setSelectedRelatedOrganizations([]);
            onSelectedRelatedOrganizationsChange([]);
        }
        setFilteredOrganizations([]);
        setIsFiltered(false);

        gtmEvent("button", "click", "clear", "field_interaction", "");
    };

    useEffect(() => {
        if (selectedOrganization) {
            organizationSelected(
                clearButtonText === "Clear selection"
                    ? selectedOrganization
                    : selectedOrganizationId
            );
        }
    }, [selectedOrganization]);

    const getButtonDisabledStatus = () => {
        return searchValue.trim() === "";
    };

    const getClearButtonDisabledStatus = () => {
        if (isOrgCountOne()) {
            return selectedOrganization === null;
        } else {
            return selectedRelatedOrganizations.length === 0;
        }
    };

    const handleSearchTypeUpdate = (type) => {
        setSearchType(type);
        setSearchValue("");
        gtmEvent(
            "dropdown",
            "select",
            "select organization / policy",
            "field_interaction",
            `${type.label.toLocaleLowerCase()}`
        );
    };

    const isOrganization = !relatedOrganizations.length > 0;
    const isRelatedOrganizations = relatedOrganizations.length > 0;
    const isOrgSelected = selectedOrganization !== null;

    const getLabelTitleForPreference = () => {
        if (isRelatedOrganizations) {
            return (
                <label tabIndex={0} htmlFor="org">
                    Set related organization preference
                </label>
            );
        } else {
            return (
                <label tabIndex={0} htmlFor="org">
                    Set organization preference
                </label>
            );
        }
    };

    const getLabelTitleForViewSelectionPage = () => {
        if (selectedOrganization) {
            return (
                <label tabIndex={-1} htmlFor="org">
                    {isRelatedOrganizations
                        ? " Selected related organization"
                        : "Selected organization"}
                </label>
            );
        } else {
            return (
                <label tabIndex={-1} htmlFor="org">
                    Enter organization
                </label>
            );
        }
    };

    return (
        <div className="row align-items-end" style={{ margin: 0 }}>
            {!selectedOrganization && (
                <div
                    className="col-lg-4 col-md-4 col-sm-6 md-mb-20p"
                    style={{ paddingLeft: 0 }}
                >
                    <div className="select-search">
                        <label htmlFor="orgpolicy" tabIndex={-1}>
                            Select organization / policy
                        </label>

                        <Select
                            className="select-dropdown"
                            placeholder="Please Select"
                            value={searchType}
                            options={list}
                            onChange={handleSearchTypeUpdate}
                            isSearchable={false}
                            id="orgpolicy"
                        />
                    </div>
                </div>
            )}
            <div
                className={
                    selectedOrganization
                        ? "col-lg-10 col-md-10 col-sm-6 md-mb-20p pl-0p"
                        : "col-lg-4 col-md-4 col-sm-6 md-mb-20p"
                }
            >
                <div className="position-relative">
                    {searchType.value === "organizationName" ||
                    selectedOrganization ? (
                        <div>
                            {clearButtonText === "Clear preference"
                                ? getLabelTitleForPreference()
                                : getLabelTitleForViewSelectionPage()}
                            {isOrganization && (
                                <div>
                                    <div ref={organizationInputRef}>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter organization"
                                            value={
                                                isOrgSelected
                                                    ? selectedOrganization
                                                    : searchValue
                                            }
                                            onChange={handleSearchValueChange}
                                            onKeyUp={(event) => {
                                                if (
                                                    event.key === "ArrowDown" &&
                                                    document.getElementsByClassName(
                                                        "organisationclass"
                                                    ).length > 0
                                                ) {
                                                    document
                                                        .getElementsByClassName(
                                                            "organisationclass"
                                                        )[0]
                                                        .focus();
                                                }
                                            }}
                                            ref={(input) => input?.focus()}
                                            autoComplete="off"
                                            id="org"
                                        />
                                    </div>
                                    {dropdownOrganizations.length > 0 && (
                                        <OrganizationAutocompleteDropdown
                                            organizations={
                                                dropdownOrganizations
                                            }
                                            hideOrganizationAutocompleteDropdown={() =>
                                                setDropdownOrganizations([])
                                            }
                                            organizationInputRef={
                                                organizationInputRef
                                            }
                                            selectOrganization={
                                                selectOrganization
                                            }
                                        />
                                    )}
                                </div>
                            )}

                            {isRelatedOrganizations && (
                                <div>
                                    <MultiSelect
                                        className={
                                            "select-dropdown " + className
                                        }
                                        options={relatedOrganizationOptions}
                                        value={selectedRelatedOrganizations}
                                        onChange={handleRelatedOrganizations}
                                        labelledBy="Select"
                                        disableSearch={false}
                                        isCreatable={false}
                                        overrideStrings={{
                                            selectSomeItems:
                                                "Add related organization +",
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        !selectedOrganization && (
                            <div>
                                <label htmlFor="policy" tabIndex={0}>
                                    Party ID / Policy number
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={searchValue}
                                    onChange={handleSearchValueChange}
                                    id="policy"
                                />
                            </div>
                        )
                    )}{" "}
                </div>
            </div>
            {selectedOrganization === null &&
                selectedRelatedOrganizations.length === 0 && (
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                        <Button
                            onClick={handleOrganizationSearch}
                            disabled={getButtonDisabledStatus()}
                            text="Search"
                            icon="fa-magnifying-glass"
                            type="submit"
                        />
                    </div>
                )}
            <div className="col-lg-2 col-md-2 col-sm-6 col-6 pr-0p">
                <Button
                    onClick={clearPreference}
                    disabled={getClearButtonDisabledStatus()}
                    text={clearButtonText}
                    type="Clear preference"
                />
            </div>

            {isFiltered && (
                <div style={{ marginTop: 35, paddingLeft: 0 }}>
                    {filteredOrganizations.length === 0 ? (
                        <NoRecords
                            timestamp={filterTimestamp}
                            page="search"
                            label="search"
                            attribute1="search"
                            attribute3="search_error"
                        />
                    ) : (
                        <OrganizationResult
                            organizations={filteredOrganizations}
                            selectOrganization={selectOrganization}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default OrganizationSearchBaseComponent;
