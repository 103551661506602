import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "./ui/Button";
import { Modal } from "react-bootstrap";
import releaseLogo from "../assets/icons/New Feature.svg";
import releaseImage from "../assets/images/Release_Message.jpeg";
import {
    clearReleaseMessage,
    setReleaseMessage,
} from "../slices/preferenceSlice";
import { Parser } from "html-to-react";

const ReleaseMessage = ({ releaseHeader, releaseContent, releaseLink }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const htmlToReactParser = new Parser();

    const [show, setShow] = useState(true);

    const clearAndClose = () => {
        setShow(false);
        dispatch(clearReleaseMessage());
    };

    const handleClose = () => {
        clearAndClose();
    };

    const handleLearnMore = () => {
        if (releaseLink) {
            navigate(new URL(releaseLink).pathname);
        }
        clearAndClose();
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            clearAndClose();
        }, 100000);

        return () => clearTimeout(timer);
    }, []);

return (
    <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        backdrop="static"
        className="Release-Message"
    >
        <div className="close-button" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
            Close
        </div>
        <Modal.Body className="p-0">
            <div className="modal-content-wrapper">
                <img 
                    src={releaseImage} 
                    alt="New Feature Logo" 
                    className="left-image"
                />
                <div className="text-content">
                    <label className="first-login-text">{releaseHeader}</label>
                    <div className="release-content">
                        {htmlToReactParser.parse(releaseContent)}
                    </div>
                    <div className="first-login-buttons">
                        <Button onClick={handleLearnMore} text="Learn more" />
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
);
};

export default ReleaseMessage;
