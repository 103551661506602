import React from "react";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import Modal from "react-bootstrap/Modal";

export default function ShareReportDialogBox({
    onShow,
    onSave,
    onDontSave,
    selectedView,
}) {

    const sendSaveEvent = () => {
        onSave();        
    };

    const sendDontSaveEvent = () => {
        onDontSave();       
    };    

    return (
        <>
            <Modal
                size="sm"
                show={onShow}
                backdrop="static"
                keyboard={false}
                centered
                className="preference"
                dialogClassName="custom-modal-size"
            >
                <Modal.Header>
                    <SectionHeading heading={selectedView?.Name} />
                    <i
                        className="fa-solid fa-circle-xmark cursor-pointer"
                        onClick={sendDontSaveEvent}
                    ></i>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>
                            Share this view with your organization
                            </label>
                        </div>
                    </div>

                    <div className="preference-button pt-16p">
                        <div className="d-contents">
                            <Button
                                data-testid="Cancel"
                                text="Cancel"
                                outline={true}
                                onClick={sendDontSaveEvent}
                            />
                            <Button text="Share" onClick={sendSaveEvent} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
