import { createSlice } from "@reduxjs/toolkit";

const helpContentSlice = createSlice({
    name: "helpContent",
    initialState: {
        definitionsExplained: [],
        helpContentSection: [],
        frequentlyAskedQuestions: {},
        showHelpSection: false,
    },
    reducers: {
        setDefinitionsExplained(state, action) {
            state.definitionsExplained = action.payload;
        },
        setHelpContentSection(state, action) {
            state.helpContentSection = action.payload;
        },
        setFrequentlyAskedQuestions(state, action) {
            state.frequentlyAskedQuestions = action.payload;
        },
        setShowHelpSection(state, action) {
            state.showHelpSection = action.payload;
        },
    },
});

export const {
    setDefinitionsExplained,
    setHelpContentSection,
    setFrequentlyAskedQuestions,
    setShowHelpSection,
} = helpContentSlice.actions;

export default helpContentSlice.reducer;
