import React from "react";

function ReportIcon() {
    return (
        <svg
            width="12"
            height="17"
            viewBox="0 0 12 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.4062 3.4375C11.7812 3.8125 12 4.3125 12 4.84375V14.5C12 15.625 11.0938 16.5 10 16.5H2C0.875 16.5 0 15.625 0 14.5V2.5C0 1.40625 0.875 0.5 2 0.5H7.65625C8.1875 0.5 8.6875 0.71875 9.0625 1.09375L11.4062 3.4375ZM10.5 14.5H10.4688V5.5H8C7.4375 5.5 7 5.0625 7 4.5V2.03125H2C1.71875 2.03125 1.5 2.25 1.5 2.53125V14.5C1.5 14.7812 1.71875 15 2 15H10C10.25 15 10.5 14.7812 10.5 14.5ZM6 8.5C6.40625 8.5 6.75 8.84375 6.75 9.25V13.25C6.75 13.6875 6.40625 14 6 14C5.5625 14 5.25 13.6875 5.25 13.25V9.25C5.25 8.84375 5.5625 8.5 6 8.5ZM8 13.25V10.25C8 9.84375 8.3125 9.5 8.75 9.5C9.15625 9.5 9.5 9.84375 9.5 10.25V13.25C9.5 13.6875 9.15625 14 8.75 14C8.3125 14 8 13.6875 8 13.25ZM3.25 10.5C3.65625 10.5 4 10.8438 4 11.25V13.25C4 13.6875 3.65625 14 3.25 14C2.8125 14 2.5 13.6875 2.5 13.25V11.25C2.5 10.8438 2.8125 10.5 3.25 10.5Z"
                fill="#015294"
            />
        </svg>
    );
}

export default ReportIcon;
