import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: "app",
    initialState: {
        config: {},
    },
    reducers: {
        configure(state, action) {
            state.config = action.payload;
        },
    },
});

export const { configure } = appSlice.actions;

export default appSlice.reducer;
