import React, { useEffect, useState } from "react";
import TableauReport from "tableau-react";
import { useParams, useNavigate } from "react-router-dom";
import reports from "./reports.json";
import {
    capitalizeFirstLetter,
    getReportBreadcrumbLinks,
} from "../../utils/helpers";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import { useSelector } from "react-redux";
import config from "../../config";

const options = {
    width: "100%",
    height: "850px",
    hideTabs: false,
    hideToolbar: false,
    toolbarPosition: "ApiToolbarPosition.Top",
};
const parameters = {
    name: " toolbar-item-collaborate",
    value: true,
};
const query = "?:linktarget=_parent&:jsdebug=n";

const reportUrl = (url) => {
    return config.REACT_APP_TABLEAU_URL + url;
};

function Report() {
    const { type, group, reportId } = useParams();
    const [report, setReport] = useState(null);
    const navigate = useNavigate();
    const { lastSelectedOrganization, lastSelectedEmployee } = useSelector(
        (state) => state.organizations
    );

    useEffect(() => {
        const selectedGroup = reports.find((g) => g.type === group);

        if (selectedGroup) {
            const selectedReport = selectedGroup.urls.find(
                (r) => r.id === parseInt(reportId)
            );

            if (selectedReport) setReport(selectedReport);
            else {
                navigate(-1);
            }
        } else {
            navigate(-1);
        }
    }, [group, reportId, navigate]);

    const links = [
        ...getReportBreadcrumbLinks(
            lastSelectedOrganization,
            lastSelectedEmployee
        ),
        {
            path: `/reports/${type}?reports=true`,
            name: `${capitalizeFirstLetter(type)} Reports`,
        },
        {
            name: report?.name,
        },
    ];

    return (
        <>
            <Breadcrumb links={links} />

            <div className="iframe">
                {report && (
                    <TableauReport
                        url={reportUrl(report.url)}
                        options={options}
                        parameters={parameters}
                        query={query}
                    />
                )}
            </div>
        </>
    );
}

export default Report;
