import { noDataPlaceholder } from "../constants";
import EmployeeRepository from "../repositories/EmployeeRepository";
import { formatDate, sort } from "../utils/helpers";

class ClaimDetails {
    _formatHeader = (data) => {
        return {
            ClaimHistory: data.ClaimHistory,
            ClaimNumber: data.ClaimNumber,
            Coverage: data.Coverage,
            CurrentStatus: data.CurrentStatus,
            DateOfBirth: data.DateOfBirth,
            EmployeeID: data.EmployeeID,
            FirstName: data.FirstName,
            LastName: data.LastName,
            MiddleName: data.MiddleName,
            PolicyDiv: data.PolicyDiv,
            PolicyNo: data.PolicyNo,
            SSN: data.SSN,
        };
    };

    _checkObject = (objProperty) =>
        objProperty ? objProperty : noDataPlaceholder;

    _checkNullAndReplace = (objProperty, dependantObj, customPlaceholder) => {
        if (objProperty) {
            return objProperty;
        } else if (dependantObj) {
            return customPlaceholder;
        } else {
            return <span>{noDataPlaceholder}</span>;
        }
    };

    _formatClaimStatus = (data) => {
        return {
            RelatedLeaves:
                data.RelatedLeaves?.split(",") !== null &&
                Array.isArray(data.RelatedLeaves?.split(","))
                    ? data.RelatedLeaves?.split(",")
                    : [],
            Division: this._checkObject(data.Division),
            StatusReason: this._checkObject(data.StatusReason),
            ReceivedDate: data.ReceivedDate,
            PaymentStatus: this._checkObject(data.PaymentStatus),
            LastPaymentAmount: data.LastPaymentAmount,
            LastPaymentDate: data.LastPaymentDate,
            TotalPaymentAmount: data.TotalPaymentAmount,
            BaseCoverage: data.BaseCoverage,
            SupplementalCoverage: data.SupplementalCoverage,
            TotalCoverage: data.TotalCoverage,
            DisabilityDate: data.DisabilityDate,
            IncurredDate: data.IncurredDate,
            CauseOfDeath: this._checkNullAndReplace(
                data.CauseOfDeath,
                data.DateOfDeath,
                noDataPlaceholder
            ),
            RetireeIndicator: this._checkObject(data.RetireeIndicator),
            DateOfDeath: data.DateOfDeath,
            BenefitStartDate: data.BenefitStartDate,
            ApprovalDate: data.ApprovalDate,
            EligibilityGroup: this._checkObject(data.EligibilityGroup),
            MaximumDuartionDate: data.MaximumDuartionDate,
            ApprovedThruDate: data.ApprovedThruDate,
            Choice: this._checkObject(data.Choice),
            ReturntoWorkDatePT: data.ReturntoWorkDatePT,
            DaysPaid: data.DaysPaid,
            ReturntoWorkDateFT: data.ReturntoWorkDateFT,
            ClaimClosedDate: data.ClaimClosedDate,
            ClaimClosedReason: this._checkObject(data.ClaimClosedReason),
            MonthsPaid: data.MonthsPaid ? data.MonthsPaid : noDataPlaceholder,
            TotalPaidToDate: data.TotalPaidToDate,
            PaidThroughDate: data.PaidThroughDate,
            SSDIAwardDate: data.SSDIAwardDate,
            WorkRelated: data.WorkRelated
                ? data.WorkRelated
                : noDataPlaceholder,
            DisabilityBenefitsSpecialist: data.DisabilityBenefitsSpecialist
                ? data.DisabilityBenefitsSpecialist
                : noDataPlaceholder,
            Claimants: data.Claimants ? data.Claimants : noDataPlaceholder,
            InsuredRelationship: data.InsuredRelationship
                ? data.InsuredRelationship
                : noDataPlaceholder,
            LifeBenefitsSpecialist: data.LifeBenefitsSpecialist
                ? data.LifeBenefitsSpecialist
                : noDataPlaceholder,
            DiagnosticCode: data.DiagnosticCode
                ? data.DiagnosticCode
                : noDataPlaceholder,
            DiagnosticDescription: data.DiagnosticDescription
                ? data.DiagnosticDescription
                : noDataPlaceholder,
        };
    };

    format = (data) => {
        const list = {};

        list.Header = data.Header[0]
            ? this._formatHeader(data.Header[0])
            : null;

        list.ClaimStatus = data.ClaimStatus[0]
            ? this._formatClaimStatus(data.ClaimStatus[0])
            : null;

        const payments = [];

        data.PaymentStatus.forEach((el) => {
            const payment = el;

            if (payment.PaymentDate !== null || payment.PaymentType !== null) {
                const paymentBreakdown = data.AmountStatus
                    ? data.AmountStatus.filter(
                          (a) => a.ClaimPaymentId === payment.ClaimPaymentId
                      )
                    : [];

                payments.push({
                    ...payment,
                    PaymentPeriod: `${formatDate(
                        payment.PaymentPeriodStartDate
                    )} - ${formatDate(payment.PaymentPeriodEndDate)}`,
                    PaymentAmountStatus:
                        EmployeeRepository.getClaimPaymentAmountStatus(
                            paymentBreakdown
                        ),
                });
            }
        });

        list.Payments = sort(payments, "PaymentDate", false);
        return list;
    };
}

export default new ClaimDetails();
