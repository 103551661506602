import { createTransform } from "redux-persist";
import apiSlice from "../slices/apiSlice";

const SetTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (state) => state,
    // transform state being rehydrated
    (state) => {
        const queries = state.queries;
        const q = {};

        for (let key in queries) {
            const data = queries[key];

            if (data && data?.status === "fulfilled") {
                q[key] = data;
            }
        }

        return { ...state, queries: q };
    },
    // define which reducers this transform gets called for.
    { whitelist: [apiSlice.reducerPath] }
);

export default SetTransform;
