import React, { useEffect, useState } from "react";
import Select from "react-select";
import SectionSubheading from "../../components/SectionSubheading";

export default function ManageMonthly({ handleMonthly, subscriptionInfo }) {
    const [recurrencePattern, setRecurrencePattern] = useState();
    const [selectedDateEveryMonth, setSelectedDateEveryMonth] = useState(null);
    const [selectedDateSelectMonths, setSelectedDateSelectMonths] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState([]);

    useEffect(() => {
        if (
            subscriptionInfo !== undefined &&
            subscriptionInfo.Frequency === "Monthly"
        ) {
            if (subscriptionInfo.RecurrancePattern === "SelectMonths") {
                setRecurrencePattern("selectMonths");
                const shortenedMonths = subscriptionInfo.MonthsOfTheYear.map(
                    (month) => month.substring(0, 3)
                );
                setSelectedMonth(shortenedMonths);
                setSelectedDateSelectMonths(convertToLabel(subscriptionInfo.DayOfTheMonth));
            } else if (subscriptionInfo.RecurrancePattern === "Monthly") {
                setRecurrencePattern("everyMonth");
                setSelectedDateEveryMonth(convertToLabel(subscriptionInfo.DayOfTheMonth));            }
        }
    }, []);

    useEffect(() => {
        const fullMonthNames = selectedMonth.map(
            (day) => monthNameMap[day] || day
        );

        const selectedDate =
            recurrencePattern === "everyMonth"
                ? selectedDateEveryMonth
                : selectedDateSelectMonths;

        handleMonthly({
            selectedDates: selectedDate?.value,
            selectedMonths: fullMonthNames,
            selectedMonthly: recurrencePattern,
        });
    }, [selectedDateEveryMonth, selectedDateSelectMonths, selectedMonth, recurrencePattern]);

    const convertToLabel = (number) => {
        let label;
        if (number === 1 || number === 21 || number === 31)
            label = number + "st";
        else if (number === 2 || number === 22) label = number + "nd";
        else if (number === 3) label = number + "rd";
        else label = number + "th";
        return [{ value: number, label: label }];
    };

    const options = Array.from({ length: 28 }, (_, i) => {
        const number = i + 1;
        let label;
        switch (number) {
            case 1:
            case 21:
            case 31:
                label = number + "st";
                break;
            case 2:
            case 22:
                label = number + "nd";
                break;
            case 3:
            case 23:
                label = number + "rd";
                break;
            default:
                label = number + "th";
                break;
        }
        return {
            value: number,
            label: label,
        };
    });

    const handleChange = (selectedOption) => {
        if (recurrencePattern === "everyMonth") {
            setSelectedDateEveryMonth(selectedOption);
        } else if (recurrencePattern === "selectMonths") {
            setSelectedDateSelectMonths(selectedOption);
        }
    };

    const monthOptions = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const handleDayClick = (day) => {
        if (selectedMonth.includes(day)) {
            setSelectedMonth(
                selectedMonth.filter((selected) => selected !== day)
            );
        } else {
            setSelectedMonth([...selectedMonth, day]);
        }
    };

    const monthNameMap = {
        Jan: "January",
        Feb: "February",
        Mar: "March",
        Apr: "April",
        May: "May",
        Jun: "June",
        Jul: "July",
        Aug: "August",
        Sep: "September",
        Oct: "October",
        Nov: "November",
        Dec: "December",
    };

    return (
        <div>
            <div>
                <label
                    style={{
                        color: "#525252",
                        fontSize: 16,
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                        wordWrap: "break-word",
                        paddingTop: 12,
                    }}
                >
                    Recurrence pattern
                </label>
                <div
                    className="frequently-used-views"
                    style={{ marginBottom: 0 }}
                >
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                            <div className="button-group frequency-select ">
                                <div
                                    className={
                                        recurrencePattern === "everyMonth"
                                            ? "frequency-reminder selected-pattern"
                                            : "frequency-reminder"
                                    }
                                    style={{
                                        width: 192,
                                        borderTopLeftRadius: 8,
                                        borderBottomLeftRadius: 8,
                                    }}
                                    onClick={() =>
                                        setRecurrencePattern("everyMonth")
                                    }
                                    aria-label="Every month"
                                >
                                    Every month
                                </div>
                                <div
                                    className={
                                        recurrencePattern === "selectMonths"
                                            ? "frequency-reminder selected-pattern"
                                            : "frequency-reminder"
                                    }
                                    style={{
                                        borderRight: "none",
                                        whiteSpace: "nowrap",
                                        paddingLeft: 35,
                                        width: 192,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                    }}
                                    onClick={() =>
                                        setRecurrencePattern("selectMonths")
                                    }
                                    aria-label="Select months"
                                >
                                    Select months
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(recurrencePattern === "everyMonth" ||
                recurrencePattern === "selectMonths") && (
                <>
                    <SectionSubheading
                        subheading="Select the day of the Month you would like to receive your notification for this view."
                        className="sub-heading-disabled pt-16p"
                    />
                    <div className="pt-16p">
                        <label>Date</label>
                        <Select
                            options={options}
                            onChange={handleChange}
                            placeholder="Select a date"
                            value={
                                recurrencePattern === "everyMonth"
                                    ? selectedDateEveryMonth
                                    : selectedDateSelectMonths
                            } 
                        />
                    </div>
                </>
            )}
            {recurrencePattern === "selectMonths" && (
                <div
                    className="frequently-used-views"
                    style={{ marginBottom: 0 }}
                >
                    <label className="pt-16p">Month</label>
                    <div className="select-month">
                        {monthOptions.map((month) => (
                            <div
                                className={
                                    selectedMonth.includes(month)
                                        ? "day-selected"
                                        : ""
                                }
                                key={month}
                                onClick={() => handleDayClick(month)}
                            >
                                {month}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
