import React, { useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import GTM from "../GTM";

function AccordionToggle({
    children,
    eventKey,
    callback,
    className = "mb-20p",
    attribute1,
}) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey)
    );
    const gtm = new GTM(null);

    const isCurrentEventKey = activeEventKey === eventKey;

    const handleClick = (action) => {
        gtm.Event(
            "claim & leave",
            "accordion",
            action,
            typeof children === "string" ? children.toLowerCase() : "",
            attribute1,
            "",
            "field_interaction",
            ""
        );
    };

    return (
        <div className={"d-flex px-20p " + className}>
            <h2
                className="section-heading mb-0p cursor-pointer"
                onClick={decoratedOnClick}
            >
                {children}
            </h2>

            {isCurrentEventKey ? (
                <div
                    onClick={() => {
                        handleClick("collapse");
                    }}
                >
                    <i
                        onClick={decoratedOnClick}
                        className="fa-solid fa-circle-minus accordion-icon ml-8p cursor-pointer"
                        tabIndex={0}
                    ></i>
                </div>
            ) : (
                <div
                    onClick={() => {
                        handleClick("expand");
                    }}
                >
                    <i
                        onClick={decoratedOnClick}
                        className="fa-solid fa-circle-plus accordion-icon ml-8p cursor-pointer"
                        tabIndex={0}
                    ></i>
                </div>
            )}
        </div>
    );
}

export default AccordionToggle;
