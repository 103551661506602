import config from "./../config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const userApiSlice = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.REACT_APP_API_URL,
        credentials: "include",
        prepareHeaders: (headers) => {
            headers.set("Accept", "application/json");
            headers.set("Content-Type", "application/json");

            const apiKey = process.env.REACT_APP_API_KEY;
            if (apiKey) headers.set("apikey", apiKey);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => ({
                url: `/employees/${process.env.REACT_APP_API_VERSION}/permissions${config.REACT_APP_ENV_NAME}`,
                params: {
                    isUserInternal: 1,
                    PolicyPartyId: null,
                },
            }),
        }),
    }),
});

export const { useGetUserQuery, useLazyGetUserQuery } = userApiSlice;

export default userApiSlice;
