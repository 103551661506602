import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { numberFormat } from "../../utils/helpers";

function ClaimPaymentTransactions({ row }) {
    function ClaimPaymentMapping(item) {
        return (
            <div className="row m-0">
                <div className="col-lg-6 col-6 text-right mb-10p">
                    {numberFormat(item.ClaimPaymentAmt)}
                </div>
                <div className="col-lg-6 col-6 mb-10p">
                    {item.ClaimPaymentName}
                </div>
            </div>
        );
    }

    return (
        <div
            className=" w-100"
            style={{
                backgroundColor: "#E6F2F8",
            }}
        >
            <PerfectScrollbar style={{ height: "auto" }}>
                {row.original.PaymentAmountStatus.filter(
                    (item) => item.ClaimPaymentName === "Basic Benefit"
                ).map((item) => {
                    return ClaimPaymentMapping(item);
                })}

                {row.original.PaymentAmountStatus.filter(
                    (item) => item.ClaimPaymentName !== "Basic Benefit"
                ).map((item) => {
                    return ClaimPaymentMapping(item);
                })}
            </PerfectScrollbar>
        </div>
    );
}

export default ClaimPaymentTransactions;
