class ClaimLeaveData {
    format = (response) => {
        const claimLeaveData = [];

        if (response.ClaimLeaveData && Array.isArray(response.ClaimLeaveData)) {
            response.ClaimLeaveData.forEach((d) => {
                if (d.ClaimData && d.ClaimData.ClaimNo) {
                    claimLeaveData.push({
                        ...d.ClaimData,
                        isClaim: true,
                        Historical: false,
                        No: d.ClaimData.ClaimNo,
                        StartDate: d.ClaimData.ClaimStartDate,
                        EndDate: d.ClaimData.ClaimEndDate,
                        Group: d.ClaimData.PolicyDiv,
                        MaximumDuration: d.ClaimData.ClaimMaxDuration,
                        StatusReason: d.ClaimData.ClaimStatusReason,
                        LeaveReason: d.ClaimData.DiagnosticDescription,
                    });
                }

                if (d.LeaveData && d.LeaveData.LeaveNo) {
                    claimLeaveData.push({
                        ...d.LeaveData,
                        isClaim: false,
                        No: d.LeaveData.LeaveNo,
                        StartDate: d.LeaveData.LeaveStartDate,
                        EndDate: d.LeaveData.LeaveEndDate,
                        Group: d.LeaveData.ReportGroup,
                        MaximumDuration: d.LeaveData.LeaveMaxDuration,
                        StatusReason: d.LeaveData.LeaveStatusReason,
                        Disability: null,
                        Historical: d.LeaveData.LeaveHistoricalIND === "Y",
                    });
                }
            });
        }

        return claimLeaveData;
    };
}

export default new ClaimLeaveData();
