import React, { useState } from "react";
import { noDataPlaceholder, ssnAccess } from "./../../constants";
import StickyIcon from "../../assets/icons/Sticky Icon.png";
import NotStickyIcon from "../../assets/icons/Not Sticky Icon.png";
import GTM from "../../GTM";
import { useFlags } from "launchdarkly-react-client-sdk";

function EmployeeRecord(props) {
    let { ssnState } = props;
    const [sticky, setSticky] = useState(true);
    const gtm = new GTM(null);
    const pageTitle = "claim & leave";
    const employeeRecordTitle = "employee record";

    const handelClick = (attribute3) => {
        gtm.Event(
            pageTitle,
            "button",
            "click",
            "ssn",
            employeeRecordTitle,
            "",
            attribute3,
            ""
        );
    };

    const customizeSSN = (fullSSN) => {
        if (props.ssnDisplay === ssnAccess["No Access"]) {
            return null;
        }

        if (!ssnState) ssnState = "TRUNCATED";

        const onClickHandler = () => props.onSSNClick(ssnState);

        switch (ssnState) {
            case "TRUNCATED":
                const lastSSNDigits = fullSSN.split("-");
                return (
                    <span
                        onClick={() => handelClick("show_ssn")}
                        tabIndex={0}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleClick();
                            }
                        }}
                    >
                        <span className="mr-10p">
                            {lastSSNDigits[lastSSNDigits.length - 1]}
                        </span>

                        <span
                            data-testid="span-truncated"
                            onClick={onClickHandler}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onClickHandler();
                                }
                            }}
                            tabIndex={0}
                        >
                            <i
                                className="fa fa-eye part-eye"
                                aria-hidden="true"
                            ></i>
                        </span>
                    </span>
                );
            case "NORMAL":
                return (
                    <span tabIndex={0}>
                        <span className="mr-10p">{fullSSN}</span>

                        <span
                            data-testid="span-normal"
                            onClick={onClickHandler}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onClickHandler();
                                }
                            }}
                            tabIndex={0}
                        >
                            <i
                                className="fa fa-eye-slash full-eye"
                                aria-hidden="true"
                            ></i>
                        </span>
                    </span>
                );
            case "HIDDEN":
                return (
                    <span
                        onClick={() => handelClick("hide_ssn")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleClick();
                            }
                        }}
                        tabIndex={0}
                    >
                        <span className="mr-10p">***-**-****</span>

                        <span
                            data-testid="span-hidden"
                            onClick={onClickHandler}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onClickHandler();
                                }
                            }}
                            tabIndex={0}
                        >
                            <i
                                className="fa fa-eye mask-eye"
                                aria-hidden="true"
                            ></i>
                        </span>
                    </span>
                );
            default:
                return null;
        }
    };

    const formatDateOfBirth = (dob) => {
        if (!dob) return noDataPlaceholder;

        const date = dob.split("T")[0];
        const [year, month, day] = date.split("-");

        return `${month}/${day}/${year}`;
    };

    const toggleClass = () => {
        setSticky(!sticky);
        gtm.Event(
            pageTitle,
            "button",
            "click",
            `${sticky !== true ? "pin" : "unpin"}`,
            employeeRecordTitle,
            "",
            `${
                sticky !== true
                    ? "pin_employee_record"
                    : "unpin_employee_record"
            }`,
            ""
        );
    };

    const handleClick = () => {
        gtm.Event(
            pageTitle,
            "button",
            "click",
            "print",
            employeeRecordTitle,
            "",
            "print_employee_record",
            ""
        );
    };

    const {
        displayWarningBanner,
        displayInformationBanner,
        displayErrorbanner,
    } = useFlags();
    function renderEmptyContainer() {
        if (props.print) {
            return null;
        }

        let emptyContainerTopValue = "0px";
        if (sticky) {
            if (
                displayErrorbanner ||
                displayInformationBanner ||
                displayWarningBanner
            ) {
                emptyContainerTopValue = props.hideBanner ? "146px" : "92px";
            } else {
                emptyContainerTopValue = "92px";
            }
        }

        return (
            <div
                className="h-24 bg-even emptycontainer"
                style={{
                    zIndex: sticky ? 100 : 0,
                    top: emptyContainerTopValue,
                }}
            ></div>
        );
    }
    let topValue;
    if (
        displayErrorbanner ||
        displayInformationBanner ||
        displayWarningBanner
    ) {
        topValue = props.hideBanner ? 170 : 115;
    } else {
        topValue = 115;
    }
    return (
        <>
            {props.isLoading === false && (
                <>
                    {renderEmptyContainer()}

                    <section
                        className={`section-card container-heading ${
                            sticky ? "sticky" : ""
                        }`}
                        style={{
                            top: topValue,
                        }}
                    >
                        <div className="row" style={{ position: "relative" }}>
                            <div
                                className="col-lg-3 col-md-6 col-sm-4 col-6 md-mb-16p td-mb-16p col-print"
                                tabIndex={0}
                            >
                                <label className="pb-0">Name</label>
                                <p>{`${props.employee.LastName}, ${props.employee.FirstName}`}</p>
                            </div>

                            <div
                                className="col-lg-3 col-md-6 col-sm-4 col-6 md-mb-16p td-mb-16p col-print"
                                tabIndex={0}
                            >
                                <label className="pb-0">Date of Birth</label>
                                <p>
                                    {formatDateOfBirth(
                                        props.employee.DateOfBirth
                                    )}
                                </p>
                            </div>

                            {props.ssnDisplay !== ssnAccess["No Access"] && (
                                <div
                                    className="col-lg-3 col-md-6 col-sm-4 col-6 ssn col-print"
                                    tabIndex={0}
                                >
                                    <label className="pb-0">SSN</label>
                                    <p>{customizeSSN(props.employee.SSN)}</p>
                                </div>
                            )}

                            <div
                                className="col-lg-3 col-md-6 col-sm-4 col-6 col-print"
                                tabIndex={0}
                            >
                                <label className="pb-0">Employee ID</label>
                                <p>{props.employee.EmployeeID}</p>
                            </div>

                            <div
                                className="d-flex justify-content-end text-bold"
                                style={{ position: "absolute" }}
                            >
                                {!props.print && (
                                    <>
                                        {props.isFetchingLeave === true ||
                                        props.isFetchingClaim === true ? (
                                            <div className="disabled-text">
                                                <span className="d-inline-block mx-4p">
                                                    Loading
                                                </span>
                                                <i className="fa-solid fa-print"></i>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={handleClick}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleClick();
                                                    }
                                                }}
                                            >
                                                <div
                                                    onClick={props.initPrint}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            return props.initPrint;
                                                        }
                                                    }}
                                                    tabIndex={0}
                                                    className="text-white cursor-pointer mx-4p"
                                                >
                                                    <span className="d-inline-block mx-4p ">
                                                        Print
                                                    </span>
                                                    <i className="fa-solid fa-print"></i>
                                                </div>
                                            </div>
                                        )}

                                        <img
                                            className="cursor-pointer mx-4p"
                                            src={
                                                sticky
                                                    ? StickyIcon
                                                    : NotStickyIcon
                                            }
                                            alt="sticky"
                                            width="22.627"
                                            height="22.627"
                                            onClick={toggleClass}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    toggleClass();
                                                }
                                            }}
                                            tabIndex={0}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}

export default React.memo(EmployeeRecord);
