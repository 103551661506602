import React, { useEffect, useState } from "react";
import Select from "react-select";

const EmployeeSearchPreference = ({
    onEmployeePreferenceSelected,
    searchPreference,
}) => {
    const filterOptions = [
        { label: "Last Name", value: "lastName" },
        { label: "EEID", value: "eeid" },
        { label: "First Name", value: "firstName" },
        { label: "Claim Number", value: "claimNumber" },
        { label: "Leave Number", value: "leaveNumber" },
    ];

    const storedOption = searchPreference?.toString();

    const [selectedOption, setSelectedOption] = useState(null);

    const defaultOption = filterOptions.find(
        (opt) => opt.value === storedOption || opt.label === storedOption
    );

    useEffect(() => {
        if (searchPreference && defaultOption) {
            setSelectedOption(defaultOption);
            onEmployeePreferenceSelected(defaultOption.value);
        } else {
            setSelectedOption(filterOptions[0]);
            onEmployeePreferenceSelected(filterOptions[0].value);
        }
    }, [searchPreference]);

    const handleSelectedOptionChange = (selected) => {
        setSelectedOption(selected);
        onEmployeePreferenceSelected(selected.value);
    };

    return (
        <div>
            <label data-testid="employee search preference">
                Set employee search preference
            </label>
            <div
                className="col-lg-3 col-md-4 col-sm-6"
                data-testid="dropdownValue"
            >
                <Select
                    options={filterOptions}
                    value={selectedOption}
                    onChange={handleSelectedOptionChange}
                    name="dropdownValue"
                    isSearchable={false}
                />
            </div>
        </div>
    );
};

export default EmployeeSearchPreference;
