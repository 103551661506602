import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config";

const prepareHeaders = (headers) => {
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");

    const apiKey = process.env.REACT_APP_API_KEY;
    if (apiKey) headers.set("apikey", apiKey);

    return headers;
};

const generateUrl = (viewId, subscriptionId = "") =>
    `/data-views/${process.env.REACT_APP_API_VERSION}/${viewId}/subscriptions${subscriptionId}${config.REACT_APP_ENV_NAME}`;

const saveReportViewReminderSlice = createApi({
    reducerPath: "saveReportViewReminder",
    baseQuery: fetchBaseQuery({
        baseUrl: config.REACT_APP_API_URL,
        credentials: "include",
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        saveReportViewReminder: builder.mutation({
            query: ({ dataToUpdate, viewId }) => ({
                url: generateUrl(viewId),
                method: "POST",
                body: JSON.stringify(dataToUpdate),
            }),
        }),
        updateReportViewReminder: builder.mutation({
            query: ({ dataToUpdate, viewId, SubscriptionId }) => ({
                url: generateUrl(viewId, `/${SubscriptionId}`),
                method: "PUT",
                body: JSON.stringify(dataToUpdate),
            }),
        }),
        deleteReportViewReminder: builder.mutation({
            query: ({ dataToUpdate, viewId }) => ({
                url: generateUrl(viewId, `/${dataToUpdate.SubscriptionId}`),
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useSaveReportViewReminderMutation,
    useUpdateReportViewReminderMutation,
    useDeleteReportViewReminderMutation,
} = saveReportViewReminderSlice;

export default saveReportViewReminderSlice;
