import Organization from "../models/Organization";
import OrganizationRepository from "./OrganizationRepository";

class UserRepository {
    formatUserResponse = (user) => {
        const { userDetails, orgCollection } = user;

        let organizations = Organization.format(orgCollection);
        let policies = OrganizationRepository.getPolicies(organizations);

        return { userDetails, organizations, policies };
    };
}

export default new UserRepository();
