class AbsenceSearch {
    format = (item1, item2) => {
        const AbsenceSearch = [];
        let claimsFilteredData;
        let leavesFilteredData;
        if (item2.claimsSearch && Array.isArray(item2.claimsSearch)) {
            item2.claimsSearch.forEach((Claim) => {
                claimsFilteredData = {
                    aliasClaimId: Claim.aliasClaimId ?? null,
                    digitalAddressText: Claim.digitalAddressText ?? null,
                    checkNumber: Claim.checkNumber ?? null,
                    choiceCd: Claim.choiceCd ?? null,
                    claimApprovedThruDt: Claim.claimApprovedThruDt ?? null,
                    claimBenefitBeginDt: Claim.claimBenefitBeginDt ?? null,
                    claimBenefitEndDt: Claim.claimBenefitEndDt ?? null,
                    claimClosedDt: Claim.claimClosedDt ?? null,
                    claimId: Claim.claimId ?? null,
                    claimPaidThroughDate: Claim.claimPaidThroughDate ?? null,
                    claimSocialSecurityAwardDt:
                        Claim.claimSocialSecurityAwardDt ?? null,
                    claimStatus: Claim.claimStatus ?? null,
                    claimStatusReason: Claim.claimStatusReason ?? null,
                    coverageType: Claim.claimCoverageTypeCd ?? null,
                    dateOfBirth: Claim.dateOfBirth ?? null,
                    disabilityBeginDt: Claim.disabilityBeginDt ?? null,
                    divisionId: Claim.divisionId ?? null,
                    divisionName: Claim.divisionName ?? null,
                    eligibilityGroup: Claim.eligibilityGroup ?? null,
                    eligibilityGroupDesc: Claim.eligibilityGroupDesc ?? null,
                    empContributionPremiumAmt:
                        Claim.empContributionPremiumAmt ?? null,
                    employeeId: Claim.employeeId ?? null,
                    firstName: Claim.firstName ?? null,
                    fullName: Claim.fullName ?? null,
                    fullTimeRtwDt: Claim.fullTimeRtwDt ?? null,
                    actualDeliveryDt: Claim.actualDeliveryDt ?? null,
                    genderAtBirth: Claim.genderAtBirth ?? null,
                    groupAgreementId: Claim.groupAgreementId ?? null,
                    hireDt: Claim.hireDt ?? null,
                    lastName: Claim.lastName ?? null,
                    leaveStatusEffectiveDt:
                        Claim.claimStatusEffectiveDt ?? null,
                    monthlyCoveredSalaryAmt: Claim.monthlyCoveredSalaryAmt ?? null,
                    nonPaymentStartDt: Claim.nonPaymentStartDt ?? null,
                    nonPaymentEndDt: Claim.nonPaymentEndDt ?? null,
                    OtherOffsetsAmt: Claim.otherOffsetsAmt ?? null,
                    paymentEndDt: Claim.paymentEndDt ?? null,
                    paymentOffsetsAmt: Claim.paymentOffsetsAmt ?? null,
                    partTimeRTWDt: Claim.partTimeRTWDt ?? null,
                    paymentDeductionsAmt: Claim.paymentDeductionsAmt ?? null,
                    paymentGrossBenefitAmt:
                        Claim.paymentGrossBenefitAmt ?? null,
                    paymentRecentCheckAmt: Claim.paymentRecentCheckAmt ?? null,
                    primaryDiagnosisCd: Claim.primaryDiagnosisCd ?? null,
                    primaryDiagnosisDesc: Claim.primaryDiagnosisDesc ?? null,
                    ReportingLocation: Claim.ReportingLocation ?? null,
                    secondaryDiagnosisCd: Claim.secondaryDiagnosisCd ?? null,
                    secondaryDiagnosisDesc:
                        Claim.secondaryDiagnosisDesc ?? null,
                    socialSecurityOffsetAmt:
                        Claim.socialSecurityOffsetAmt ?? null,
                    status: Claim.claimStatus ?? null,
                    taxIdentificationNumber:
                        Claim.taxIdentificationNumber ?? null,
                    monthsPaid: Claim.monthsPaid ?? null,
                    totalDaysPaidCount: Claim.totalDaysPaidCount ?? null,
                    totalPaidToDtAmt: Claim.totalPaidToDtAmt ?? null,
                    weeklyCoveredSalaryAmt:
                        Claim.weeklyCoveredSalaryAmt ?? null,
                    workerCompensationOffsetsAmt:
                        Claim.workerCompensationOffsetsAmt ?? null,
                    workIncentiveBenefitInd: Claim.workIncentiveBenefitInd ?? null,
                    workLevel: Claim.workLevel ?? null,
                    workRelatedInd: Claim.workRelatedInd ?? null,
                };
                AbsenceSearch.push(claimsFilteredData);
            });
        }
        if (item1.LeaveSearch && Array.isArray(item1.LeaveSearch)) {
            item1.LeaveSearch.forEach((Leave) => {
                leavesFilteredData = {
                    addressLineOne: Leave.addressLineOne ?? null,
                    addressLineTwo: Leave.addressLineTwo ?? null,
                    actualDeliveryDt: Leave.actualDeliveryDt ?? null,
                    ageAtLeaveStart: Leave.ageAtLeaveStart ?? null,
                    city: Leave.city ?? null,
                    claimReceivedTs: Leave.claimReceivedTs ?? null,
                    claimStatusReason: Leave.leaveCloseReason ?? null,
                    classificiationTypeDesc:
                        Leave.classificiationTypeDesc ?? null,
                    coverageType: Leave.coverageType ?? null,
                    dateOfBirth: Leave.dateOfBirth ?? null,
                    homeDigitalAddressText: Leave.homeDigitalAddressText ?? null,
                    workDigitalAddressText: Leave.workDigitalAddressText ?? null,
                    divisionId: Leave.divisionId ?? null,
                    earningType: Leave.earningType ?? null,
                    employeeId: Leave.employeeId ?? null,
                    employeeWorkStateCd: Leave.employeeWorkStateCd ?? null,
                    employerLocationName: Leave.employerLocationName ?? null,
                    firstHireDt: Leave.firstHireDt ?? null,
                    firstName: Leave.firstName ?? null,
                    fitForDutyReleaseDt: Leave.fitForDutyReleaseDt ?? null,
                    claimClosedDt:Leave.leaveClosedDt,
                    genderAtBirth: Leave.genderAtBirth ?? null,
                    groupAgreementId: Leave.groupAgreementId ?? null,
                    hireDt: Leave.hireDt ?? null,
                    historicalLeaveInd: Leave.historicalLeaveInd ?? null,
                    hoursAvailable: Leave.hoursAvailable ?? null,
                    hoursTaken: Leave.hoursTaken ?? null,
                    intermittentAbsenceDisplayName:
                        Leave.intermittentAbsenceDisplayName ?? null,
                    intermittentAbsenceDurationHoursQty:
                        Leave.intermittentAbsenceDurationHoursQty ?? null,
                    intermittentDt: Leave.intermittentDt ?? null,
                    jurisdictionCode: Leave.jurisdictionCode ?? null,
                    lastDateWorked: Leave.lastDateWorked ?? null,
                    lastName: Leave.lastName ?? null,
                    leaveId: Leave.leaveId ?? null,
                    leaveEndDt: Leave.leaveEndDt ?? null,
                    leaveStatusEffectiveDt:
                        Leave.leaveStatusEffectiveDt ?? null,
                    leaveRequestDt: Leave.leaveRequestDt ?? null,
                    leaveReason: Leave.leaveReason ?? null,
                    leaveScheduleType: Leave.leaveScheduleType ?? null,
                    leaveStartDt: Leave.leaveStartDt ?? null,
                    leavePeriodId: Leave.leavePeriodId ?? null,
                    leavePeriodNbr: Leave.leavePeriodNbr ?? null,
                    leaveReceivedDt: Leave.leaveReceivedDt ?? null,
                    leaveStatus: Leave.leaveStatus ?? null,
                    managerEmail: Leave.managerEmail ?? null,
                    managerName: Leave.managerName ?? null,
                    fullName: Leave.name ?? null,
                    partTimeRTWDt:
                        Leave.rtwType === "Part Time"
                            ? Leave.partTimeRtwDt
                            : null,

                    fullTimeRtwDt:
                        Leave.rtwType === "Full Time"
                            ? Leave.fullTimeRtwDt
                            : null,
                    periodStatus: Leave.periodStatus ?? null,
                    periodStatusReason: Leave.periodStatusReason ?? null,
                    periodStartDt: Leave.periodStartDt ?? null,
                    periodEndDt: Leave.periodEndDt ?? null,
                    postalCode: Leave.postalCode ?? null,
                    personDisplayName: Leave.personDisplayName ?? null,
                    protectionName: Leave.protectionName ?? null,
                    protectionStatusDesc: Leave.protectionStatusDesc ?? null,
                    reducedScheduleHours: Leave.reducedScheduleHours ?? null,
                    relatedClaimLinkedInd: Leave.relatedClaimLinkedInd ?? null,
                    relatedStdClaimDuration:
                        Leave.relatedStdClaimDuration ?? null,
                    relatedStdClaimId: Leave.claimId ?? null,
                    relatedStdClaimStatus: Leave.relatedStdClaimStatus ?? null,
                    relatedStdClaimStatusReason:
                        Leave.relatedStdClaimStatusReason ?? null,
                    relatedStdDivisionId: Leave.divisionId ?? null,
                    relatedStdDivisionName: Leave.divisionName ?? null,
                    relatedStdMaxDurationDate:
                        Leave.relatedStdMaxDurationDate ?? null,
                    relatedStdPolicyName: Leave.policyName ?? null,
                    relatedStdPolicyNumber: Leave.groupAgreementId ?? null,
                    reportedDt: Leave.reportedDt ?? null,
                    reportGroupName: Leave.reportGroupName ?? null,
                    reportGroupNumber:Leave.reportGroupNumber ?? null,
                    rtwDt: Leave.rtwDt ?? null,
                    rtwLastUpdateDt: Leave.rtwLastUpdateDt ?? null,
                    rtwType: Leave.rtwType ?? null,
                    rtwStatus: Leave.rtwStatus ?? null,
                    startTime: Leave.startTime ?? null,
                    endTime: Leave.endTime ?? null,
                    status: Leave.leaveStatus ?? null,
                    taxIdentificationNumber:
                        Leave.taxIdentificationNumber ?? null,
                    tenureDays: Leave.tenureDays ?? null,
                };
                AbsenceSearch.push(leavesFilteredData);
            });
        }
        return AbsenceSearch;
    };
}
export default new AbsenceSearch();
