import { useEffect } from "react";
import UserRepository from "../../repositories/UserRepository";
import { logout } from "../../slices/authSlice";
import { useLazyGetUserQuery } from "../../slices/userApiSlice";
import {
    setUserDetails,
    setUserDetailsLoadingStatus,
} from "../../slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

const useOrganizationsObserver = () => {
    const dispatch = useDispatch();

    const { user: loggedInUser } = useSelector((state) => state.auth);

    const [getUser, { data: user = null, error, isFetching }] =
        useLazyGetUserQuery();

    const { loaded } = useSelector((state) => state.userDetails);
    const { totalParentOrganizations } = useSelector(
        (state) => state.organizations
    );

    useEffect(() => {
        if (
            loggedInUser !== null &&
            loaded === false &&
            totalParentOrganizations > 1
        ) {
            getUser();
        }
    }, []);

    useEffect(() => {
        if (user !== null) {
            const { organizations, policies } =
                UserRepository.formatUserResponse(user);

            dispatch(
                setUserDetails({
                    organizations: organizations,
                    policies: policies,
                })
            );

            // dispatch(setTotalParentOrganization(organizations.length));
        }
    }, [user]);

    useEffect(() => {
        if (error && error.status === 401) {
            dispatch(logout());
        }
    }, [error]);

    useEffect(() => {
        dispatch(
            setUserDetailsLoadingStatus({
                isLoading: isFetching,
                error: error,
            })
        );
    }, [isFetching, error]);

    return;
};

export default useOrganizationsObserver;
