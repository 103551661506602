class ClaimsSearch {
    format = (response) => {
        const searchResultMetadata = {
            startIndex: response.searchResultMetadata.startIndex,
            totalResults: response.searchResultMetadata.totalResults,
            itemsPerPage: response.searchResultMetadata.itemsPerPage,
            timeTaken: response.searchResultMetadata.resultMetaData.timeTaken,
            links: response.searchResultMetadata.resultMetaData._links,
        };
        const claimsSearch = [];

        if (response.claims && Array.isArray(response.claims)) {
            response.claims.forEach((claim) => {
                const paymentDetails = claim.claimPaymentsSummaryDetails || {};
                const recentPaymentDetails =
                    paymentDetails.recentPaymentDetails || {};
                const claimantDetails = claim.claimantDetails || {};
                const coverageDetails = claim.coverageDetails || {};
                const disabilityDetails = claim.disabilityDetails || {};
                const employeeInformation = claim.employeeInformation || {};

                const claimData = {
                    paymentPeriodEndDt:
                        recentPaymentDetails.paymentPeriodEndDt ?? null,
                    paymentRecentCheckAmt:
                        recentPaymentDetails.paymentRecentCheckAmt ?? null,
                    paymentGrossBenefitAmt:
                        recentPaymentDetails.paymentGrossBenefitAmt ?? null,
                    paymentDeductionsAmt:
                        recentPaymentDetails.paymentDeductionsAmt ?? null,
                    paymentOffsetsAmt:
                        recentPaymentDetails.paymentOffsetsAmt ?? null,
                    paymentEndDt:
                        recentPaymentDetails.paymentPeriodEndDt ?? null,
                    workerCompensationOffsetsAmt:
                        recentPaymentDetails.workerCompensationOffsetsAmt ??
                        null,
                    nonPaymentStartDt:
                        recentPaymentDetails.nonPaymentStartDt ?? null,
                    nonPaymentEndDt:
                        recentPaymentDetails.nonPaymentEndDt ?? null,
                    socialSecurityOffsetAmt:
                        paymentDetails.socialSecurityOffsetAmt ?? null,
                    otherOffsetsAmt:
                        recentPaymentDetails.OtherOffsetsAmt ?? null,
                    monthlyCoveredSalaryAmt:
                        coverageDetails.monthlyCoveredSalaryAmt ?? null,
                    paymentAmt: paymentDetails.paymentAmt,
                    monthsPaid: paymentDetails.monthsPaid,
                    checkNumber: recentPaymentDetails.checkNumber,
                    claimId: claim.claimId,
                    aliasClaimId: claim.aliasClaimId,
                    claimSystemCd: claim.claimSystemCd,
                    totalPaidToDtAmt: paymentDetails.totalPaidToDtAmt ?? null,
                    totalDaysPaidCount: paymentDetails.totalDaysPaidCount,
                    claimDaysPaidCount: paymentDetails.claimDaysPaidCount,
                    claimStatus: claim.claimStatus,
                    claimStatusReason: claim.claimStatusReason,
                    claimStatusEffectiveDt: claim.claimStatusEffectiveDt,
                    claimApprovedThruDt: claim.claimApprovedThruDt,
                    claimBenefitBeginDt: claim.claimBenefitBeginDt,
                    claimBenefitEndDt: claim.claimBenefitEndDt,
                    claimPaidThroughDate: claim.claimPaidThroughDate,
                    claimSocialSecurityAwardDt:
                        paymentDetails.claimSocialSecurityAwardDt,
                    claimClosedDt: claim.claimClosedDt,
                    eliminationPeriodEndDate: claim.eliminationPeriodEndDate,
                    workIncentiveBenefitInd:
                        recentPaymentDetails.workIncentiveBenefitInd,
                    claimMaxDurationDt: claim.claimMaxDurationDt,
                    claimReceivedTs: claim.claimReceivedTs,
                    fullName: claimantDetails.claimantDisplayName ?? null,
                    firstName: claimantDetails.firstName ?? null,
                    lastName: claimantDetails.lastName ?? null,
                    dateOfBirth: claimantDetails.dateOfBirth ?? null,
                    genderAtBirth: claimantDetails.genderAtBirth ?? null,
                    postalCode: claimantDetails.postalCode ?? null,
                    hireDt: claimantDetails.hireDt ?? null,
                    claimOwner:
                        claim.claimOwner !== undefined
                            ? claim.claimOwner.personDisplayName ?? null
                            : null,
                    personID:
                        claim.claimOwner !== undefined
                            ? claim.claimOwner.personID ?? null
                            : null,
                    digitalAddresses: claimantDetails.digitalAddresses ?? null,
                    digitalAddressText:
                        claimantDetails.digitalAddresses !== undefined
                            ? claim.claimantDetails.digitalAddresses.find(
                                  (d) => d.digitalAddressTypeCd === "HE" || d.digitalAddressType === "Personal Email"
                              )?.digitalAddressText ?? null
                            : null,
                    taxIdentificationNumber:
                        claimantDetails.taxIdentificationNumber ?? null,
                    employeeId: claimantDetails.employeeId ?? null,
                    divisionId: coverageDetails.divisionId ?? null,
                    divisionName: coverageDetails.divisionName ?? null,
                    weeklyCoveredSalaryAmt:
                        coverageDetails.weeklyCoveredSalaryAmt ?? null,
                    ReportingLocation:
                        employeeInformation.employeeLocationName ?? null,
                    eligibilityGroup: coverageDetails.eligibilityGroup ?? null,
                    eligibilityGroupDesc:
                        coverageDetails.eligibilityGroupDesc ?? null,
                    groupAgreementId: coverageDetails.groupAgreementID ?? null,
                    choiceCd: coverageDetails.choiceCd ?? null,
                    empContributionPremiumAmt:
                        coverageDetails.empContributionPremiumAmt ?? null,
                    claimCoverageTypeCd: claim.claimCoverageTypeCd,
                    disabilityBeginDt:
                        disabilityDetails.disabilityBeginDt ?? null,
                    lagDays: disabilityDetails.lagDays ?? null,
                    primaryDiagnosisCd:
                        disabilityDetails.primaryDiagnosisCd ?? null,
                    primaryDiagnosisDesc:
                        disabilityDetails.primaryDiagnosisDesc ?? null,
                    secondaryDiagnosisCd:
                        disabilityDetails.secondaryDiagnosisCd ?? null,
                    secondaryDiagnosisDesc:
                        disabilityDetails.secondaryDiagnosisDesc ?? null,
                    workLevel: disabilityDetails.workLevel ?? null,
                    workRelatedInd: disabilityDetails.workRelatedInd ?? null,
                    actualDeliveryDt:
                        disabilityDetails.actualDeliveryDt ?? null,
                    lastWorkedDt: disabilityDetails.lastWorkedDt ?? null,
                    partTimeRtwDt: disabilityDetails.partTimeRtwDt ?? null,
                    fullTimeRtwDt: disabilityDetails.fullTimeRtwDt ?? null,
                    // paymentAmount: d.paymentAmount,
                    // GrossBenefitAmt: d.GrossBenefitAmt,
                    // aliasClaimSystemCd: d.aliasClaimSystemCd,
                    // claimReceivedTs: d.claimReceivedTs,
                    //claimClosedDt: d.claimClosedDt,
                    // claimSubmitMethod: d.claimSubmitMethod,
                    // claimStatusCategory: d.claimStatusCategory,
                    // claimStatusSubCategory: d.claimStatusSubCategory,
                    // claimCoverageTypeCd: d.claimCoverageTypeCd,
                    // claimType: d.claimType,
                    // relatedClaimId: d.relatedClaimId,
                    // organizationName: d.organizationName
                    // maritalStatus: d.claimantDetails.maritalStatus,
                    // policyAdminSystemCd: d.coverageDetails.policyAdminSystemCd,
                    // groupAgreementID: d.coverageDetails.groupAgreementID,
                    // individualAgreementID: d.coverageDetails.individualAgreementID,
                    // divisionId: d.coverageDetails.divisionId,
                    // productGroupCd: d.coverageDetails.productGroupCd,
                    // longProductName: d.coverageDetails.longProductName,
                    // prodClmSystemAliasCd: d.coverageDetails.prodClmSystemAliasCd,
                    // prodClmSystemAliasDesc: d.coverageDetails.prodClmSystemAliasDesc,
                    // fundingType: d.coverageDetails.fundingType,
                    // disabilityBasisDesc: d.disabilityDetails.disabilityBasisDesc,
                    // pregnancyRelatedInd: d.disabilityDetails.pregnancyRelatedInd,
                    // mvaRelatedInd: d.disabilityDetails.mvaRelatedInd,
                    // primaryDiagnosisCategoryDesc: d.disabilityDetails.primaryDiagnosisCategoryDesc,
                };

                claimsSearch.push(claimData);
            });
        }

        return { claimsSearch, searchResultMetadata };
    };
}

export default new ClaimsSearch();
