import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { paymentHistoryType } from "../../constants";

const  FooterNote=forwardRef(({ date, reportViewType = "" },ref) =>{
    const spanRef1=useRef(null)
    const spanRef2=useRef(null)
    useImperativeHandle(ref,()=>({
        span1:spanRef1.current,
        span2:spanRef2.current,
    }))
    return (
        <div className="report-disclaimer" tabIndex={0}>
            <div className="disclaimer-date-time">Run as of: {date}</div>

            <span ref={spanRef1} className="d-block fs-xs">
                It is imperative that recipients of these reports recognize the
                sensitive nature of the detail they contain and handle them with
                the same discretion as other confidential human resource
                documents.
            </span>
            {reportViewType === paymentHistoryType && (
                <span ref={spanRef2} className="d-block fs-xs">
                    The information shown on this report is for claim status
                    purposes only. It should not be used for an analysis of
                    claims experience since the payment information may not
                    reflect a full month’s benefits and may not reflect true
                    claim liability due to payment adjustments, offsets,
                    deductions, and other factors.<br/> For employers who previously
                    arranged for Unum to handle depositing their portion of
                    Employer FICA, the amounts reported in the Employer SS tax
                    (6.2%) and the Medicare Tax (1.45%) columns accurately
                    reflect Unum’s deposit on your behalf.<br/> For employers who
                    deposit their own FICA portions, this information on this
                    report should not be used for tax reporting purposes. Please
                    use the official quarterly reports provided by Unum’s Tax
                    Reporting Group.<br/>
                </span>
            )}
        </div>
    );
})

export default FooterNote;
