import { createSlice } from "@reduxjs/toolkit";
import OrganizationRepository from "../repositories/OrganizationRepository";

const userSlice = createSlice({
    name: "userDetails",
    initialState: {
        policies: [],
        organizations: [],
        parentOrganizations: [],
        reportEnabledOrganizations: [],
        loaded: false,
        isLoading: false,
        error: null,
    },
    reducers: {
        setUserDetails(state, action) {
            state.policies = action.payload.policies;
            state.organizations = action.payload.organizations;
            state.parentOrganizations =
                OrganizationRepository.getParentOrganizations(
                    action.payload.organizations
                );
            state.loaded = true;
            state.reportEnabledOrganizations = action.payload.reportEnabledOrgs;
        },
        setUserDetailsLoadingStatus(state, action) {
            state.isLoading = action.payload.isLoading;
            state.error = action.payload.error;
        },
    },
});

export const { setUserDetails, setUserDetailsLoadingStatus, setReportEnabledOrganizations } =
    userSlice.actions;

export default userSlice.reducer;
