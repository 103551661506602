import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

const BreadcrumbPortal = () => (
    <nav aria-label="Breadcrumb" id="breadcrumb" tabIndex={-1}></nav>
);

const Breadcrumb = ({ links }) => {
    const [portalNode, setPortalNode] = useState();

    useEffect(() => {
        setPortalNode(document.getElementById("breadcrumb"));
    }, []);

    return portalNode
        ? ReactDOM.createPortal(
              links.map((link, index) => (
                  <span
                      className={links.length === index + 1 ? "text-bold" : ""}
                      key={index}
                  >
                      {links.length === index + 1 ? (
                          <span>{link.name}</span>
                      ) : (
                          <Link to={link.path}>{link.name}</Link>
                      )}
                  </span>
              )),
              portalNode
          )
        : null;
};

export { Breadcrumb, BreadcrumbPortal };
