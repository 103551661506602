import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config";
import { cacheDataForInSeconds } from "../constants";

const enterpriseInformationApiSlice = createApi({
    reducerPath: "enterpriseInformationApi",
    keepUnusedDataFor: cacheDataForInSeconds ?? 300,
    refetchOnMountOrArgChange: cacheDataForInSeconds ?? 300,
    baseQuery: fetchBaseQuery({
        baseUrl: config.REACT_APP_ENTERPRISE_INFO_API_URL,
        credentials: "include",
        // mode: "no-cors",
        prepareHeaders: (headers) => {
            headers.set("Accept", "application/json");
            headers.set("Content-Type", "application/json");

            const apiKey = process.env.REACT_APP_API_KEY;
            if (apiKey) headers.set("apikey", apiKey);

            return headers;
        },
    }),
    endpoints: () => ({}),
});

export default enterpriseInformationApiSlice;
