import config from "./../config";

class CookieService {
    get(name) {
        let cookieArray = document.cookie.split(";");
        let returnVal = null;
        for (let i = 0; i < cookieArray.length; i++) {
            let nameEQ = name + "=";
            while (cookieArray[i].charAt(0) == " ")
                cookieArray[i] = cookieArray[i].substring(
                    1,
                    cookieArray[i].length
                );

            if (cookieArray[i].indexOf(nameEQ) == 0) {
                returnVal = cookieArray[i].substring(
                    nameEQ.length,
                    cookieArray[i].length
                );
                break;
            }
        }
        return returnVal;
    }

    delete(name) {
        if (config.REACT_APP_ENV === "local") {
            document.cookie =
                name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        } else {
            document.cookie =
                name +
                "=; domain=.unum.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
    }
}

export default new CookieService();
