import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config";
import { cacheDataForInSeconds } from "../constants";

let reactBaseurl = "";
const { user } = (state) => state.auth;
if (user !== null) {
    if(user?.isUserInternal === true){
        reactBaseurl = config.REACT_APP_API_URL;
    }
    else if(user?.isUserInternal === false){
        reactBaseurl = config.REACT_APP_EXTERNAL_PREFERENCE_API_URL;
    }
}

const tableauUserApiSlice = createApi({
    reducerPath: "UserReports",
    keepUnusedDataFor: cacheDataForInSeconds ?? 300,
    refetchOnMountOrArgChange: cacheDataForInSeconds ?? 300,
    baseQuery: fetchBaseQuery({
        baseUrl: reactBaseurl,
        credentials: "include",
        prepareHeaders: (headers) => {
            headers.set("Accept", "application/json");
            headers.set("Content-Type", "application/json");

            const apiKey = process.env.REACT_APP_API_KEY;
            if (apiKey) headers.set("apikey", apiKey);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCustomUsers: builder.query({
            query: () => ({
                url: `/${process.env.REACT_APP_API_VERSION}/groups${config.REACT_APP_ENV_NAME}`,
                method: "GET",
                params: {
                    siteId: config.REACT_APP_TABLEAU_API_SITEID,
                },
            }),
        }),
    }),
});
export const { useLazyGetCustomUsersQuery } = tableauUserApiSlice;

export default tableauUserApiSlice;
