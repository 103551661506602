import React from "react";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import RadioButtons from "./RadioButtons";
import { useSelector } from "react-redux";
import { getReportBreadcrumbLinks } from "../../utils/helpers";
import LoadInternalUserCookie from "../../components/LoadInternalUserCookie";
import { useFlags } from "launchdarkly-react-client-sdk";

const ReportTypeSelection = () => {
    const { lastSelectedOrganization, lastSelectedEmployee } = useSelector(
        (state) => state.organizations
    );
    const { displayReports } = useFlags();

    return (
        <>
            <LoadInternalUserCookie />

            <Breadcrumb
                links={getReportBreadcrumbLinks(
                    lastSelectedOrganization,
                    lastSelectedEmployee
                )}
            />
            {!displayReports ? (
                <div className="reportpage">
                    <div>
                        A new<b> Report</b> feature is coming soon... In the
                        meantime <br />
                        you can still access the <b>Report</b> in
                        <b> Claim & Leave InSight (CLI).</b>
                    </div>
                </div>
            ) : (
                <RadioButtons />
            )}
        </>
    );
};

export default ReportTypeSelection;
