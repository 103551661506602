class OrganizationAccess {
    _format = (data) => {
        return {
            ORGANIZATIONID: data.OrganizationId,
            SSNACCESS: data.SSN,
            HISTORICALLEAVEACCESS: data.HistoricalLeave,
            SelfInsuredDisabilityClaimAccess: data.SelfInsuredClaim,
        };
    };

    format = (data) => {
        const list = [];

        for (let i = 0; i < data.length; i++) {
            list.push(this._format(data[i]));
        }

        return list;
    };
}

export default new OrganizationAccess();
