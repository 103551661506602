import config from "../../config";
import { noDataPlaceholder } from "../../constants";
import GTM from "../../GTM";
import {
    checkWhiteSpace,
    formatClaimDate,
    numberFormat,
} from "../../utils/helpers";

const getClaimHistoryView = (data, organization, user) => {
    const gtm = new GTM(null);
    const handleClaimHistoryLink = () => {
        gtm.Event(
            "claim & leave",
            "link",
            "click",
            "view",
            `${data.Header.Coverage.toLowerCase()}`,
            "",
            "view_cliam_history",
            ""
        );
    };

    const getHistoryLink = () => {
        if (user?.isUserInternal === true) {
            return config.REACT_APP_ISERVICES_URL;
        } else {
            return (
                config.REACT_APP_ISERVICES_SSO_URL +
                config.REACT_APP_ISERVICES_URL
            );
        }
    };

    return organization?.HISTORICALLEAVEACCESS === "Show" ? (
        <a
            target="_blank"
            href={getHistoryLink() + data.Header.ClaimNumber}
            className="d-block link"
            rel="noreferrer"
            title="View Claim History link"
            onClick={() => {
                handleClaimHistoryLink();
            }}
        >
            View
        </a>
    ) : (
        <span className="d-block">No Access</span>
    );
};

const COLUMNS = {
    CLAIM_NUMBER: {
        label: "Claim Number",
        accessor: (data) => data.Header.ClaimNumber,
    },

    COVERAGE: {
        label: "Coverage",
        accessor: (data) => data.Header.Coverage,
    },

    POLICY_NUMBER: {
        label: "Policy Number",
        accessor: (data) => data.Header.PolicyNo,
    },

    DIVISION: {
        label: "Division",
        accessor: (data) => data.ClaimStatus.Division,
    },

    ELIGIBILITY_GROUP: {
        label: "Eligibility Group",
        accessor: (data) => data.ClaimStatus.EligibilityGroup,
    },

    CHOICE: {
        label: "Choice",
        accessor: (data) => data.ClaimStatus.Choice,
    },

    CURRENT_STATUS: {
        label: "Current Status",
        accessor: (data) => data.Header.CurrentStatus,
    },

    STATUS_REASON: {
        label: "Status Reason",
        accessor: (data) => data.ClaimStatus.StatusReason,
    },

    RECEIVED_DATE: {
        label: "Received Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.ReceivedDate),
    },

    LAST_PAYMENT_AMOUNT: {
        label: "Last Payment Amount",
        accessor: (data) => numberFormat(data.ClaimStatus?.LastPaymentAmount),
    },

    LAST_PAYMENT_DATE: {
        label: "Last Payment Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.LastPaymentDate),
    },

    TOTAL_PAID_TO_DATE: {
        label: "Total Paid to Date",
        accessor: (data) => numberFormat(data.ClaimStatus?.TotalPaidToDate),
    },

    CLAIM_HISTORY: {
        label: "Claim History",
        accessor: (data, organization, user) => {
            return getClaimHistoryView(data, organization, user);
        },
    },

    PAYMENT_STATUS: {
        label: "Payment Status",
        accessor: (data) => data.ClaimStatus.PaymentStatus,
    },

    TOTAL_PAYMENT_AMOUNT: {
        label: "Total Payment Amount",
        accessor: (data) => numberFormat(data.ClaimStatus?.TotalPaymentAmount),
    },

    DAYS_PAID: {
        label: "Days Paid",
        accessor: (data) =>
            data.ClaimStatus.DaysPaid
                ? data.ClaimStatus.DaysPaid
                : noDataPlaceholder,
    },

    INCURRED_DATE: {
        label: "Incurred Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.IncurredDate),
    },

    RETIREE_INDICATOR: {
        label: "Retiree Indicator",
        accessor: (data) => data.ClaimStatus.RetireeIndicator,
    },

    DATE_OF_DEATH: {
        label: "Date of Death",
        accessor: (data) => formatClaimDate(data.ClaimStatus.DateOfDeath),
    },

    CAUSE_OF_DEATH: {
        label: "Cause of Death",
        accessor: (data) => data.ClaimStatus.CauseOfDeath,
    },

    CLAIM_CLOSURE_DATE: {
        label: "Claim Closure Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.ClaimClosedDate),
    },

    CLAIM_CLOSURE_REASON: {
        label: "Claim Closure Reason",
        accessor: (data) => data.ClaimStatus.ClaimClosedReason,
    },

    CLAIMANTS: {
        label: "Claimant(s)",
        accessor: (data) => data.ClaimStatus.Claimants,
    },

    INSURED_RELATIONSHIP: {
        label: "Insured Relationship",
        accessor: (data) => data.ClaimStatus.InsuredRelationship,
    },

    LIFE_BENEFITS_SPECIALIST: {
        label: "Life Benefits Specialist",
        accessor: (data) => data.ClaimStatus.LifeBenefitsSpecialist,
    },

    DISABILITY_DATE: {
        label: "Disability Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.DisabilityDate),
    },

    BENEFIT_START_DATE: {
        label: "Benefit Start Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.BenefitStartDate),
    },

    APPROVED_THROUGH_DATE: {
        label: "Approved Through Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.ApprovedThruDate),
    },

    MAX_DURATION_DATE: {
        label: "Maximum Duration Date",
        accessor: (data) =>
            formatClaimDate(data.ClaimStatus.MaximumDuartionDate),
    },

    MONTHS_PAID: {
        label: "Months Paid",
        accessor: (data) => data.ClaimStatus.MonthsPaid,
    },

    PAID_THROUGH_DATE: {
        label: "Paid Through Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.PaidThroughDate),
    },

    WORK_RELATED: {
        label: "Work Related",
        accessor: (data) => data.ClaimStatus.WorkRelated,
    },

    SSDI_AWARD_DATE: {
        label: "SSDI Award Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.SSDIAwardDate),
    },

    RELATED_LEAVES: {
        label: "Related Leaves",
        accessor: (_data) => {
            "";
        },
    },

    DISABILITY_BENEFITS_SPECIALIST: {
        label: "Disability Benefits Specialist",
        accessor: (data) => data.ClaimStatus.DisabilityBenefitsSpecialist,
    },

    DIAGNOSIS_CODE: {
        label: "Diagnosis Code",
        accessor: (data) =>
            checkWhiteSpace(data.ClaimStatus.DiagnosticCode, noDataPlaceholder),
    },

    DIAGNOSIS_DESCRIPTION: {
        label: "Diagnosis Description",
        accessor: (data) =>
            checkWhiteSpace(
                data.ClaimStatus.DiagnosticDescription,
                noDataPlaceholder
            ),
    },

    RTW_FT: {
        label: "Return to Work Date (FT)",
        accessor: (data) =>
            formatClaimDate(data.ClaimStatus.ReturntoWorkDateFT),
    },

    RTW_PT: {
        label: "Return to Work Date (PT)",
        accessor: (data) =>
            formatClaimDate(data.ClaimStatus.ReturntoWorkDatePT),
    },

    BASE_COVERAGE: {
        label: "Base Coverage",
        accessor: (data) => numberFormat(data.ClaimStatus?.BaseCoverage),
    },

    SUPPLEMENTAL_COVERAGE: {
        label: "Supplemental Coverage",
        accessor: (data) =>
            numberFormat(data.ClaimStatus?.SupplementalCoverage),
    },

    TOTAL_COVERAGE: {
        label: "Total Coverage",
        accessor: (data) => numberFormat(data.ClaimStatus?.TotalCoverage),
    },

    APPROVAL_DATE: {
        label: "Approval Date",
        accessor: (data) => formatClaimDate(data.ClaimStatus.ApprovalDate),
    },
};

export default COLUMNS;
