import React, { useState, useEffect } from "react";

function ProgressBar() {
    const [completed, setCompleted] = useState(0);

    let interval;

    const complState = () => {
        if (parseInt(completed) >= 25 && parseInt(completed) < 50) {
            return "Optimizing results...";
        } else if (parseInt(completed) > 0 && parseInt(completed) < 25) {
            return "Requesting details...";
        } else if (parseInt(completed) >= 50 && parseInt(completed) < 100) {
            return "Processing data...";
        }
        return "";
    };

    useEffect(() => {
        interval = setInterval(() => {
            setCompleted((c) => {
                return c + 0.25;
            });
        }, 30);

        if (completed === 95) {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [complState]);

    return (
        <div className="progress-bar-container">
            <div className="d-flex flex-column">
                <div className="progress-text">{complState()}</div>

                <div className="d-flex align-items-center">
                    <div className="flex-1"></div>
                    <i
                        className="fa-solid fa-laptop"
                        style={{
                            fontSize: 31,
                            marginRight: 50,
                        }}
                    ></i>

                    <div className="progress-container">
                        <div
                            style={{ width: `${completed}%` }}
                            className="progress-filler"
                        ></div>
                    </div>

                    <i
                        className="fa-solid fa-cloud"
                        style={{
                            fontSize: 31,
                            marginLeft: 50,
                        }}
                    ></i>

                    <div className="flex-1"></div>
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;
