class ClaimsPaymentSearch {
    format = (response) => {
        const searchResultMetadata = {
            startIndex: response.searchResultMetaData.startIndex,
            totalResults: response.searchResultMetaData.totalResults,
            itemsPerPage: response.searchResultMetaData.itemsPerPage,
            timeTaken: response.searchResultMetaData.resultMetaData.timeTaken,
            links: response.searchResultMetaData.resultMetaData._links,
        };
        const claimsPaymentSearch = [];

        if (response.claims && Array.isArray(response.claims)) {
            response.claims.forEach((claim) => {
                const claimData = {
                    fullName: claim.claimantDisplayName ?? null,
                    // firstName: claimantDetails.firstName ?? null, //not in payments response, Chris is working on it
                    // lastName: claimantDetails.lastName ?? null, //not in payments response, Chris is working on it
                    employeeId: claim.employeeId ?? null,

                    taxIdentificationNumber: claim.taxIdentificationNumber,
                    groupAgreementId: claim.groupAgreementId,
                    divisionId: claim.divisionId,
                    eligibilityGroup: claim.eligibilityGroup,
                    claimCoverageTypeCd: claim.claimCoverageTypeCd,
                    claimId: claim.claimId,
                    legacyClaimNumber: claim.aliasClaimId,
                    choiceCd: claim.choiceCd ?? null,
                    disabilityDate: claim.disabilityBeginDt ?? null,
                    workState: claim.employeeWorkStateCd ?? null,
                };

                const paymentDetails = claim.payments || {};

                if (paymentDetails && Array.isArray(paymentDetails)) {
                    paymentDetails.forEach((payment) => {
                        const claimPaymentSummary =
                            payment.claimPaymentSummary || {};

                        const paymentData = {
                            payee: payment.payeeDisplayName ?? null,
                            employeeSSTax:
                                claimPaymentSummary.employeeSocialSecurityTaxAmt ??
                                null,
                            federalWithholding:
                                claimPaymentSummary.federdalWithHoldingTaxAmt ??
                                null,
                            stateWithholding:
                                claimPaymentSummary.stateWithHoldingTaxAmt ??
                                null,
                            checkEftNumber: payment.checkNumber ?? null,
                            paymentAmount: payment.paymentAmt ?? null,
                            paymentDate: payment.paymentIssueDt ?? null,
                            paymentStartDate:
                                payment.paymentPeriodBeginDt ?? null,
                            paymentEndDate: payment.paymentPeriodEndDt ?? null,
                            paymentStatus: payment.paymentStatus ?? null,
                            socialSecurityOffsetAmt:
                                claimPaymentSummary.socialSecurityOffsetAmt ??
                                null,
                            grossBenefitInPeriod:
                                claimPaymentSummary.grossBenefitAmt ?? null,
                            nonPaymentStartDt:
                                payment.nonPayableBeginDt ?? null,
                            nonPaymentEndDt: payment.nonPayableEndDt ?? null,
                            localWithholding:
                                claimPaymentSummary.localWithHoldingTaxAmt ??
                                null,
                            stateOffsetAmt:
                                claimPaymentSummary.stateOffsetAmt ?? null,
                            dentalInsurancePremiumAmt:
                                claimPaymentSummary.dentalInsurancePremiumAmt ??
                                null,
                            overpaymentReduction:
                                claimPaymentSummary.overReductionPaymentAmt ??
                                null,
                            postTaxTotal:
                                claimPaymentSummary.postTaxTotalAmt ?? null,
                            preTaxMedicalInsurance:
                                claimPaymentSummary.preTaxMedicalInsTotalAmt ??
                                null,
                            preTaxTotal:
                                claimPaymentSummary.preTaxTotalAmt ?? null,
                            paymentType: payment.paymentTransmitMethod ?? null,
                            wcOffset:
                                claimPaymentSummary.workersCompensationOffsetAmt ??
                                null,
                            allOtherDeductions:
                                claimPaymentSummary.allOtherOffsetAmt ?? null,
                            employerSSTax:
                                claimPaymentSummary.employerSocialSecurityTaxAmt ??
                                null,
                            medicareTax:
                                claimPaymentSummary.medicareTaxAmt ?? null,
                        };

                        const combinedData = { ...claimData, ...paymentData };

                        claimsPaymentSearch.push(combinedData);
                    });
                }
            });
        }
        return { claimsPaymentSearch, searchResultMetadata };
    };
}

export default new ClaimsPaymentSearch();
