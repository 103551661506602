import React from "react";
import { Accordion } from "react-bootstrap";
import IntermittentAbsence from "./IntermittentAbsence.js";
import LeavePeriods from "./../period_protections/LeavePeriods";
import Divider from "../../components/ui/Divider";
import AccordionToggle from "../../components/AccordionToggle.js";
import AvailableTime from "./AvailableTime.js";

function LeaveRecordDetails({ leaveDetailsEmp, print, leaveNo }) {
    return (
        <div className="section-card container-leave-record ps-0 pe-0 mb-20p ">
            <Accordion defaultActiveKey="0" flush tabIndex={0}>
                <AccordionToggle eventKey="0" attribute1="leave summary">
                    Leave Record
                </AccordionToggle>

                <Accordion.Collapse eventKey="0">
                    <>
                        <AvailableTime
                            print={print}
                            data={
                                leaveDetailsEmp.AvailableTime
                                    ? leaveDetailsEmp.AvailableTime
                                    : []
                            }
                            leaveNo={leaveNo}
                        />

                        <LeavePeriods
                            periodProtectionData={
                                leaveDetailsEmp.LeavePeriod ?? []
                            }
                            protections={leaveDetailsEmp.Protection ?? []}
                            print={print}
                        />
                        {leaveDetailsEmp.LeavePeriod.length > 10 && (
                            <Divider mx="" className="mt-5p" />
                        )}

                        <IntermittentAbsence
                            data={
                                leaveDetailsEmp?.IntermittentAbsence
                                    ? leaveDetailsEmp?.IntermittentAbsence
                                    : []
                            }
                            print={print}
                        />
                    </>
                </Accordion.Collapse>
            </Accordion>
        </div>
    );
}

export default LeaveRecordDetails;
