import Employee from "../models/Employee";
import ClaimDetails from "../models/ClaimDetails";
import apiSlice from "./apiSlice";
import config from "./../config";
import ClaimLeaveData from "../models/ClaimLeaveData";

const employeesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => {
        return {
            getEmployees: builder.query({
                query: ({ organizationId, filters }) => ({
                    url: `/employees/${process.env.REACT_APP_API_VERSION}${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        OrgId: organizationId,
                        LeaveNo: filters.leaveNumber
                            ? filters.leaveNumber
                            : null,
                        ClaimNo: filters.claimNumber
                            ? filters.claimNumber
                            : null,
                        OrgName: null,
                        PolicyId: null,
                        EEID: filters.eeid ? filters.eeid : null,
                    },
                    headers: {
                        "x-unum-SSN": !filters.ssn ? "null" : filters.ssn,
                        "x-unum-LastName":
                            filters.lastName === "" ? "null" : filters.lastName,
                        "x-unum-FirstName":
                            filters.firstName === ""
                                ? "null"
                                : filters.firstName,
                    },
                }),
                invalidatesTags: (_) => ["employees"],
                transformResponse: (response, _meta, _arg) => {
                    return Employee.format(response.employeeList);
                },
            }),

            getEmployeeDetails: builder.query({
                query: ({ employeeId, organizationId }) => ({
                    url: `/employees/${process.env.REACT_APP_API_VERSION}/${employeeId}/absences${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        OrgId: organizationId,
                        IsClaimOrLeave: 3,
                        isInternalUser: 1,
                    },
                }),
                invalidatesTags: (_) => ["employeeDetails"],
                transformResponse: (response, _meta, _arg) => {
                    const claimLeaveData = ClaimLeaveData.format(response);

                    return {
                        EmployeeDetails:
                            response.EmployeeDetails[0].SSN === null &&
                            response.EmployeeDetails.length > 1
                                ? response.EmployeeDetails[1]
                                : response.EmployeeDetails[0],

                        ClaimLeaveData: claimLeaveData,
                    };
                },
            }),

            getEmployeeLeaveDetails: builder.query({
                query: ({ employeeId, leaveNo, organizationId }) => ({
                    url: `/employees/${process.env.REACT_APP_API_VERSION}/${employeeId}/leaves/${leaveNo}${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        OrgId: organizationId,
                    },
                }),
                invalidatesTags: (_) => ["leaveDetails"],
            }),

            getEmployeeClaimDetails: builder.query({
                query: ({ employeeId, claimNo, organizationId }) => ({
                    url: `/employees/${process.env.REACT_APP_API_VERSION}/${employeeId}/claims/${claimNo}${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        OrgId: organizationId,
                    },
                }),
                invalidatesTags: (_) => ["claimDetails"],
                transformResponse: (response, _meta, _arg) => {
                    return ClaimDetails.format(response);
                },
            }),

            getOrganizationAccess: builder.query({
                query: ({ organizationIds }) => ({
                    url: `/employees/${process.env.REACT_APP_API_VERSION}/user-organization-access${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        OrgId: organizationIds,
                    },
                }),
            }),
        };
    },
    overrideExisting: false,
});

export const {
    useLazyGetEmployeesQuery,
    useGetEmployeeDetailsQuery,
    useLazyGetEmployeeDetailsQuery,
    useGetEmployeeLeaveDetailsQuery,
    useGetEmployeeClaimDetailsQuery,
    useGetOrganizationAccessQuery,
    useLazyGetOrganizationAccessQuery,
} = employeesApiSlice;

export default employeesApiSlice;
