import { createSlice } from "@reduxjs/toolkit";

const preferenceSlice = createSlice({
    name: "preferenceSlice",
    initialState: {
        homePreference: [],
        organizationPreference: [],
        userSelectedOrganizationPreference: [],
        searchPreference: [],
        loginMessage: "",
        releaseHeader: "",
        releaseLink: "",
        releaseContent: "",
        isPreferenceChanged: false,
        isPreferencedDataFetched: false,
    },
    reducers: {
        setUserPreference(state, action) {
            state.homePreference = action.payload.homePreference;
            state.organizationPreference =
                action.payload.organizationPreference;
            state.userSelectedOrganizationPreference =
                action.payload.userSelectedOrganizationPreference;
            state.searchPreference = action.payload.searchPreference;
        },
        setReleaseMessage(state, action) {
            state.releaseHeader = action.payload.releaseHeader;
            state.releaseContent = action.payload.releaseContent;
            state.releaseLink = action.payload.releaseLink;
        },
        clearReleaseMessage(state, action) {
            state.releaseHeader = null;
            state.releaseContent = null;
            state.releaseLink = null;
        },
        setPreferenceChanged(state, action) {
            state.isPreferenceChanged = action.payload;
        },
        setLoginMessage(state, action) {
            state.loginMessage = action.payload.loginMessage;
        },
        setPreferenceDataFetched(state, action) {
            state.isPreferencedDataFetched = action.payload;
        },
        setOrganisationPreference(state, action) {
            state.homePreference = state.homePreference;
            state.organizationPreference =
                action.payload.organizationPreference;
            state.userSelectedOrganizationPreference = state.userSelectedOrganizationPreference;
            state.searchPreference = state.searchPreference;
        },
    },
});

export const {
    setUserPreference,
    setPreferenceChanged,
    setLoginMessage,
    setReleaseMessage,
    clearReleaseMessage,
    setPreferenceDataFetched,
    setOrganisationPreference
} = preferenceSlice.actions;

export default preferenceSlice.reducer;
