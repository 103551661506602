import React from "react";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import { useSelector } from "react-redux";

export default function Dashboard() {
    const { user, config } = useSelector((state) => ({
        user: state.auth.user,
        config: state.app.config,
    }));

    const handleClick = () => {
        if (user?.isUserInternal) {
            window.open(config.REACT_APP_CLI_INTERNAL, "_blank");
        } else {
            window.open(config.REACT_APP_CLI, "_blank");
        }
    };
    return (
        <div>
            <Breadcrumb
                links={[
                    {
                        path: "/search",
                        name: "Search",
                    },
                    {
                        name: "Dashboard",
                    },
                ]}
            />
            <div className="reportpage">
                <div>
                    A new<b> dashboard</b> feature is coming soon... In the
                    meantime <br />
                    you can still access the <b>dashboard</b> in{" "}
                    <span
                        className="cursor-pointer"
                        onClick={handleClick}
                        onKeyDown={handleClick}
                    >
                        Claim & Leave InSight (CLI).
                    </span>
                </div>
            </div>
        </div>
    );
}
