import React from "react";
import { useNavigate } from "react-router-dom";
import GTM from "../GTM";

import mainLogo from "../assets/images/unum-Insights-logo.svg";
import landingPageImage from "../assets/images/landing-page-image.jpg";
import SearchIcon from "./icons/SearchIcon";
import ReportIcon from "./icons/ReportIcon";
import { useSelector } from "react-redux";

const LandingPage = () => {
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const gtm = new GTM(null);

    const { parentOrganizations: organizations } = useSelector(
        (state) => state.userDetails
    );

    const handleGtmTags = (label) => {
        gtm.Event(
            "top nav",
            "link",
            "click",
            label,
            "top nav",
            "",
            "navigation_click",
            ""
        );
    };

    const handleSearch = () => {
        if (user !== null) {
            let isAllSame = true;

            if (organizations.length > 0) {
                const firstOrganizationGroupId =
                    organizations[0].ORGANIZATIONGROUPID;

                for (const item of organizations) {
                    const organizationGroupId = item.ORGANIZATIONGROUPID;

                    if (
                        organizationGroupId === null ||
                        organizationGroupId !== firstOrganizationGroupId
                    ) {
                        isAllSame = false;
                        break;
                    }
                }
            }
            if (isAllSame || organizations?.length === 1) {
                let selectedOrganization = [...organizations].sort((a, b) =>
                    a.ORGNIZATIONNAME.localeCompare(b.ORGNIZATIONNAME)
                );

                navigate(
                    `/organization/${selectedOrganization[0]?.ORGANIZATIONID}/employees/search`,
                    { replace: true }
                );
            } else {
                navigate("/search", { replace: true });
            }
        }
    };

    return (
        <div>
            <div
                className="d-flex justify-content-center"
                style={{ marginTop: 28.5 }}
            >
                <div className="section-card landing-page">
                    <div className="content">
                        <img
                            src={mainLogo}
                            className="pb-16p"
                            alt="logo"
                            width="284px"
                        />
                        <label className="header">
                            A modern and responsive platform for viewing claim
                            and leave records
                        </label>
                        <div
                            className="d-flex mb-32p sub-content"
                            tabIndex={0}
                            onClick={handleSearch}
                        >
                            <div className="icon ">
                                <SearchIcon />
                            </div>

                            <span>
                                <label>Search</label>
                                <p className="fs-xs mb-0p">
                                    Find important employee information with ease
                                </p>
                            </span>
                        </div>
                        <div
                            className="d-flex mb-32p sub-content"
                            tabIndex={0}
                            onClick={() => {
                                navigate("/reportingcapabilities");
                                handleGtmTags("reports");
                            }}
                        >
                            <div className="icon">
                                <ReportIcon />
                            </div>

                            <span>
                                <label>Reports</label>
                                <p className="fs-xs mb-0p">
                                    View your organization’s data and create customizable reports
                                </p>
                            </span>
                        </div>
                    </div>
                    <div className="landing-page-image">
                        {" "}
                        <img src={landingPageImage} alt="Landing Page" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
