import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import Table from "../../components/table/Table";
import SectionHeading from "../../components/SectionHeading";
import ReactTooltip from "react-tooltip";
import GTM from "../../GTM";

function ClaimLeaveHistoryTable({
    handleLeaveNoSelection,
    handleClaimNoSelection,
    handleClaimHistoryData,
    handleSelectedOrgId,
    employeeLeaves,
    isLoading,
    print,
}) {
    const [showHistoricalLeaves, setShowHistoricalLeaves] = useState(!!print);
    const [totalHistoricalLeaves, setTotalHistoricalLeaves] = useState(0);
    const [data, setData] = useState([]);
    const [printData, setPrintData] = useState([]);
    const [tooltip, showTooltip] = useState(true);
    const [historicalLeaveMsg, sethistoricalLeaveMsg] = useState(
        "No historical leaves to display"
    );

    const gtm = new GTM(null);
    useEffect(() => {
        updateData(employeeLeaves);
        const historicalLeave = employeeLeaves.find(
            (d) => d.Historical === true
        );

        const countLeaveHistoricalINDY = employeeLeaves.filter(
            (leave) => leave.LeaveHistoricalIND === "N"
        ).length;

        setShowHistoricalLeaves(
            countLeaveHistoricalINDY === 0 && historicalLeave
        );
    }, [employeeLeaves]);

    useEffect(() => {
        if (isLoading === false) {
            const historicalLeave = employeeLeaves.find(
                (d) => d.Historical === true
            );

            if (historicalLeave || print === true) {
                setTotalHistoricalLeaves(1);
                sethistoricalLeaveMsg(
                    "Click here to view historical leaves <br /> in the history table"
                );
            }
        }
    }, [isLoading]);

    useEffect(() => {
        if (handleClaimHistoryData) handleClaimHistoryData(printData);
    }, [printData]);

    const sortColumnHeaderKeyMapping = {
        Coverage: "Coverage",
        Status: "CurrentStatus",
        "Claim / Leave #": "No",
        "Policy Div / Report Grp": "Group",
        "RTW Status": "RTWStatus",
        "Status Reason": "StatusReason",
        "Leave Reason / Diagnosis": "LeaveReason",

        "Status Date": "CurrentStatusDate",
        "Received Date": "ReceivedDate",
        "Last Day Worked": "LastWorkDate",
        "Start Date": "StartDate",
        "End Date": "EndDate",
        "Max Dur.": "MaximumDuration",
        "RTW Date": "RTWDate",
        "Disability Date": "Disability",
    };

    const handleSort = useCallback(
        (sortBy) => {
            if (sortBy.length === 0) {
                updateData(employeeLeaves);
                return;
            }

            if (sortBy[0].id in sortColumnHeaderKeyMapping) {
                const historicalRecords = employeeLeaves.filter(
                    (row) => row.Historical === true
                );

                const nonHistoricalRecords = employeeLeaves.filter(
                    (row) => row.Historical === false
                );

                const sortedEmployees = [
                    ...customSortFn(
                        historicalRecords,
                        sortBy[0],
                        sortColumnHeaderKeyMapping[sortBy[0].id]
                    ),
                    ...customSortFn(
                        nonHistoricalRecords,
                        sortBy[0],
                        sortColumnHeaderKeyMapping[sortBy[0].id]
                    ),
                ];

                setPrintData(sortedEmployees);

                const d = showHistoricalLeaves
                    ? sortedEmployees
                    : sortedEmployees.filter(
                          (leave) => leave.Historical !== true
                      );

                setData(d);
            }
        },
        [employeeLeaves, showHistoricalLeaves]
    );

    const updateData = (records) => {
        if (print !== true) {
            const recordsWithHistorical = [...records].sort((rowA, _) => {
                if (rowA.Historical === true) return -1;
                return 1;
            });

            setPrintData(recordsWithHistorical);

            const d = showHistoricalLeaves
                ? recordsWithHistorical
                : records.filter((leave) => leave.Historical !== true);

            setData(d);
        } else {
            setData(records);
        }
    };

    const customSortFn = (rows, sortBy, sortByKey) => {
        return rows.sort((rowA, rowB) => {
            let aField = rowA[sortByKey];
            let bField = rowB[sortByKey];

            if (typeof aField === "string" || aField instanceof String)
                aField = aField.toLowerCase();

            if (typeof bField === "string" || bField instanceof String)
                bField = bField.toLowerCase();

            if (aField === null) aField = "";
            if (bField === null) bField = "";

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return sortBy.desc === false ? comparison : comparison * -1;
        });
    };

    const getTrClasses = (row) => {
        return row.original.Historical === true ? "tr historical-tr" : "tr";
    };

    const handelClick = (label, attribute3) => {
        gtm.Event(
            "claim & leave",
            "button",
            "click",
            label,
            "employee history",
            "",
            attribute3,
            ""
        );
    };

    const ShowHistoricalLeaveAction = (
        <div
            style={{
                cursor: totalHistoricalLeaves === 0 ? "default" : "pointer",
                color: totalHistoricalLeaves === 0 ? "#AAAAAA" : "#025780",
            }}
            tabIndex="0"
        >
            <i
                className="fa-solid fa-info-circle"
                style={{ color: "#015294", fontSize: 16, paddingRight: 10 }}
                onMouseEnter={() => showTooltip(true)}
                onMouseLeave={() => {
                    showTooltip(false);
                    setTimeout(() => showTooltip(true), 50);
                }}
                data-tip
                data-for="tipForHistoricalLeave"
            ></i>
            <span
                style={{ textDecoration: "underline", fontWeight: 600 }}
                onClick={() => {
                    handelClick(
                        "Show Historical Leaves",
                        "Show_Historical_Leaves"
                    );
                }}
            >
                Show Historical Leaves
            </span>
            {tooltip && (
                <ReactTooltip
                    id="tipForHistoricalLeave"
                    place="bottom"
                    effect="solid"
                    backgroundColor="#015294"
                    className="tooltipStyle"
                    html={true}
                >
                    {historicalLeaveMsg}
                </ReactTooltip>
            )}
            <i
                className="fa-solid fa-circle-arrow-right ml-10p"
                onClick={() => {
                    handelClick("front arrow", "Show_Historical_Leaves");
                }}
            ></i>
        </div>
    );

    const HideHistoricalLeaveAction = (
        <div style={{ cursor: "pointer", color: "#025780" }}>
            <span
                style={{ textDecoration: "underline", fontWeight: 600 }}
                onClick={() => {
                    handelClick(
                        "Hide Historical Leaves",
                        "Hide_Historical_Leaves"
                    );
                }}
            >
                Hide Historical Leaves
            </span>
            <i
                className="fa-solid fa-circle-arrow-right ml-10p"
                style={{
                    transform: "rotate(180deg)",
                }}
                onClick={() => {
                    handelClick("Back arrow", "Hide_Historical_Leaves");
                }}
            ></i>
        </div>
    );

    let returnRecord = (isClaim, rowNum, orgIdNo) => {
        if (isClaim) {
            handleClaimNoSelection(rowNum);
            handleSelectedOrgId(orgIdNo);
        } else {
            handleLeaveNoSelection(rowNum);
            handleSelectedOrgId(orgIdNo);
        }
    };

    const formattedDate = (dateObject) => {
        return dateObject ? moment(dateObject).format("MM/DD/YYYY") : "";
    };

    function claimLeaveNo(row) {
        return (
            <span
                onClick={() => {
                    returnRecord(row.isClaim, row.No, row.OrganizationId);
                    gtm.Event(
                        "claim & leave",
                        "link",
                        "click",
                        "claim/leave",
                        "employee history",
                        "",
                        "field_interaction",
                        `${row.Coverage.toLowerCase()}`
                    );
                }}
                style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                }}
                className="sticky-no"
                tabIndex={0}
            >
                {row.No}
            </span>
        );
    }
    const tableColumns = React.useMemo(
        () => [
            {
                Header: "Employee History",
                sticky: "left",
                columns: [
                    {
                        Header: "Claim / Leave #",
                        width: 90,
                        tabIndex: 0,
                        accessor: (row) => {
                            return claimLeaveNo(row);
                        },
                    },
                ],
            },
            {
                Header: "B",
                columns: [
                    {
                        Header: "Policy Div / Report Grp",
                        accessor: (row) => row.Group,
                        sortable: true,
                        wrap: true,

                        width: print === true ? 250 : 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "Coverage",
                        accessor: (row) => row.Coverage,
                        sortable: true,
                        wrap: true,
                        maxWidth: 75,
                        minWidth: 75,
                        width: 75,
                        tabIndex: 0,
                    },
                    {
                        Header: "Status",
                        accessor: (row) => row.CurrentStatus,
                        sortable: true,
                        wrap: true,
                        maxWidth: 80,
                        minWidth: 80,
                        width: 80,
                        tabIndex: 0,
                    },
                    {
                        Header: "Status Reason",
                        accessor: (row) => row.StatusReason,
                        sortable: true,
                        wrap: true,

                        width: print === true ? 200 : 90,
                        tabIndex: 0,
                    },
                    {
                        id: "Leave Reason / Diagnosis",
                        Header: () => {
                            return (
                                <span>
                                    Leave Reason / <br /> Diagnosis
                                </span>
                            );
                        },
                        Cell: ({ row }) => {
                            return (
                                <span
                                    style={{
                                        whiteSpace:
                                            print === true ? "normal" : "auto",
                                    }}
                                >
                                    {row.original.LeaveReason}
                                </span>
                            );
                        },
                        sortable: true,
                        wrap: true,
                        canSort: true,
                        width: print ? 150 : 120,
                        tabIndex: 0,
                    },
                    {
                        Header: "Status Date",
                        accessor: (row) => formattedDate(row.CurrentStatusDate),
                        sortable: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "Received Date",
                        accessor: (row) => formattedDate(row.ReceivedDate),
                        sortable: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "Disability Date",
                        accessor: (row) => formattedDate(row.Disability),
                        sortable: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "Last Day Worked",
                        accessor: (row) => formattedDate(row.LastWorkDate),
                        sortable: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "Start Date",
                        accessor: (row) => formattedDate(row.StartDate),
                        sortable: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "End Date",
                        accessor: (row) => formattedDate(row.EndDate),
                        sortable: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "Max Dur.",
                        accessor: (row) => formattedDate(row.MaximumDuration),
                        sortable: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "RTW Date",
                        accessor: (row) => row.RTWDate,
                        sortable: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                    {
                        Header: "RTW Status",
                        accessor: (row) => row.RTWStatus,
                        sortable: true,
                        wrap: true,
                        maxWidth: 90,
                        minWidth: 90,
                        width: 90,
                        tabIndex: 0,
                    },
                ],
            },
        ],
        [employeeLeaves]
    );

    return (
        <div
            className={
                "section-card px-0 employee-history-table " +
                (!print && data.length <= 5 ? "pb-0" : "")
            }
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between align-items-start px-20p">
                        <SectionHeading heading="Employee History" />

                        <span
                            onClick={
                                totalHistoricalLeaves === 0
                                    ? null
                                    : () => {
                                          setShowHistoricalLeaves(
                                              !showHistoricalLeaves
                                          );
                                      }
                            }
                            id="show-hide-historical-btn"
                        >
                            {showHistoricalLeaves
                                ? HideHistoricalLeaveAction
                                : ShowHistoricalLeaveAction}
                        </span>
                    </div>

                    {employeeLeaves.length > 0 && (
                        <Table
                            columns={tableColumns}
                            className="page-brake"
                            data={data}
                            onSort={handleSort}
                            getTrClasses={getTrClasses}
                            manualSortBy={true}
                            sticky={true}
                            loading={isLoading}
                            pagination={!print && data.length > 5}
                            responsiveConfig={{
                                type: "COLUMN",
                                striped: false,
                                screen: "md",
                            }}
                            attribute1="employee history"
                            pageName="claim & leave"
                            numberOfRecords={10}
                            defaultPageSize={5}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(ClaimLeaveHistoryTable);
