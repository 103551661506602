import React, { useEffect } from "react";
import { useGetEmployeeClaimDetailsQuery } from "../../slices/employeesApiSlice";
import { recordsType } from "../../constants";
import { useDispatch } from "react-redux";
import {
    addLeaveNo,
    addRelatedNos,
} from "./../../slices/leaveClaimRecordsSlice";
import RequestFailed from "../../components/alerts/RequestFailed";
import Loader from "../../components/ui/Loader";
import SectionHeading from "../../components/SectionHeading";
import SectionSubheading from "../../components/SectionSubheading";
import ClaimPayments from "../claim_payments/ClaimPayments";
import Divider from "../../components/ui/Divider";
import config from "../../config";
import { logout } from "./../../slices/authSlice";
import NoRecords from "../../components/alerts/NoRecords";
import {
    groupLifeSections,
    ltdSections,
    siStdSections,
    stdSections,
    lifeWaiverSections,
    siLtdSections,
} from "./claim_record_sections";
import GTM from "../../GTM";

function ClaimRecord({
    claimNo,
    organizationId,
    organizationAccess,
    employeeId,
    print,
    fetchingClaim,
    user,
}) {
    let sections;
    let claimHeader;

    const dispatch = useDispatch();
    const gtm = new GTM(null);

    const {
        data = null,
        isFetching,
        isUninitialized,
        isError,
        error,
    } = useGetEmployeeClaimDetailsQuery({
        claimNo,
        organizationId,
        employeeId,
    });

    useEffect(() => {
        if (error && error.status === 401) {
            dispatch(logout());
        }
    }, [error]);

    if (data) {
        sections = [];

        if (data.Header != null) {
            switch (data.Header.Coverage) {
                case "LTD":
                    sections = ltdSections;
                    claimHeader = "LTD Claim Record";
                    break;
                case "SI-LTD":
                    sections = siLtdSections;
                    claimHeader = "SI-LTD Claim Record";
                    break;
                case "STD":
                    sections = stdSections;
                    claimHeader = "STD Claim Record";
                    break;
                case "SI-STD":
                    sections = siStdSections;
                    claimHeader = "SI-STD Claim Record";
                    break;
                case "Life Waiver":
                case "LW":
                case "LDRS":
                    claimHeader = "Life Waiver Claim Record";
                    sections = lifeWaiverSections;
                    break;
                case "Group Life":
                case "LIFE":
                    sections = groupLifeSections;
                    claimHeader = "Group Life Claim Record";
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        if (data === null) {
            return;
        }

        if (data.ClaimStatus?.RelatedLeaves) {
            dispatch(
                addRelatedNos({
                    nos: data.ClaimStatus.RelatedLeaves,
                    type: recordsType.LEAVE,
                })
            );
        }
    }, [data]);

    const handelClick = (label, attribute3) => {
        gtm.Event(
            "claim & leave",
            "link",
            "click",
            label,
            data.Header.Coverage.toLowerCase(),
            "",
            attribute3,
            ""
        );
    };

    const renderRelatedLeave = (v) => {
        return (
            <a
                className="cursor-pointer mr-10p link"
                key={v}
                onClick={(e) => {
                    e.preventDefault();
                    handelClick("related leaves", "field_interaction");

                    dispatch(addLeaveNo(v));
                }}
            >
                {v}
            </a>
        );
    };

    useEffect(() => {
        if (!print) fetchingClaim(isFetching); // Call the callback function
    }, [isFetching]);

    const getOutstandingLink = () => {
        return user?.isUserInternal === true
            ? config.REACT_APP_OUTSTANDING_INFO_ISERVICES_URL
            : config.REACT_APP_ISERVICES_SSO_URL +
                  config.REACT_APP_OUTSTANDING_INFO_ISERVICES_URL;
    };

    return (
        <section id={"claim-container-" + claimNo}>
            {isFetching && <Loader />}

            {data?.Header === null && (
                <NoRecords message="No Records Found" label="claim record" />
            )}

            {data !== null && data.Header !== null && !isFetching && (
                <>
                    <div className="section-card pb-0">
                        <SectionHeading heading={claimHeader} />

                        <div className="row">
                            {sections.map((section) => {
                                return (
                                    <>
                                        <div className="d-flex align-items-center mb-20p">
                                            <SectionSubheading
                                                subheading={section.type}
                                                className="mr-20p"
                                            />

                                            {section.type ===
                                                "Claim Status" && (
                                                <a
                                                    className="link text-semibold"
                                                    target="_blank"
                                                    href={
                                                        getOutstandingLink() +
                                                        data.Header.ClaimNumber
                                                    }
                                                    rel="noreferrer"
                                                    onClick={() => {
                                                        handelClick(
                                                            "outstanding information",
                                                            "navigation_click"
                                                        );
                                                    }}
                                                >
                                                    Outstanding Information
                                                </a>
                                            )}
                                        </div>

                                        {section.columns.map((c, index) => (
                                            <div
                                                className="col-lg-3 col-sm-4 col-6 col-print mb-20p"
                                                key={index}
                                                tabIndex={0}
                                            >
                                                <label className="section-label">
                                                    {c.label}
                                                </label>

                                                <span className="d-block">
                                                    {c.label ===
                                                    "Related Leaves"
                                                        ? data.ClaimStatus.RelatedLeaves?.map(
                                                              (v) =>
                                                                  renderRelatedLeave(
                                                                      v
                                                                  )
                                                          )
                                                        : c.accessor(
                                                              data,
                                                              organizationAccess,
                                                              user
                                                          )}
                                                </span>
                                            </div>
                                        ))}
                                    </>
                                );
                            })}
                        </div>

                        <div
                            style={{
                                marginLeft: -24,
                                marginRight: -24,
                            }}
                        >
                            {data.Header &&
                                !["LW", "Life Waiver"].includes(
                                    data.Header.Coverage
                                ) && (
                                    <>
                                        <Divider mt={"mt-0"} mx="" />

                                        <ClaimPayments
                                            claimPayments={data}
                                            print={print}
                                            noRecords={
                                                !isUninitialized &&
                                                !isFetching &&
                                                !isError &&
                                                data.Payments.length === 0
                                            }
                                            claimNo={claimNo}
                                        ></ClaimPayments>
                                    </>
                                )}
                        </div>
                    </div>
                </>
            )}

            {isError && <RequestFailed label="claim error" />}
        </section>
    );
}

export default ClaimRecord;
