import React, { useEffect, useState } from "react";

export default function ManageWeekly({
    handleDaysOfTheWeek,
    subscriptionInfo,
}) {
    const [selectedDays, setSelectedDays] = useState([]);

    useEffect(() => {
        if (
            subscriptionInfo !== undefined &&
            subscriptionInfo.Frequency === "Weekly" &&
            subscriptionInfo.DaysOfTheWeek.length > 0
        ) {
            setSelectedDays(
                subscriptionInfo.DaysOfTheWeek.map((day) => day.substring(0, 3))
            );
        }
    }, [subscriptionInfo]);

    useEffect(() => {
        const fullDayNames = selectedDays.map((day) => {
            switch (day) {
                case "Sun":
                    return "Sunday";
                case "Mon":
                    return "Monday";
                case "Tue":
                    return "Tuesday";
                case "Wed":
                    return "Wednesday";
                case "Thu":
                    return "Thursday";
                case "Fri":
                    return "Friday";
                case "Sat":
                    return "Saturday";
                default:
                    return day;
            }
        });
        handleDaysOfTheWeek(fullDayNames);
    }, [selectedDays]);

    const handleDayClick = (day) => {
        if (selectedDays.includes(day)) {
            setSelectedDays(
                selectedDays.filter((selected) => selected !== day)
            );
        } else {
            setSelectedDays([...selectedDays, day]);
        }
    };

    return (
        <div>
            <div className="pt-16p">
                Select the days to receive notifications.
            </div>
            <div>
                <label
                    style={{
                        color: "#525252",
                        fontSize: 16,
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                        wordWrap: "break-word",
                        paddingTop: 12,
                    }}
                >
                    Days
                </label>
                <div
                    className="frequently-used-views"
                    style={{ marginBottom: 0 }}
                >
                    <div>
                        <div className="btn-group manage-weekly">
                            <button
                                type="button"
                                className={
                                    selectedDays.includes("Sun")
                                        ? "btn day-selected"
                                        : "btn"
                                }
                                onClick={() => handleDayClick("Sun")}
                            >
                                Sun
                            </button>
                            <button
                                type="button"
                                className={
                                    selectedDays.includes("Mon")
                                        ? "btn day-selected"
                                        : "btn"
                                }
                                onClick={() => handleDayClick("Mon")}
                            >
                                Mon
                            </button>
                            <button
                                className={
                                    selectedDays.includes("Tue")
                                        ? "btn day-selected"
                                        : "btn"
                                }
                                type="button"
                                onClick={() => handleDayClick("Tue")}
                            >
                                Tue
                            </button>
                            <button
                                className={
                                    selectedDays.includes("Wed")
                                        ? "btn day-selected"
                                        : "btn"
                                }
                                type="button"
                                onClick={() => handleDayClick("Wed")}
                            >
                                Wed
                            </button>
                            <button
                                className={
                                    selectedDays.includes("Thu")
                                        ? "btn day-selected"
                                        : "btn"
                                }
                                type="button"
                                onClick={() => handleDayClick("Thu")}
                            >
                                Thu
                            </button>
                            <button
                                className={
                                    selectedDays.includes("Fri")
                                        ? "btn day-selected"
                                        : "btn"
                                }
                                type="button"
                                onClick={() => handleDayClick("Fri")}
                            >
                                Fri
                            </button>
                            <button
                                className={
                                    selectedDays.includes("Sat")
                                        ? "btn day-selected"
                                        : "btn"
                                }
                                type="button"
                                onClick={() => handleDayClick("Sat")}
                            >
                                Sat
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
