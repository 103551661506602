import { createSlice } from "@reduxjs/toolkit";
import cookieService from "./../services/CookieService";
import config from "../config";
import { getLogoutRedirectUrl } from "../utils/helpers";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null,
        returnUrl: null,
        deepLinkUrl: null,
        dlselectedIsfOrganizationId: [],
        dlViewId: null,
        dlDataView: null,
    },
    reducers: {
        login(state, action) {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        setReturnUrl(state, action) {
            state.returnUrl = action.payload.returnUrl;
        },
        setDlUrlValues(state, action) {
            const { key, value } = action.payload;
            state[key] = value;
        },
        clearDlUrlValues(state) {
            state.dlselectedIsfOrganizationId = "";
            state.dlViewId = null;
            state.dlDataView = null;
        },
        logout(state, _) {
            const redirectUrl = getLogoutRedirectUrl(
                state.user,
                state.returnUrl
            );

            // state.user = null;
            state.token = null;
            state.returnUrl = null;

            const token = cookieService.get(config.REACT_APP_SSO_COOKIE_NAME);

            if (token) {
                cookieService.delete(config.REACT_APP_SSO_COOKIE_NAME);
            }

            window.location = redirectUrl;
        },
    },
});

export const { login, logout, setReturnUrl, setDlUrlValues, clearDlUrlValues } =
    authSlice.actions;

export default authSlice.reducer;
