import React from "react";
import mainLogo from "../../assets/images/unum-insights.svg";
import EmployeeClaimLeavePrint from "./EmployeeClaimLeavePrint";
import Footer from "../../components/Footer";

export const DataToPrint = React.forwardRef((props, ref) => {
    return (
        <>
            <div
                className="mt-4"
                style={{
                    display: "none",
                }}
            >
                <div ref={ref}>
                    {props.printing === true && (
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <img
                                                width={230}
                                                src={mainLogo}
                                                className="logo"
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <EmployeeClaimLeavePrint
                                                employeeClaimLeaveData={
                                                    props.data
                                                }
                                            />
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <Footer></Footer>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
});
