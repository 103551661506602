import React from "react";
import NoData from "../../components/alerts/NoData";
import Table from "../../components/table/Table";

function LeaveProtections({ row, print, protections = [] }) {
    const { original: data } = row;

    const columns = React.useMemo(
        () => [
            {
                Header: "Leave Protection",
                columns: [
                    {
                        Header: "Protection Description",
                        accessor: (r) => r.ProtectionDescription,
                        disableSortBy: true,
                    },
                    {
                        Header: "Protection Status",
                        accessor: (r) => r.Status,
                        disableSortBy: true,
                    },
                    {
                        Header: "Protection Status Reason",
                        accessor: (r) => r.StatusReason,
                        disableSortBy: true,
                    },
                ],
            },
        ],
        []
    );

    const protectionsData = protections.filter(
        (p) =>
            p.Leavedata_LeaveNo === data.Leavedata_LeaveNo &&
            p.LeavePeriodSourceID === data.LeavePeriodSourceID
    );

    let cntrlHight = "auto";

    if (!print && protectionsData.length > 10) {
        cntrlHight = 25;
    }

    return (
        <div
            className={
                "w-100 pt-10p  " + (row.index % 2 === 0 && "table-bg-secondary")
            }
            style={{
                backgroundColor: row.index % 2 !== 0 && "#E6F2F8",
            }}
        >
            <div className="row">
                {protectionsData.length === 0 ? (
                    <div className="col-12 pt-10p  ">
                        <NoData />
                    </div>
                ) : (
                    <>
                        <div className=" ">
                            <Table
                                data={protectionsData}
                                columns={columns}
                                striped={false}
                                bordered={false}
                                pagination={false}
                                height={cntrlHight}
                                className={"pb-10p leave-protection-table"}
                                responsiveConfig={{
                                    type: "ROW",
                                    striped: false,
                                    screen: "md",
                                    bordered: true,
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default LeaveProtections;
