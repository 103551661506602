import React from "react";
import { useSelector } from "react-redux";
import CookieService from "./../services/CookieService";
import config from "../config";

const LoadInternalUserCookie = () => {
    const { user } = useSelector((state) => state.auth);

    return (
        <>
            {user.isUserInternal &&
                !CookieService.get(config.REACT_APP_SSO_COOKIE_NAME) &&
                config.REACT_APP_INT_ACCESS_URL && (
                    <iframe src={config.REACT_APP_INT_ACCESS_URL} hidden />
                )}
        </>
    );
};

export default LoadInternalUserCookie;
