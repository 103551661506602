import React from "react";
import Table from "../../components/table/Table";
import { Accordion } from "react-bootstrap";
import ClaimPaymentTransactions from "./ClaimPaymentTransactions";
import AccordionToggle from "../../components/AccordionToggle";
import NoData from "../../components/alerts/NoData";
import { formatDate, numberFormat } from "../../utils/helpers";
import GTM from "../../GTM";
import Divider from "../../components/ui/Divider";

function ClaimPayments({ claimPayments, print, noRecords, claimNo }) {
    const gtm = new GTM(null);
    const handleClick = (row) => {
        gtm.Event(
            "claim & leave",
            "accordion",
            `${row.isExpanded === true ? "collapse" : "expand"}`,
            "payment period",
            "claim payments",
            "leave record",
            "field_interaction",
            ""
        );
    };
    const columns = React.useMemo(
        () => [
            {
                Header: "Claim Payments",
                columns: [
                    {
                        Header: "Payment Date",
                        accessor: (row) => formatDate(row.PaymentDate),
                        disableSortBy: true,
                    },
                    {
                        Header: "Payment Type",
                        accessor: (row) => row.PaymentType,
                        disableSortBy: true,
                    },
                    {
                        Header: "Payment Amount",
                        accessor: (row) => numberFormat(row.PaymentAmount),
                        disableSortBy: true,
                    },
                    {
                        Header: "Payment Period",
                        accessor: (row) => row.PaymentPeriod,
                        disableSortBy: true,
                        Cell: ({ row }) => {
                            return (
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    onClick={() => {
                                        handleClick(row);
                                    }}
                                >
                                    <span className="flex-1 mr-10p">
                                        {row.original.PaymentPeriod}
                                    </span>

                                    <i
                                        className={
                                            "fa-solid accordion-icon " +
                                            (row.isExpanded === true
                                                ? "fa-circle-minus"
                                                : "fa-circle-plus")
                                        }
                                    ></i>
                                </div>
                            );
                        },
                    },
                ],
            },
        ],
        []
    );

    const renderRowSubComponent = React.useCallback(
        ({ row }) => <ClaimPaymentTransactions row={row} print={print} />,
        []
    );
    const tableBodyHeight =  (!print && claimPayments.Payments.length > 10) && 329
    
    

    return (
        <Accordion defaultActiveKey={print === true ? "0" : ""} tabIndex={0}>
            <AccordionToggle eventKey={claimNo} attribute1="claim payments">
                Claim Payments
            </AccordionToggle>

            <Accordion.Collapse
                eventKey={claimNo}
                className={print ? "show" : ""}
                tabIndex="0"
            >
                {noRecords && claimPayments.Payments.length === 0 ? (
                    <div className="">
                        <NoData />
                        <Divider className=" mt-0" mx="" />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        className="print-data"
                        data={claimPayments.Payments}
                        caption={"Claim Payments"}
                        renderRowSubComponent={renderRowSubComponent}
                        defaultPageSize={10}
                        paginationClassName={"pb-20p"}
                        print={print}
                        pagination={
                            !print && claimPayments.Payments.length > 10
                        }
                        height={
                            tableBodyHeight
                        }
                    ></Table>
                )}
            </Accordion.Collapse>
        </Accordion>
    );
}

export default ClaimPayments;
