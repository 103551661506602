import React from "react";
import close from "../assets/icons/close-icon.svg";

const InfomationBanner = ({ bannerType, bannerImage, bannerText, onClose }) => {
    return (
        <div className={`${bannerType}Banner`}>
            <div className="d-flex align-items-center justify-content-between container py-16p px-20p">
                <div className="d-flex">
                    <img
                        className="bannericon"
                        src={bannerImage}
                        alt="bannerImage"
                    />
                    <div className="infobanner-text">{bannerText}</div>
                </div>
                <img
                    className="closeicon"
                    src={close}
                    onClick={onClose}
                    alt="closeIcon"
                />
            </div>
        </div>
    );
};

export default InfomationBanner;
