import TagManager from "react-gtm-module";
import config from "./config";

export class GTM {
    initialize() {
        const env = config.REACT_APP_ENV;

        const tagManagerArgs = {
            gtmId: "",
            auth: "",
            preview: "",
        };
        if (env === "prod") {
            //production
            tagManagerArgs.gtmId = config.REACT_GTM_ID;
            tagManagerArgs.auth = config.REACT_GTM_AUTH;
            tagManagerArgs.preview = config.REACT_GTM_PREVIEW;
        }
        if (env === "staging") {
            //ACPT
            tagManagerArgs.gtmId = config.REACT_GTM_ID;
            tagManagerArgs.auth = config.REACT_GTM_AUTH;
            tagManagerArgs.preview = config.REACT_GTM_PREVIEW;
        }
        if (env === "itest") {
            //dev
            tagManagerArgs.gtmId = config.REACT_GTM_ID;
            tagManagerArgs.auth = config.REACT_GTM_AUTH;
            tagManagerArgs.preview = config.REACT_GTM_PREVIEW;
        }

        TagManager.initialize(tagManagerArgs);
    }

    PageView(
        title,
        userName,
        userId,
        userRole,
        path,
        page_referrer,
        page_referrer_title,
        employeeId
    ) {
        TagManager.dataLayer({
            dataLayer: {
                event: "custom_pageview",
                env: config.REACT_APP_ENV,
                page_title: title,
                app_source: "web",
                journey_name: "admin",
                digital_asset: "unum insights",
                page_location: path,
                page_referrer: page_referrer,
                page_referrer_title: page_referrer_title,
                employer: userName,
                user_id: userId,
                user_type: userRole,
                employer_id: employeeId,
            },
        });
    }

    Event(
        page,
        category,
        action,
        label,
        attribute1,
        attribute2,
        attribute3,
        attribute4
    ) {
        TagManager.dataLayer({
            dataLayer: {
                event: "custom_event",
                page: page,
                category: category,
                action: action,
                label: label,
                attribute1: attribute1,
                attribute2: attribute2,
                attribute3: attribute3,
                attribute4: attribute4,
            },
        });
    }
}

export default GTM;
