class Organization {
    _format = (data) => {
        return {
            ORGANIZATIONGROUPID: data.organizationGroupId,
            ORGANIZATIONID: data.organizationId,
            ORGANIZATIONIDISF: data.organizationIdISF,
            ORGNIZATIONNAME: data.orgnizationName ?? "",
            ORGANIZATIONGROUPNAME: data.orgnizationGroupName ?? "",
            POLICYID: data.policyId,
        };
    };

    format = (data) => {
        const list = [];

        data.forEach((el) => {
            list.push(this._format(el));
        });

        return list;
    };
}

export default new Organization();
