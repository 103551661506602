import AppRoutes from "./router";
import "react-perfect-scrollbar/dist/css/styles.css";
import ScrollToTopIcon from "./components/ui/ScrollToTopIcon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "./config";
import { configure } from "./slices/appSlice";
import cookieService from "./services/CookieService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setDlUrlValues, setReturnUrl } from "./slices/authSlice";
import SessionTimeout from "./components/SessionTimeout";
import useOrganizationsObserver from "./components/observables/useOrganizationsObserver";
import useSelectedOrganizationsObserver from "./components/observables/useSelectedOrganizationsObserver";
import FirstLoginMessage from "./components/FirstLoginMessage";
import ReleaseMessage from "./components/ReleaseMessage";

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useOrganizationsObserver();
    useSelectedOrganizationsObserver();

    const { token: stateToken, user: loggedInUser } = useSelector(
        (state) => state.auth
    );
    const { loginMessage, releaseContent, releaseHeader, releaseLink } =
        useSelector((state) => state.preferenceSlice);

    let message = loginMessage;

    useEffect(() => {
        dispatch(configure(config));
        const rtnUrl = searchParams.get("rtnurl");
        if (rtnUrl !== null) {
            dispatch(
                setReturnUrl({
                    returnUrl: rtnUrl,
                })
            );
        }

        const requestedPage = searchParams.get("RelayState");

        const dlUrl = searchParams.get("dlUrl");
        if (dlUrl !== null) {
            dispatch(setDlUrlValues({ key: "deepLinkUrl", value: dlUrl }));
        }

        if (requestedPage) {
            const decodedPage = decodeURIComponent(requestedPage);
            const params = new URLSearchParams(decodedPage);

            const dlselectedIsfOrganizationId = params.get(
                "selectedisforganizationids"
            );
            const dlViewId = params.get("viewid");
            const dlDataView = params.get("dataviewtype");

            if (dlselectedIsfOrganizationId) {
                dispatch(
                    setDlUrlValues({
                        key: "dlselectedIsfOrganizationId",
                        value: dlselectedIsfOrganizationId.toLocaleLowerCase(),
                    })
                );
                dispatch(setDlUrlValues({ key: "dlViewId", value: dlViewId }));
                dispatch(
                    setDlUrlValues({
                        key: "dlDataView",
                        value:
                            dlDataView.charAt(0).toUpperCase() +
                            dlDataView.slice(1),
                    })
                );
            }
        }

        const cookieToken = cookieService.get(config.REACT_APP_SSO_COOKIE_NAME);

        // Relogin and update user state with token if token in state and cookie are different
        if (loggedInUser !== null && cookieToken !== stateToken) {
            navigate("/login");
        }
    }, [dispatch, navigate, searchParams, loggedInUser, stateToken]);

    return (
        <div className="App">
            {loggedInUser && loginMessage && loginMessage?.length > 0 && (
                <FirstLoginMessage message={message} />
            )}
            {loggedInUser &&
                releaseContent !== "" &&
                releaseContent !== undefined &&
                releaseContent !== null &&
                !loginMessage &&
                !loginMessage?.length > 0 && (
                    <ReleaseMessage
                        releaseHeader={releaseHeader}
                        releaseContent={releaseContent}
                        releaseLink={releaseLink}
                    />
                )}

            <SessionTimeout />
            <AppRoutes />
            <ScrollToTopIcon />
        </div>
    );
}

export default App;
