import React from "react";
import { Accordion } from "react-bootstrap";
import Table from "../../components/table/Table";
import NoData from "../../components/alerts/NoData";
import AccordionToggle from "../../components/AccordionToggle";
import moment from "moment";
import { CSVLink } from "react-csv";
import Divider from "../../components/ui/Divider";
import SectionSubheading from "../../components/SectionSubheading";

function IntermittentAbsence({ data, print }) {
    const sortedData = data ? [...data] : [];
    const dateTimeFormat = "MM/DD/YYYY, hh:mm A";

    sortedData.sort((a, b) => {
        return a["BeginDateTime"] > b["BeginDateTime"] ? -1 : 1;
    });

    const columns = React.useMemo(
        () => [
            {
                Header: "Intermittent Absence",
                columns: [
                    {
                        Header: "Begin Date/Time",
                        accessor: (row) =>
                            row.BeginDateTime
                                ? moment(row.BeginDateTime).format(
                                      dateTimeFormat
                                  )
                                : "",
                        disableSortBy: true,
                        width: 170,
                    },
                    {
                        Header: "End Date/Time",
                        accessor: (row) =>
                            row.EndDateTime
                                ? moment(row.EndDateTime).format(dateTimeFormat)
                                : "",
                        disableSortBy: true,
                        width: 170,
                    },
                    {
                        Header: "Time Absent",
                        accessor: (row) => {
                            let timeAbsent = "";

                            if (row.AbsenceTimeHours !== null)
                                timeAbsent += `${row.AbsenceTimeHours} hr, `;
                            if (row.AbsenceTimeMinutes !== null)
                                timeAbsent += `${row.AbsenceTimeMinutes} min`;

                            return timeAbsent;
                        },
                        disableSortBy: true,
                    },
                    {
                        Header: "Reported Date",
                        accessor: (row) =>
                            row.ReportedDate
                                ? moment(row.ReportedDate).format("MM/DD/YYYY")
                                : "",
                        disableSortBy: true,
                    },
                    {
                        Header: "Leave Period",
                        accessor: (row) => row.LeavePeriod,
                        disableSortBy: true,
                    },
                    {
                        Header: "Period Status",
                        accessor: (row) => row.PeriodStatus,
                        disableSortBy: true,
                        width: 200,
                    },
                ],
            },
        ],
        []
    );

    const headers = [
        { label: "Begin Date/Time", key: "BeginDateTime" },
        { label: "End Date/Time", key: "EndDateTime" },
        { label: "Time Absent", key: "timeAbsent" },
        { label: "Reported Date", key: "ReportedDate" },
        { label: "Leave Period", key: "LeavePeriod" },
        { label: "Period Status", key: "PeriodStatus" },
    ];

    const csvData = sortedData.map((row) => ({
        ...row,
        BeginDateTime: row.BeginDateTime
            ? moment(row.BeginDateTime).format(dateTimeFormat)
            : "",
        EndDateTime: row.EndDateTime
            ? moment(row.EndDateTime).format(dateTimeFormat)
            : "",
        ReportedDate: row.ReportedDate
            ? moment(row.ReportedDate).format("MM/DD/YYYY")
            : "",
        timeAbsent: FormatAbsenceHours(row),
    }));

    function FormatAbsenceHours(row) {
        if (row.AbsenceTimeHours === null)
            return row.AbsenceTimeMinutes
                ? `${row.AbsenceTimeMinutes} min`
                : "";

        return `${row.AbsenceTimeHours} hr, ${row.AbsenceTimeMinutes} min`;
    }

    const csvReport = {
        data: csvData,
        headers: headers,
        filename: "IntermittentAbsence.csv",
    };

    const allValuesAreNullExceptLeavedata_LeaveNo = sortedData.map(
        (dataObject) =>
            Object.keys(dataObject).every(
                (key) => key === "Leavedata_LeaveNo" || dataObject[key] === null
            )
    );

    const isAllDataNull = allValuesAreNullExceptLeavedata_LeaveNo.every(
        (value) => value === true
    );

    return (
        <div>
            <Accordion defaultActiveKey="0" tabIndex={0}>
                <div className="d-flex justify-content-between align-items-start mb-20p ">
                    <AccordionToggle
                        eventKey="0"
                        attribute1="leave summary"
                        className="mb-0p"
                    >
                        <SectionSubheading
                            subheading="Intermittent Absence"
                            className="d-contents"
                        />
                    </AccordionToggle>
                    {!isAllDataNull && (
                        <span className="px-20p">
                            <CSVLink {...csvReport} target="_blank">
                                <i className="fa-solid fa-download"></i>
                                <span className="export-to-excel">
                                    Export CSV
                                </span>
                            </CSVLink>
                        </span>
                    )}
                </div>
                <Accordion.Collapse eventKey="0">
                    {isAllDataNull ? (
                        <div className="">
                            <NoData className="pb-0" />
                            <Divider className="mb-0p mt-0" mx="" />
                        </div>
                    ) : (
                        <Table
                            columns={columns}
                            data={sortedData}
                            print={print}
                            defaultPageSize={10}
                            className="print-data"
                            pagination={!print && sortedData.length > 10}
                            attribute1="intermittent absence"
                            pageName="claim & leave"
                            responsiveConfig={{
                                type: "row",
                                striped: false,
                                screen: "md",
                            }}
                            height={!print && data.length > 10 ? 329 : "auto"}
                        />
                    )}
                </Accordion.Collapse>
            </Accordion>
        </div>
    );
}

export default IntermittentAbsence;
