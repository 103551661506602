class EmployeeRepository {
    getClaimPaymentAmountStatus = (payments) => {
        const list = [];

        payments.forEach((el) => {
            if (el.ClaimComponentId !== null) list.push(el);
        });

        return list;
    };
}

export default new EmployeeRepository();
