import React from "react";

function Button({ onClick, text, icon, outline, type, ...rest }) {
    return (
        <button
            type={type ? type : "button"}
            className={outline === true ? "btn-outline" : "btn-primary"}
            onClick={onClick}
            {...rest}
        >
            {rest.pageType === "reportSaving" ? (
                <>
                    {text}
                    {icon && <i className={"fa-solid pl-10p " + icon}></i>}
                </>
            ) : (
                <>
                    {icon && <i className={"fa-solid pr-10p " + icon}></i>}

                    {text}
                </>
            )}
        </button>
    );
}

export default Button;
