import React, { useEffect, useState } from "react";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import Modal from "react-bootstrap/Modal";
import GTM from "../../GTM";
import { getButtonDisabledStatus } from "../../utils/helpers";

export default function ReportEditPage({
    onShow,
    onSave,
    onSaveAs,
    onDontSave,
    notification,
    reportDetails,
}) {
    const [viewNameChange, setViewNameChange] = useState(false);
    const [descriptionChange, setDescriptionChange] = useState(false);
    const [viewNameValue, setViewNameValue] = useState(reportDetails.Name);
    const [descriptionValue, setDescriptionValue] = useState(
        reportDetails.Description
    );
    const [viewId, setViewId] = useState(reportDetails.ViewId);
    const gtm = new GTM(null);

    const handleGtmTags = (label) => {
        gtm.Event(
            "custom_event",
            "button",
            "click",
            label,
            "report detail",
            reportDetails.DataViewType.toLowerCase(),
            "field_interaction",
            ""
        );
    };

    useEffect(() => {
        setViewNameValue(reportDetails.Name);
        setDescriptionValue(reportDetails.Description);
        setViewId(reportDetails.ViewId);
    }, [reportDetails]);

    const sendUpdateEvent = () => {
        onSave(viewNameValue, descriptionValue, viewId);
        notification("UPDATE");
        handleGtmTags("update");
    };
    const sendDontSaveEvent = () => {
        setViewNameChange(false);
        setDescriptionChange(false);
        onDontSave();
    };
    const sendSaveAsNewEvent = () => {
        onSaveAs(viewNameValue, descriptionValue, viewId);
        notification("SAVE AS");
        handleGtmTags("save as new");
    };

    const handleViewNameChange = ({ target: input }) => {
        if (input.value.length) {
            setViewNameChange(true);
        } else {
            setViewNameChange(false);
        }
        setViewNameValue(input.value);
    };

    const handleDescriptionValueChange = ({ target: input }) => {
        if (input.value.length) {
            setDescriptionChange(true);
        } else {
            setDescriptionChange(false);
        }
        setDescriptionValue(input.value);
    };
    return (
        <>
            <Modal
                size="sm"
                show={onShow}
                backdrop="static"
                keyboard={false}
                centered
                className="preference"
                dialogClassName="custom-modal-size"
            >
                <Modal.Header>
                    <SectionHeading heading="Update current report?" />
                    <i
                        className="fa-solid fa-circle-xmark cursor-pointer"
                        onClick={sendDontSaveEvent}
                    ></i>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>Report name</label>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Add a name"}
                            value={viewNameValue}
                            onChange={handleViewNameChange}
                        />
                    </div>
                    <div>
                        <div>
                            <label
                                style={{
                                    paddingTop: 12,
                                }}
                            >
                                Description (Optional)
                            </label>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Add a description"
                            value={descriptionValue}
                            onChange={handleDescriptionValueChange}
                        />
                    </div>
                    <div className="preference-button pt-16p">
                        <div className="d-contents">
                            <Button
                                data-testid="Save as new"
                                text="Save as new"
                                outline={true}
                                onClick={sendSaveAsNewEvent}
                                disabled={getButtonDisabledStatus(true,viewNameValue,true,descriptionValue)}
                            />
                            <Button text="Update" onClick={sendUpdateEvent} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
