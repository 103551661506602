import React from "react";
import ErrorMessage from "./ErrorMessage";

function Input({ onChange, name, value, error, success, message, ...rest }) {
    let conditionalJsx = null;
    if (error)
        conditionalJsx = (
            <i className="fa-solid fa-circle-exclamation color-danger input-icon"></i>
        );
    if (success)
        conditionalJsx = (
            <i className="fa-solid fa-circle-check color-success input-icon"></i>
        );

    return (
        <div>
            <div className="position-relative">
                <input
                    className={
                        "form-control " +
                        (error ? "error " : " ") +
                        (success ? "success " : " ")
                    }
                    type="text"
                    value={value}
                    name={name}
                    onChange={onChange}
                    {...rest}
                />
                {conditionalJsx}
            </div>

            {error && message && <ErrorMessage message={message} />}
        </div>
    );
}

export default Input;
