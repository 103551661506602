import React from "react";

function SectionHeading({ heading, mb = "mb-16p mb-7p md-fs", ...rest }) {
    return (
        <div {...rest}>
            <h2 className={"section-heading " + mb}>{heading}</h2>
        </div>
    );
}

export default SectionHeading;
