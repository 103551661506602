import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "./../config";

const sessionSlice = createApi({
    reducerPath: "sessionSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: `${config.REACT_APP_SECURITY_API_URL}${config.REACT_APP_ENV_NAME}`,
        credentials: "include",
        prepareHeaders: (headers) => {
            headers.set("Accept", "application/json");
            headers.set("Content-Type", "application/json");
            headers.set("Accept-API-Version", "protocol=1.0,resource=2.0");

            const apiKey = process.env.REACT_APP_API_KEY;
            if (apiKey) headers.set("apikey", apiKey);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSessionInfo: builder.query({
            query: () => ({
                url: `?_action=getSessionInfo&realm=external`,
                method: "POST",
            }),
        }),
    }),
});

export const { useGetSessionInfoQuery, useLazyGetSessionInfoQuery } = sessionSlice;

export default sessionSlice;
