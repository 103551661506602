import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.min.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "./styles/style.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import GTM from "./GTM";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import config from "./config";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const gtm = new GTM(null);
window.dataLayer = window.dataLayer || [];
gtm.initialize();

const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store);

const AppWithLaunchDarkly = withLDProvider({
    clientSideID: config.REACT_CLIENT_SIDE_ID,
})(App);
root.render(
    <DndProvider backend={HTML5Backend}>
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <Router>
                        <AppWithLaunchDarkly />
                    </Router>
                </PersistGate>
            </Provider>
        </React.StrictMode>{" "}
    </DndProvider>
);
