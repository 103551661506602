import { React, useState } from "react";

function Info({ className, message }) {
    const [dismissed, setDismissed] = useState(false);

    return (
        <div
            style={{
                padding: "10px 15px",
                background: "#F7F9E6",
                border: "1px solid #46B6DD",
                borderRadius: 8,
                display: dismissed ? "none" : "flex",
            }}
            tabIndex={0}
            className={
                "justify-content-between align-items-center " + className
            }
        >
            <span>{message}</span>

            <span
                className="cursor-pointer ml-10p"
                onClick={() => {
                    setDismissed(true);
                }}
            >
                <i
                    className="fa-solid fa-circle-xmark"
                    style={{
                        color: "#46B6DD",
                        fontSize: 16,
                    }}
                ></i>
            </span>
        </div>
    );
}

export default Info;
