import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SectionHeading from "../../components/SectionHeading";
import Pagination from "./../../components/table/Pagination";
import { noDataPlaceholder, ssnAccess } from "./../../constants";
import Info from "./../../components/alerts/Info";
import GTM from "../../GTM";

function EmployeesTable(props) {
    const [users, setUsers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [
        employeesWithMultipleRelatedOrgs,
        setEmployeesWithMultipleRelatedOrgs,
    ] = useState([]);

    const processEmployees = () => {
        const emps = {};
        const sortedEmployees = [];
        const employeesWithMultipleOrgs = [];

        employees.forEach((user) => {
            if (!emps[user.MasterPartyId]) emps[user.MasterPartyId] = [];

            if (emps[user.MasterPartyId].length >= 1) {
                handleMultipleOrgs(user, emps, employeesWithMultipleOrgs);
            } else {
                emps[user.MasterPartyId].push(user);
                sortedEmployees.push({
                    EEID: user.MasterPartyId,
                    Name: `${user.LastName} ${user.FirstName}`,
                });
            }
        });

        filterEmptyRecords(emps);

        const u = sortedEmployees.map((sortedEmployee) => {
            const empData = emps[sortedEmployee.EEID];
            return Array.isArray(empData) ? empData : [empData];
        });

        return { u, employeesWithMultipleOrgs };
    };

    const handleMultipleOrgs = (user, emps, employeesWithMultipleOrgs) => {
        if (user.OpenLeave != null || user.OpenClaim != null) {
            if (
                user.OrganizationId !==
                emps[user.MasterPartyId][0].OrganizationId
            ) {
                if (!employeesWithMultipleOrgs.includes(user.MasterPartyId)) {
                    employeesWithMultipleOrgs.push(user.MasterPartyId);
                }
            }

            emps[user.MasterPartyId].push(user);
        }
    };

    const filterEmptyRecords = (emps) => {
        for (const eid in emps) {
            if (emps[eid].length > 1) {
                const filteredRows = emps[eid].filter(
                    (e) => e.OpenLeave !== null || e.OpenClaim !== null
                );

                if (filteredRows.length > 0) {
                    emps[eid] = filteredRows;
                } else {
                    emps[eid] = emps[eid][0];
                }
            }
        }
    };

    // Usage
    useEffect(() => {
        const { u, employeesWithMultipleOrgs } = processEmployees();

        setUsers(u);
        setEmployeesWithMultipleRelatedOrgs(employeesWithMultipleOrgs);
    }, [employees]);

    useEffect(() => {
        setEmployees(props.filteredEmployees);
    }, [props.filteredEmployees]);

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = props.rowRangeChanged ? props.rowRangeChanged : 5;
    const pagesVisited = pageNumber * usersPerPage;

    const getPageCount = () => {
        return Math.ceil(users.length / usersPerPage);
    };

    const changePage = (selected) => {
        setPageNumber(selected);
    };

    function updateSsnState(ssn, ssnState) {
        let newSSNState = "TRUNCATED";
        if (ssnState === "NORMAL") newSSNState = "HIDDEN";
        else if (ssnState === "TRUNCATED") newSSNState = "NORMAL";

        if (props.ssnDisplay === ssnAccess.Partial && newSSNState === "NORMAL")
            newSSNState = "HIDDEN";

        const newUsers = [];

        employees.forEach((employee) => {
            if (employee.SSN === ssn)
                newUsers.push({ ...employee, SSNState: newSSNState });
            else newUsers.push({ ...employee });
        });

        setEmployees(newUsers);
    }
    const handelClick = (attribute3) => {
        gtm.Event(
            "searching an employee from an organization",
            "button",
            "click",
            "ssn",
            "search return",
            "",
            attribute3,
            ""
        );
    };

    function customizeSSN(fullSSN, ssnState) {
        if (props.ssnDisplay === ssnAccess["No Access"]) {
            return <></>;
        }

        if (ssnState === "TRUNCATED") {
            const lastSSNDigits = fullSSN.split("-");

            return (
                <span
                    onClick={() => {
                        handelClick("show_ssn");
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            handelClick("show_ssn");
                        }
                    }}
                    tabIndex={0}
                >
                    <span tabIndex={0}>
                        {lastSSNDigits[lastSSNDigits.length - 1]}
                    </span>

                    <span
                        data-testid="truncatedTestVal"
                        onClick={() => updateSsnState(fullSSN, ssnState)}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                updateSsnState(fullSSN, ssnState);
                            }
                        }}
                        tabIndex={0}
                    >
                        <i
                            className="fa fa-eye part-eye cursor-pointer"
                            aria-hidden="true"
                        ></i>
                    </span>
                </span>
            );
        } else if (ssnState === "NORMAL") {
            return (
                <span>
                    <span tabIndex={0}>{fullSSN}</span>

                    <span
                        data-testid="normalTestValue"
                        onClick={() => updateSsnState(fullSSN, ssnState)}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                updateSsnState(fullSSN, ssnState);
                            }
                        }}
                        tabIndex="0"
                    >
                        <i
                            className="fa fa-eye-slash full-eye cursor-pointer"
                            aria-hidden="true"
                        ></i>
                    </span>
                </span>
            );
        } else if (ssnState === "HIDDEN") {
            return (
                <span
                    onClick={() => {
                        handelClick("hide_ssn");
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            handelClick("show_ssn");
                        }
                    }}
                    tabIndex={0}
                >
                    <span tabIndex={0}>***-**-****</span>

                    <span
                        data-testid="hiddenTestValue"
                        onClick={() => updateSsnState(fullSSN, ssnState)}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                updateSsnState(fullSSN, ssnState);
                            }
                        }}
                        tabIndex="0"
                    >
                        <i
                            className="fa fa-eye mask-eye cursor-pointer"
                            aria-hidden="true"
                        ></i>
                    </span>
                </span>
            );
        }
    }

    function customizeRow(item, index) {
        return (
            <React.Fragment key={index}>
                {item.length !== 1 &&
                    multipleRows(
                        item,
                        index,
                        props,
                        customizeSSN,
                        employeesWithMultipleRelatedOrgs
                    )}

                {item.length == 1 &&
                    singleRow(index, props, item, customizeSSN)}
            </React.Fragment>
        );
    }

    return (
        <>
            {users.length >= 100 && (
                <Info
                    className="fs-sm mb-20p"
                    message={
                        "The first 100 records matching your search criteria are shown below. If you need to search further, input more characters in your search request."
                    }
                />
            )}

            <div className="section-card px-0">
                <SectionHeading heading="Search Return" className="px-20p" />

                <div className="fixed-header custom-scrollbar">
                    <table className="table-container">
                        <thead>
                            <tr>
                                {props.relatedOrganizationsCount > 0 && (
                                    <th scope="col" tabIndex={0}>
                                        Organization Name
                                    </th>
                                )}
                                <th scope="col" tabIndex={0}>
                                    Name
                                </th>

                                {props.ssnDisplay !==
                                    ssnAccess["No Access"] && (
                                    <th scope="col" tabIndex={0}>
                                        SSN
                                    </th>
                                )}

                                <th
                                    scope="col"
                                    className="text-center"
                                    tabIndex={0}
                                >
                                    Open Leave
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    tabIndex={0}
                                >
                                    Open Claim
                                </th>
                                <th scope="col" tabIndex={0}>
                                    <span className="pl-10p">Coverage</span>
                                </th>
                                <th scope="col" tabIndex={0}>
                                    Leave Reason
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {users
                                .slice(
                                    pagesVisited,
                                    parseInt(pagesVisited) +
                                        parseInt(usersPerPage)
                                )
                                .map((item, index) => {
                                    return customizeRow(item, index);
                                })}
                        </tbody>
                    </table>
                </div>

                {users.length > 10 && (
                    <div className="pt-20p">
                        <Pagination
                            pageCount={getPageCount()}
                            gotoPage={changePage}
                            setPageSize={props.handleItemsPerPageChange}
                            pageSize={props.rowRangeChanged ?? 10}
                            options={[5, 10, 50, 100]}
                            attribute1="search return"
                            pageName="searching an employee from an organization"
                            attribute4=""
                            tabIndex={0}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

const conditionalJsxColumn = (dataObject, option) => {
    let returnJsx = noDataPlaceholder;

    if (dataObject) {
        switch (option) {
            case "Leave Reason":
                if (
                    dataObject.OpenLeave !== null &&
                    dataObject.LeaveReason !== null
                )
                    return (
                        <td className="border-bottom-0">
                            <p>{dataObject.LeaveReason} </p>
                        </td>
                    );
                else
                    return (
                        <td className="border-bottom-0">{noDataPlaceholder}</td>
                    );
            case "Leave Reason Value":
                if (dataObject.OpenLeave !== null)
                    return dataObject.LeaveReason;
                break;

            case "Coverage Value":
                if (
                    dataObject.OpenClaim !== null ||
                    dataObject.OpenLeave !== null
                )
                    return dataObject.Coverage;
                break;
            default:
                break;
        }
    }
    return returnJsx;
};

export default React.memo(EmployeesTable);
const gtm = new GTM(null);

function multipleRows(
    item,
    index,
    props,
    customizeSSN,
    employeesWithMultipleRelatedOrgs
) {
    const handleLink = (label, attribute3) => {
        gtm.Event(
            "searching an employee from an organization",
            "link",
            "click",
            label,
            "search return",
            "",
            attribute3,
            ""
        );
    };
    const renderOrganizationName = (user) => {
        if (props.relatedOrganizationsCount > 0) {
            const organizationName = employeesWithMultipleRelatedOrgs.includes(
                user.MasterPartyId
            )
                ? props.selectedOrganization?.ORGANIZATIONGROUPNAME
                : user.Organization;

            return (
                <td
                    rowSpan={item.length}
                    data-header="Organization Name"
                    tabIndex={0}
                >
                    {organizationName}
                </td>
            );
        }
        return null;
    };

    const renderNameLinks = (user) => {
        const linkProps = {
            to: `/organization/${user.OrganizationId}/employees/${user.MasterPartyId}?ids=${props.relatedIds}`,
            state: {
                ssnState: user.SSNState,
            },
            onClick: () => {
                handleLink("name", "select_employee");
            },
        };

        return (
            <>
                <Link {...linkProps}>{`${user.LastName},`}</Link>{" "}
                <Link {...linkProps}>{`${user.FirstName}`}</Link>
            </>
        );
    };

    const renderOpenLeaveLink = (user, handleClick) => {
        if (user.OpenLeave) {
            return (
                <Link
                    to={`/organization/${user.OrganizationId}/employees/${user.MasterPartyId}?leave=${user.OpenLeave}&ids=${props.relatedIds}`}
                    state={{ ssnState: user.SSNState }}
                    onClick={() => handleLink("open leave", "select_leave")}
                >
                    {user.OpenLeave}
                </Link>
            );
        } else {
            return <>{noDataPlaceholder}</>;
        }
    };

    const renderOpenClaimLink = (user, handleClick) => {
        if (user.OpenClaim) {
            return (
                <Link
                    to={`/organization/${user.OrganizationId}/employees/${user.MasterPartyId}?claim=${user.OpenClaim}&ids=${props.relatedIds}`}
                    state={{ ssnState: user.SSNState }}
                    onClick={() => handleLink("open claim", "select_claim")}
                >
                    {user.OpenClaim}
                </Link>
            );
        } else {
            return <>{noDataPlaceholder}</>;
        }
    };

    const renderLeaveReason = (user, i) => {
        const trimmedLeaveReason = user.LeaveReason?.trim();

        if (!trimmedLeaveReason || user.LeaveReason == null) {
            return (
                <td
                    className={`pt-0 ${
                        i !== item.length - 1 ? "border-bottom-0" : ""
                    }`}
                    tabIndex="0"
                >
                    {noDataPlaceholder}
                </td>
            );
        }
        return (
            <td
                data-header="Leave Reason"
                className={`pt-0 ${
                    i !== item.length - 1 ? "border-bottom-0" : ""
                }`}
                tabIndex="0"
            >
                <span>{user.LeaveReason}</span>
            </td>
        );
    };

    return (
        <>
            {item.map((user, i) => (
                <React.Fragment key={i}>
                    {i === 0 && (
                        <tr
                            key={index + i}
                            className={
                                index % 2 === 0
                                    ? "odd vertical-align-top"
                                    : "even vertical-align-top"
                            }
                        >
                            {renderOrganizationName(user)}
                            <td
                                rowSpan={item.length}
                                data-header="Name"
                                tabIndex={0}
                            >
                                {renderNameLinks(user)}
                            </td>
                            {props.ssnDisplay !== ssnAccess["No Access"] && (
                                <td
                                    rowSpan={item.length}
                                    data-header="SSN"
                                    tabIndex={0}
                                >
                                    {user.SSN
                                        ? customizeSSN(user.SSN, user.SSNState)
                                        : "-"}
                                </td>
                            )}
                            <td
                                className="text-center border-left border-bottom-0 md-border-x-0 md-text-left md-d-none"
                                data-header="Open Leave"
                                tabIndex={0}
                            >
                                {renderOpenLeaveLink(user)}
                            </td>
                            <td
                                className="text-center border-left border-right border-bottom-0 md-border-x-0 md-text-left md-d-none"
                                data-header="Open Claim"
                                tabIndex={0}
                            >
                                {renderOpenClaimLink(user)}
                            </td>
                            <td
                                data-header="Coverage"
                                className="border-bottom-0"
                                tabIndex={0}
                            >
                                <span className="pl-10p md-pl-0p">
                                    {user.Coverage ?? noDataPlaceholder}
                                </span>
                            </td>
                            {conditionalJsxColumn(user, "Leave Reason")}
                        </tr>
                    )}

                    {i !== 0 && (
                        <tr
                            className={index % 2 === 0 ? "odd" : "even"}
                            key={index + i}
                        >
                            <td
                                data-header="Open leave"
                                className={`text-center border-left pt-0 ${
                                    i !== item.length - 1
                                        ? "border-bottom-0"
                                        : ""
                                }`}
                                tabIndex="0"
                            >
                                {renderOpenLeaveLink(user)}
                            </td>
                            <td
                                data-header="Open Claim"
                                className={`text-center border-left border-right pt-0 pr-10p ${
                                    i !== item.length - 1
                                        ? "border-bottom-0"
                                        : ""
                                }`}
                                tabIndex={0}
                            >
                                {renderOpenClaimLink(user)}
                            </td>
                            <td
                                data-header="Coverage"
                                className={`pt-0 ${
                                    i !== item.length - 1
                                        ? "border-bottom-0"
                                        : ""
                                }`}
                                tabIndex="0"
                            >
                                <span className="pl-10p">{user.Coverage}</span>
                            </td>
                            {renderLeaveReason(user, i)}
                        </tr>
                    )}
                </React.Fragment>
            ))}
        </>
    );
}

function singleRow(index, props, item, customizeSSN) {
    const handleLink = (label, attribute3) => {
        gtm.Event(
            "searching an employee from an organization",
            "link",
            "click",
            label,
            "search return",
            "",
            attribute3,
            ""
        );
    };
    return (
        <tr className={index % 2 == 0 ? "odd" : "even"} key={index}>
            {props.relatedOrganizationsCount > 0 && (
                <td data-header="Organization" tabIndex={0}>
                    {item[0].Organization}
                </td>
            )}

            <td data-header="Name" tabIndex={0} title="Name">
                <Link
                    to={`/organization/${item[0].OrganizationId}/employees/${item[0].MasterPartyId}?ids=${props.relatedIds}`}
                    state={{
                        ssnState: item[0].SSNState,
                    }}
                    onClick={() => {
                        handleLink("name", "select_employee");
                    }}
                >
                    {item[0].LastName + ","}
                </Link>{" "}
                <Link
                    to={`/organization/${item[0].OrganizationId}/employees/${item[0].MasterPartyId}?ids=${props.relatedIds}`}
                    state={{
                        ssnState: item[0].SSNState,
                    }}
                    onClick={() => {
                        handleLink("name", "select_employee");
                    }}
                >
                    {item[0].FirstName}
                </Link>
            </td>

            {props.ssnDisplay !== ssnAccess["No Access"] && (
                <td data-header="SSN" tabIndex={0} title="SSN">
                    {item[0].SSN
                        ? customizeSSN(item[0].SSN, item[0].SSNState)
                        : "-"}
                </td>
            )}

            <td
                data-header="Open Leave"
                className="text-center border-left md-border-x-0 md-text-left"
                tabIndex={0}
                title="Open Leave"
            >
                {item[0].OpenLeave ? (
                    <Link
                        to={`/organization/${item[0].OrganizationId}/employees/${item[0].MasterPartyId}?leave=${item[0].OpenLeave}&ids=${props.relatedIds}`}
                        state={{
                            ssnState: item[0].SSNState,
                        }}
                        onClick={() => {
                            handleLink("open leave", "select_leave");
                        }}
                    >
                        {item[0].OpenLeave}
                    </Link>
                ) : (
                    noDataPlaceholder
                )}
            </td>

            <td
                data-header="Open Claim"
                className="text-center border-left border-right md-border-x-0 md-text-left"
                tabIndex={0}
                title="Open Claim"
            >
                {item[0].OpenClaim ? (
                    <Link
                        to={`/organization/${item[0].OrganizationId}/employees/${item[0].MasterPartyId}?claim=${item[0].OpenClaim}&ids=${props.relatedIds}`}
                        state={{
                            ssnState: item[0].SSNState,
                        }}
                        onClick={() => {
                            handleLink("open claim", "select_claim");
                        }}
                    >
                        {item[0].OpenClaim}
                    </Link>
                ) : (
                    noDataPlaceholder
                )}
            </td>

            <td data-header="Coverage" tabIndex={0} title="Coverage">
                <span className="pl-10p md-pl-0p">
                    {conditionalJsxColumn(item[0], "Coverage Value")}
                </span>
            </td>

            <td data-header="Leave Reason" tabIndex={0} title="Leave Reason">
                <p className="mb-0">
                    {conditionalJsxColumn(item[0], "Leave Reason Value")}
                </p>
            </td>
        </tr>
    );
}
