import React from "react";

function SearchIcon() {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 7C13 8.4375 12.5312 9.78125 11.75 10.8438L15.6875 14.8125C16.0938 15.1875 16.0938 15.8438 15.6875 16.2188C15.3125 16.625 14.6562 16.625 14.2812 16.2188L10.3125 12.25C9.25 13.0625 7.90625 13.5 6.5 13.5C2.90625 13.5 0 10.5938 0 7C0 3.4375 2.90625 0.5 6.5 0.5C10.0625 0.5 13 3.4375 13 7ZM6.5 11.5C8.96875 11.5 11 9.5 11 7C11 4.53125 8.96875 2.5 6.5 2.5C4 2.5 2 4.53125 2 7C2 9.5 4 11.5 6.5 11.5Z"
                fill="#015294"
            />
        </svg>
    );
}

export default SearchIcon;
