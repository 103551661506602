import React from "react";
import EmployeeRecord from "../../views/claimleaveview/EmployeeRecord";
import ClaimLeaveHistoryTable from "../../views/claimleaveview/ClaimLeaveHistoryTable";
import LeaveRecord from "../../views/claimleaveview/LeaveRecord";
import ClaimRecord from "../../views/claimleaveview/ClaimRecord.js";
import { recordsType } from "../../constants/index";
import { useSelector } from "react-redux";
import FooterNote from "../../views/claimleaveview/FooterNote";

function EmployeeClaimLeavePrint(props) {
    const {
        employee,
        ssnDisplay,
        ssnState,
        employeeId,
        orgId,
        organizationsAccess,
        employeesLastFetched,
        claimHistoryData,
    } = props.employeeClaimLeaveData;

    const { selectedNo, type, selectedRecords } = useSelector(
        (state) => state.leaveClaimRecords
    );

    return (
        <div className="employeedet print-container">
            {employee && (
                <div className="mb-24p">
                    <EmployeeRecord
                        employee={employee.EmployeeDetails}
                        isLoading={false}
                        ssnDisplay={ssnDisplay}
                        ssnState={ssnState}
                        sticky={false}
                        print={true}
                    />
                </div>
            )}

            {type === recordsType.LEAVE && selectedNo !== null && (
                <LeaveRecord
                    leaveNo={selectedNo}
                    employeeId={employeeId}
                    organizationId={orgId}
                    organizationAccess={organizationsAccess[orgId]}
                    print={true}
                />
            )}
            <div className="mb-24p">
                {type === recordsType.CLAIM && selectedNo !== null && (
                    <ClaimRecord
                        claimNo={selectedNo}
                        employeeId={employeeId}
                        organizationId={orgId}
                        organizationAccess={organizationsAccess[orgId]}
                        print={true}
                    />
                )}
            </div>

            {selectedRecords.map((selectedRecord, index) => {
                if (selectedRecord.type === recordsType.LEAVE) {
                    return (
                        <LeaveRecord
                            key={index}
                            leaveNo={selectedRecord.no}
                            employeeId={employeeId}
                            organizationId={orgId}
                            organizationAccess={organizationsAccess[orgId]}
                            print={true}
                        />
                    );
                } else if (selectedRecord.type === recordsType.CLAIM) {
                    return (
                        <div className="mb-24p">
                            <ClaimRecord
                                key={index}
                                claimNo={selectedRecord.no}
                                employeeId={employeeId}
                                organizationId={orgId}
                                organizationAccess={organizationsAccess[orgId]}
                                print={true}
                            />
                        </div>
                    );
                }
            })}

            <ClaimLeaveHistoryTable
                employeeLeaves={claimHistoryData}
                handleClaimHistoryData={() => {
                    //blank row
                }}
                isLoading={false}
                print={true}
            />

            <FooterNote date={employeesLastFetched} />
        </div>
    );
}

export default EmployeeClaimLeavePrint;
