import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

const EventContext = createContext();

export const useEventContext = () => {
    const context = useContext(EventContext);
    if (!context) {
        throw new Error("No provider");
    }
    return context;
};

export const EventProvider = ({ children }) => {
    const [eventData, setEventData] = useState(null);
    const handleEvent = (data) => {
        setEventData(data);
    };
    return (
        <EventContext.Provider
            value={useMemo(
                () => ({ eventData, handleEvent }),
                [eventData, handleEvent]
            )}
        >
            {children}
        </EventContext.Provider>
    );
};
EventProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
