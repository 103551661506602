import React, { useState } from "react";
import { defaultPaginationItems } from "../../constants";
import SectionHeading from "../../components/SectionHeading";
import Pagination from "../../components/table/Pagination";
import GTM from "../../GTM";

const OrganizationResult = ({ organizations, selectOrganization }) => {
    const [pageNumber, setPageNumber] = useState(0);
    const [tableItems, setTableItems] = useState(defaultPaginationItems);
    const pagesVisited = pageNumber * tableItems;

    const getPageCount = () => {
        return Math.ceil(organizations.length / tableItems);
    };

    const changePage = (selected) => {
        setPageNumber(selected);
    };

    const handleItemsPerPageChange = (e) => {
        setTableItems(e);
        setPageNumber(0);
    };

    return (
        <div className="container-card search-return-content py-20p py-8p overflow-hidden">
            <SectionHeading
                heading="Select Organization"
                className="px-20p md-px-10p"
            />

            <div className="row border-bottom-1 px-20p md-px-10p m-0 pb-20p md-pb-7p">
                <div className="col-12 p-0">
                    <p className="mb-0 text-bold">
                        <span className="fs-sm">Employer</span>
                        <i className="fa-solid fa-chevron-down ml-10p md-ml-8p icon"></i>
                    </p>
                </div>
            </div>

            <div className="fixed-header custom-scrollbar">
                {organizations
                    .slice(
                        pagesVisited,
                        parseInt(pagesVisited) + parseInt(tableItems)
                    )
                    .map((o, i) => (
                        <OrganizationRow
                            organization={o}
                            index={i}
                            selectOrganization={selectOrganization}
                            key={i}
                        />
                    ))}
            </div>

            <div className="pt-20p md-pt-14p">
                {organizations.length > 10 && (
                    <Pagination
                        pageCount={getPageCount()}
                        gotoPage={changePage}
                        setPageSize={handleItemsPerPageChange}
                        pageSize={tableItems}
                        tabIndex={0}
                        fieldName="org selection"
                        pageName="searching an organization"
                    />
                )}
            </div>
        </div>
    );
};

export default React.memo(OrganizationResult);

function OrganizationRow({ organization, index, selectOrganization }) {
    const gtm = new GTM(null);

    return (
        <div
            className={
                "row align-items-center border-bottom-1 px-20p md-px-10p sm-py-20p " +
                (index % 2 === 0 ? "bg-even" : "bg-odd")
            }
            style={{
                minHeight: 64,
            }}
        >
            <div className="col-md-10 col-sm-8 sm-mb-10p">
                <span>{organization.ORGNIZATIONNAME}</span>
            </div>

            <div className="col-md-2 col-sm-4 md-text-center">
                <button
                    type="button"
                    className="btn-primary btn-select"
                    onClick={() => {
                        selectOrganization(organization);

                        gtm.Event(
                            "searching an organization",
                            "button",
                            "click",
                            "select",
                            "org selection",
                            "",
                            "select_organization",
                            `${organization.ORGNIZATIONNAME.toLowerCase()}`
                        );
                    }}
                >
                    Select
                </button>
            </div>
        </div>
    );
}
