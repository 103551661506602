import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrganizationRepository from "../../repositories/OrganizationRepository";
import { useLazyGetOrganizationAccessQuery } from "../../slices/employeesApiSlice";
import {
    addOrganizationAccessInfo,
    addSelectedOrganization,
} from "../../slices/organizationSlice";
import { clearHistory } from "../../slices/historySlice";

const useSelectedOrganizationsObserver = () => {
    const dispatch = useDispatch();

    const {
        selectedOrganizations: userSelectedOrganizations,
        organizationsAccess,
    } = useSelector((state) => state.organizations);

    //selected organization in report page
    const { selectedOrganizationsReports: userSelectedOrganizationReports } =
        useSelector((state) => state.reportOrganization);

    const { loaded, organizations: allOrganizations } = useSelector(
        (state) => state.userDetails
    );

    const url = window.location.pathname;

    const [getOrganizationsAccess, { data: organizationsAccessData = null }] =
        useLazyGetOrganizationAccessQuery();

    useEffect(() => {
        if (!loaded) return;

        const orgIds = [];

        for (const [organizationId, organization] of Object.entries(
            userSelectedOrganizations
        )) {
            if (organization.organization === null) {
                orgIds.push(parseInt(organizationId));
            }
        }

        updateOrganizations(orgIds);

        const selectedIds = OrganizationRepository.getSelectedOrganizationIds(
            url.includes("reportingcapabilities")
                ? userSelectedOrganizationReports
                : userSelectedOrganizations
        );

        const accessIds = [];

        selectedIds.forEach((selectedId) => {
            if (!(selectedId in organizationsAccess)) {
                accessIds.push(selectedId);
            }
        });

        if (accessIds.length > 0) {
            let accessIdsString = accessIds.join(",");
            getOrganizationsAccess({
                organizationIds: accessIdsString,
            });
        }
    }, [userSelectedOrganizations, loaded, userSelectedOrganizationReports]);

    const updateOrganizations = (ids) => {
        ids.forEach((id) => {
            const organization = OrganizationRepository.getOrganizationById(
                allOrganizations,
                id
            );

            if (organization === null) {
                dispatch(clearHistory());
            } else {
                const related = OrganizationRepository.getRelatedOrganizations(
                    allOrganizations,
                    organization
                );

                dispatch(
                    addSelectedOrganization({
                        id: organization.ORGANIZATIONID,
                        organization: organization,
                        related,
                    })
                );
            }
        });
    };

    useEffect(() => {
        if (
            organizationsAccessData !== null &&
            Array.isArray(organizationsAccessData.orgAccessList)
        ) {
            dispatch(
                addOrganizationAccessInfo(organizationsAccessData.orgAccessList)
            );
        }
    }, [organizationsAccessData]);

    return;
};

export default useSelectedOrganizationsObserver;
