import React from "react";
import useOutsideClick from "../hooks/useOutsideClick";
import $ from "jquery";
import GTM from "../GTM";

const DROPDOWN_ITEM_HEIGHT = 44;
const BORDER_HEIGHT = 2;

function OrganizationAutocompleteDropdown({
    organizations,
    hideOrganizationAutocompleteDropdown,
    organizationInputRef,
    selectOrganization,
}) {
    const dropdownRef = React.useRef(null);
    const dropdownOutsideClick = useOutsideClick(
        dropdownRef,
        organizationInputRef
    );
    const gtm = new GTM(null);
    React.useEffect(() => {
        if (dropdownOutsideClick === true)
            hideOrganizationAutocompleteDropdown();
    }, [dropdownOutsideClick]);
    const url = window.location.pathname
    
    return (
        <div ref={dropdownRef}>
            <div
                className="search-dropdown custom-scrollbar"
                style={{
                    height:
                        organizations.length *
                        (DROPDOWN_ITEM_HEIGHT + BORDER_HEIGHT),
                    maxHeight: DROPDOWN_ITEM_HEIGHT * 4 + BORDER_HEIGHT,
                    display: organizations.length === 0 ? "none" : "block",
                }}
            >
                <div className="custom-scrollbar-css">
                    {organizations.map((value) => (
                        <a
                            key={value.ORGANIZATIONID}
                            onClick={(e) => {
                                e.preventDefault();
                                selectOrganization(value);
                                gtm.Event(
                                    "search",
                                    "textbox",
                                    "interact",
                                    `${value.ORGNIZATIONNAME.toLowerCase()}`,
                                    "search",
                                    "",
                                    "field_interaction",
                                    url==="/preferences"?"":`${value.ORGNIZATIONNAME.toLowerCase()}`
                                );
                            }}
                            tabIndex="0"
                            className="organisationclass"
                            onKeyUp={(e) => {
                                if (e.key === "ArrowDown") {
                                    let control = $(e.target).next();
                                    $(control).trigger("focus");
                                }
                                if (e.key === "ArrowUp") {
                                    let control = $(e.target).prev();
                                    $(control).trigger("focus");
                                }
                                if (e.key === "Enter")
                                    selectOrganization(value);
                            }}
                        >
                            {value.ORGNIZATIONNAME}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OrganizationAutocompleteDropdown;
