import React from "react";

function Loader({ text = "Searching" }) {
    return (
        <div className="loader">
            <span>{text}</span>

            <div className="spinner-border" role="status"></div>
        </div>
    );
}

export default Loader;
