import COLUMNS from "./claim_record_columns";

const CLAIM_STATUS = "Claim Status";
const ADDITIONAL_DETAILS = "Additional Claim Detail";

export const groupLifeSections = [
    {
        type: CLAIM_STATUS,
        columns: [
            COLUMNS.CLAIM_NUMBER,
            COLUMNS.COVERAGE,
            COLUMNS.POLICY_NUMBER,
            COLUMNS.DIVISION,
            COLUMNS.CURRENT_STATUS,
            COLUMNS.STATUS_REASON,
            COLUMNS.RECEIVED_DATE,
            COLUMNS.PAYMENT_STATUS,
            COLUMNS.LAST_PAYMENT_DATE,
            COLUMNS.TOTAL_PAYMENT_AMOUNT,
            COLUMNS.CLAIM_HISTORY,
        ],
    },
    {
        type: ADDITIONAL_DETAILS,
        columns: [
            COLUMNS.INCURRED_DATE,
            COLUMNS.RETIREE_INDICATOR,
            COLUMNS.DATE_OF_DEATH,
            COLUMNS.CAUSE_OF_DEATH,
            COLUMNS.CLAIM_CLOSURE_DATE,
            COLUMNS.CLAIM_CLOSURE_REASON,
            COLUMNS.CLAIMANTS,
            COLUMNS.INSURED_RELATIONSHIP,
            COLUMNS.LIFE_BENEFITS_SPECIALIST,
        ],
    },
];

export const ltdSections = [
    {
        type: CLAIM_STATUS,
        columns: [
            COLUMNS.CLAIM_NUMBER,
            COLUMNS.COVERAGE,
            COLUMNS.POLICY_NUMBER,
            COLUMNS.DIVISION,
            COLUMNS.ELIGIBILITY_GROUP,
            COLUMNS.CHOICE,
            COLUMNS.CURRENT_STATUS,
            COLUMNS.STATUS_REASON,
            COLUMNS.RECEIVED_DATE,
            COLUMNS.LAST_PAYMENT_AMOUNT,
            COLUMNS.LAST_PAYMENT_DATE,
            COLUMNS.TOTAL_PAID_TO_DATE,
            COLUMNS.CLAIM_HISTORY,
        ],
    },
    {
        type: ADDITIONAL_DETAILS,
        columns: [
            COLUMNS.DISABILITY_DATE,
            COLUMNS.BENEFIT_START_DATE,
            COLUMNS.APPROVED_THROUGH_DATE,
            COLUMNS.MAX_DURATION_DATE,
            COLUMNS.CLAIM_CLOSURE_DATE,
            COLUMNS.CLAIM_CLOSURE_REASON,
            COLUMNS.MONTHS_PAID,
            COLUMNS.PAID_THROUGH_DATE,
            COLUMNS.WORK_RELATED,
            COLUMNS.SSDI_AWARD_DATE,
            COLUMNS.RELATED_LEAVES,
            COLUMNS.DISABILITY_BENEFITS_SPECIALIST,
        ],
    },
];

export const siStdSections = [
    {
        type: CLAIM_STATUS,
        columns: [
            COLUMNS.CLAIM_NUMBER,
            COLUMNS.COVERAGE,
            COLUMNS.POLICY_NUMBER,
            COLUMNS.DIVISION,
            COLUMNS.ELIGIBILITY_GROUP,
            COLUMNS.CHOICE,
            COLUMNS.CURRENT_STATUS,
            COLUMNS.STATUS_REASON,
            COLUMNS.RECEIVED_DATE,
            COLUMNS.LAST_PAYMENT_AMOUNT,
            COLUMNS.LAST_PAYMENT_DATE,
            COLUMNS.TOTAL_PAID_TO_DATE,
            COLUMNS.DAYS_PAID,
            COLUMNS.CLAIM_HISTORY,
        ],
    },
    {
        type: ADDITIONAL_DETAILS,
        columns: [
            COLUMNS.DISABILITY_DATE,
            COLUMNS.BENEFIT_START_DATE,
            COLUMNS.APPROVED_THROUGH_DATE,
            COLUMNS.MAX_DURATION_DATE,
            COLUMNS.RTW_FT,
            COLUMNS.RTW_PT,
            COLUMNS.CLAIM_CLOSURE_DATE,
            COLUMNS.CLAIM_CLOSURE_REASON,
            COLUMNS.DIAGNOSIS_CODE,
            COLUMNS.DIAGNOSIS_DESCRIPTION,
            COLUMNS.WORK_RELATED,
            COLUMNS.RELATED_LEAVES,
            COLUMNS.DISABILITY_BENEFITS_SPECIALIST,
        ],
    },
];

export const stdSections = [
    {
        type: CLAIM_STATUS,
        columns: [
            COLUMNS.CLAIM_NUMBER,
            COLUMNS.COVERAGE,
            COLUMNS.POLICY_NUMBER,
            COLUMNS.DIVISION,
            COLUMNS.ELIGIBILITY_GROUP,
            COLUMNS.CHOICE,
            COLUMNS.CURRENT_STATUS,
            COLUMNS.STATUS_REASON,
            COLUMNS.RECEIVED_DATE,
            COLUMNS.LAST_PAYMENT_AMOUNT,
            COLUMNS.LAST_PAYMENT_DATE,
            COLUMNS.TOTAL_PAID_TO_DATE,
            COLUMNS.DAYS_PAID,
            COLUMNS.CLAIM_HISTORY,
        ],
    },
    {
        type: ADDITIONAL_DETAILS,
        columns: [
            COLUMNS.DISABILITY_DATE,
            COLUMNS.BENEFIT_START_DATE,
            COLUMNS.APPROVED_THROUGH_DATE,
            COLUMNS.MAX_DURATION_DATE,
            COLUMNS.RTW_FT,
            COLUMNS.RTW_PT,
            COLUMNS.CLAIM_CLOSURE_DATE,
            COLUMNS.CLAIM_CLOSURE_REASON,
            COLUMNS.WORK_RELATED,
            COLUMNS.RELATED_LEAVES,
            COLUMNS.DISABILITY_BENEFITS_SPECIALIST,
        ],
    },
];

export const lifeWaiverSections = [
    {
        type: CLAIM_STATUS,
        columns: [
            COLUMNS.CLAIM_NUMBER,
            COLUMNS.COVERAGE,
            COLUMNS.POLICY_NUMBER,
            COLUMNS.DIVISION,
            COLUMNS.CURRENT_STATUS,
            COLUMNS.STATUS_REASON,
            COLUMNS.RECEIVED_DATE,
            COLUMNS.CLAIM_HISTORY,
            COLUMNS.BASE_COVERAGE,
            COLUMNS.SUPPLEMENTAL_COVERAGE,
            COLUMNS.TOTAL_COVERAGE,
        ],
    },
    {
        type: ADDITIONAL_DETAILS,
        columns: [
            COLUMNS.DISABILITY_DATE,
            COLUMNS.BENEFIT_START_DATE,
            COLUMNS.APPROVAL_DATE,
            COLUMNS.CLAIM_CLOSURE_DATE,
            COLUMNS.CLAIM_CLOSURE_REASON,
            COLUMNS.LIFE_BENEFITS_SPECIALIST,
        ],
    },
];

export const siLtdSections = [
    {
        type: CLAIM_STATUS,
        columns: [
            COLUMNS.CLAIM_NUMBER,
            COLUMNS.COVERAGE,
            COLUMNS.POLICY_NUMBER,
            COLUMNS.DIVISION,
            COLUMNS.ELIGIBILITY_GROUP,
            COLUMNS.CHOICE,
            COLUMNS.CURRENT_STATUS,
            COLUMNS.STATUS_REASON,
            COLUMNS.RECEIVED_DATE,
            COLUMNS.LAST_PAYMENT_AMOUNT,
            COLUMNS.LAST_PAYMENT_DATE,
            COLUMNS.TOTAL_PAID_TO_DATE,
            COLUMNS.CLAIM_HISTORY,
        ],
    },
    {
        type: ADDITIONAL_DETAILS,
        columns: [
            COLUMNS.DISABILITY_DATE,
            COLUMNS.BENEFIT_START_DATE,
            COLUMNS.APPROVED_THROUGH_DATE,
            COLUMNS.MAX_DURATION_DATE,
            COLUMNS.CLAIM_CLOSURE_DATE,
            COLUMNS.CLAIM_CLOSURE_REASON,
            COLUMNS.MONTHS_PAID,
            COLUMNS.PAID_THROUGH_DATE,
            COLUMNS.SSDI_AWARD_DATE,
            COLUMNS.DIAGNOSIS_CODE,
            COLUMNS.DIAGNOSIS_DESCRIPTION,
            COLUMNS.WORK_RELATED,
            COLUMNS.RELATED_LEAVES,
            COLUMNS.DISABILITY_BENEFITS_SPECIALIST,
        ],
    },
];
