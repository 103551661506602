import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DefinitionAndClaimComponent from "./DefinitionAndClaimContent";

function DefinitionContent({ showDefinitionContent, closeHelp, content, handleBack }) {
    const [show, setShow] = useState(showDefinitionContent);

    useEffect(() => {
        setShow(showDefinitionContent);
    }, [showDefinitionContent]);

    const handleClose = () => {
        setShow(false);
        closeHelp();
    };


    // Keydown event handler for scrolling
    const handleKeyDown = (event) => {
        const modalBody = document.querySelector('.modal-body');
        if (modalBody) {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                modalBody.scrollBy({ top: 1, behavior: 'smooth' });
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                modalBody.scrollBy({ top: -1, behavior: 'smooth' });
            }
        }
    };

    useEffect(() => {
        if (show) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [show]);

    return (
        <Modal
            show={show}
            dialogClassName="claim-diagnostic-section"
            size="xl"
            backdrop="true"
            onHide={handleClose}
            backdropClassName="helpContent-backdrop"
        >
            <Modal.Header style={{ justifyContent: "space-between" }}>
                <div
                    className="help-buttons align-items-center"
                    style={{ fontWeight: 700 }}
                    onClick={handleBack}
                >
                    <i className="fa-solid fa-angle-left"></i>
                    Back
                </div>
                <div
                    className="help-buttons align-items-center" style={{ paddingRight: 0 }}
                    onClick={handleClose}
                >
                    <i className="fa-solid fa-xmark"></i>
                    Close
                </div>
            </Modal.Header>
            <div className="header pt-20p">{content.Name}</div>
            <Modal.Body
                style={{
                    padding: content?.Content.length > 0 ? "0px 0px 0px 32px" : "0px 32px"
                }}
            >
                <div className="modalBodySection" style={{
                    maxHeight: "calc(100vh - 195px)",
                    overflowY: "auto",
                    height: 1000,
                    boxSizing: 'content-box'
                }}>
                    <div className="pr-32p card-section" style={{
                        boxSizing: "border-box",
                    }}>
                        <div style={{ width: "610px" }}>
                            <DefinitionAndClaimComponent content={content} />
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default DefinitionContent;
