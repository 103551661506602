import React from "react";
import ReportTable from "./ReportTable";

function ReportViewData({
    organizationIDISF,
    selectedReport,
    reportData,
    dataViewType,
    reportViewInformation,
    onViewOptionChange,
    onViewReportSave,
    allViewsData,
    loaderStatus,
    onViewReportUpdate,
    notificationStatus,
    reportTableData,
    isFetching,
    isUninitialized,
    isError,
    handleCoverageType,
    handleViewID,
    reportLastFetched,
    userEmail,
    filterValues,
    initialFiltersValue,
    portalUserApiError,
    organizationsAccess,
    emitFiltersDataForSelectedView,
    selectedFilterStatus,
    reportingPeriodValue,
    reportingStatusValue,
    selectedCoverageType,
    selectedPolicyDivision,
    includeHistoricalLeaveValue,
    periodScheduleTypeValue,
    periodStatusValue,
    protectionStatusValue,
    disclaimer,
    totalResults,
    isFetchingMore,
    portalUserData,
    totalPages,
    pagecounter,
    isDataFormattingComplete,
    columnFilters,
    setColumnFilters,
    organizationName,
    relatedOrganizationNames,
    selectedReportingGroup,
    isFetchLoading
}) {
    const handleOptionChange = (selectedOption) => {
        onViewOptionChange(selectedOption);
    };

    const handleViewReportSave = (item1, item2, item3, item4) => {
        onViewReportSave(item1, item2, item3, item4);
    };

    const handleViewReportUpdate = (item1, item2, item3, item4, item5) => {
        onViewReportUpdate(item1, item2, item3, item4, item5);
    };

    const genericSort = (rowA, rowB, columnId) => {
        const parseValue = (value) => {
            const valueString = String(value).trim();

            if (/^\d+$/.test(valueString)) {
                return { type: 'number', value: parseInt(valueString, 10) };
            }

            const hyphenMatch = valueString.match(/^(\d+)\s*-\s*(.*)/);
            if (hyphenMatch) {
                return {
                    type: 'hyphenated',
                    value: {
                        number: parseInt(hyphenMatch[1], 10),
                        text: hyphenMatch[2].toLowerCase(),
                    },
                };
            }

            if (/^[\w\s,]+/.test(valueString)) {
                return { type: 'text', value: valueString.toLowerCase() };
            }

            return { type: 'other', value: valueString.toLowerCase() };
        };

        const a = parseValue(rowA.getValue(columnId));
        const b = parseValue(rowB.getValue(columnId));

        const typeOrder = { number: 1, hyphenated: 2, text: 3, other: 4 };

        if (a.type !== b.type) {
            return typeOrder[a.type] - typeOrder[b.type];
        }

        if (a.type === 'number') {
            return a.value - b.value;
        } else if (a.type === 'hyphenated') {
            if (a.value.number !== b.value.number) {
                return a.value.number - b.value.number;
            } else {
                return a.value.text.localeCompare(b.value.text);
            }
        } else {
            return a.value.localeCompare(b.value);
        }
    };

    const sortingDtFn = (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId);
        const valueB = rowB.getValue(columnId);

        const parseValue = (value) => {
            const dateV = new Date(value);
            return dateV;
        };

        const a = parseValue(valueA);
        const b = parseValue(valueB);

        return a > b ? 1 : a < b ? -1 : 0;
    };
    const columnsData = () => {
        return reportViewInformation[0].ColumnInformation.map((obj, index) => {
            const hasDateKeyword = String(obj.id).toLocaleLowerCase().includes("date");
            const chosenSortFn = hasDateKeyword ? sortingDtFn : genericSort;

            return index !== null ? { ...obj, sortingFn: chosenSortFn } : obj;
        });
    };
    return (
        <ReportTable
            defaultColumns={columnsData()}
            allViewsData={allViewsData}
            claimData={reportTableData || []}
            handleOptionChange={handleOptionChange}
            isFetching={isFetching}
            isUninitialized={isUninitialized}
            isError={isError}
            organizationId={organizationIDISF}
            selectedReportView={selectedReport}
            reportData={reportData}
            dataViewType={dataViewType}
            handleViewReportSave={handleViewReportSave}
            loaderStatus={loaderStatus}
            handleViewReportUpdate={handleViewReportUpdate}
            handleCoverageType={handleCoverageType}
            notificationStatus={notificationStatus}
            description={reportViewInformation[0].Description}
            selectedViewId={reportViewInformation[0].ViewId}
            handleViewID={handleViewID}
            reportViewType={reportViewInformation[0].DataViewType}
            reportLastFetched={reportLastFetched}
            userEmail={userEmail}
            filterValues={filterValues}
            initialFiltersValue={initialFiltersValue}
            portalUserApiError={portalUserApiError}
            organizationsAccess={organizationsAccess}
            emitFiltersDataForSelectedView={emitFiltersDataForSelectedView}
            selectedFilterStatus={selectedFilterStatus}
            reportingPeriodValue={reportingPeriodValue}
            reportingStatusValue={reportingStatusValue}
            selectedCoverageType={selectedCoverageType}
            selectedPolicyDivision={selectedPolicyDivision}
            includeHistoricalLeaveValue={includeHistoricalLeaveValue}
            periodScheduleTypeValue={periodScheduleTypeValue}
            periodStatusValue={periodStatusValue}
            protectionStatusValue={protectionStatusValue}
            reportSortingColumnName={
                reportViewInformation?.[0]?.SortOrder?.ColumnDisplayOrderId
                    ? columnsData()[
                        reportViewInformation[0].SortOrder
                            .ColumnDisplayOrderId - 1
                    ]?.id
                    : []
            }
            reportSortingColumnDirection={
                reportViewInformation?.[0]?.SortOrder?.Direction === "ASC"
                    ? false
                    : true
            }
            disclaimer={disclaimer}
            totalResults={totalResults}
            isFetchingMore={isFetchingMore}
            portalUserData={portalUserData}
            totalPages={totalPages}
            pagecounter={pagecounter}
            isDataFormattingComplete={isDataFormattingComplete}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            organizationName={organizationName}
            relatedOrganizationNames={relatedOrganizationNames}
            selectedReportingGroup={selectedReportingGroup}
            isFetchLoading={isFetchLoading}
        />
    );
}

export default React.memo(ReportViewData);
