import React, { useEffect } from "react";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import { useParams, Link } from "react-router-dom";
import {
    capitalizeFirstLetter,
    getReportBreadcrumbLinks,
} from "../../utils/helpers";
import reports from "./reports.json";
import extractReport from "./extractReports.json";
import RadioButtons from "./RadioButtons";
import { useSelector } from "react-redux";
import Divider from "../../components/ui/Divider";
import GTM from "../../GTM";
import { useLazyGetCustomUsersQuery } from "../../slices/tableauUserApiSlice";


const ReportSelection = () => {
    const { type } = useParams();

    const { lastSelectedOrganization, lastSelectedEmployee } = useSelector(
        (state) => state.organizations
    );
    const gtm = new GTM(null);
    const links = [
        ...getReportBreadcrumbLinks(
            lastSelectedOrganization,
            lastSelectedEmployee
        ),
        {
            name: `${capitalizeFirstLetter(type)} Reports`,
        },
    ];

    const [
        getUser,
        {
            data: UserGroup = null,
            isError: UserError,
            isSuccess: isSuccessUser,
        },
    ] = useLazyGetCustomUsersQuery();

    useEffect(() => {
        getUser();
    }, []);

    const showUserReport =
        UserError === false && isSuccessUser === true;

    const isGroupNames = UserGroup?.groups.map(
        (group) => group.groupName
    );

    const renderStandardReports = () => {
        return (
            <div className="section-card">
                <div className="row">
                    {reports.map((group) => {
                        const isUserPresent = showUserReport && isGroupNames?.includes(group.user);

                        const isUserAvailable = (showUserReport) && (isUserPresent);

                        const availableUrls = group.urls.filter(
                            (url) => url.url !== "" && isUserAvailable
                        );
                        const disabledUrls = group.urls.filter(
                            (url) =>
                                url.url === "" ||
                                ((!isUserPresent) && (!showUserReport) && !isUserAvailable)
                        );

                        return (
                            <div className="col" key={group.type}>
                                <span className="section-heading text-bold d-flex pb-20p">
                                    {group.name}
                                </span>
                                {availableUrls.map((url) => (
                                    <div
                                        className="section-subheading"
                                        key={url.id}
                                    >
                                        <div className="customtooltip">
                                            <Link
                                                to={`/reports/${type}/${group.type}/${url.id}`}
                                                onClick={() =>
                                                    gtm.Event(
                                                        "standard reports",
                                                        "button",
                                                        "click",
                                                        url.name,
                                                        "standard reports",
                                                        `${group.type} reporting`,
                                                        "report_navigation",
                                                        ""
                                                    )
                                                }
                                            >
                                                <div
                                                    className="text-bold fs"
                                                    style={{ color: "#025780" }}
                                                >
                                                    {url.name}
                                                </div>
                                                <div
                                                    className="customtooltip"
                                                    style={{
                                                        paddingBottom:
                                                            url.id !== 2 &&
                                                            group.name ===
                                                                "Leave Reporting"
                                                                ? 16
                                                                : 0,
                                                    }}
                                                >
                                                    {url.msg1}
                                                    <br />
                                                    {url.msg2}
                                                </div>
                                            </Link>
                                            <div className="customTooltipText">
                                                {url.msg}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {group.name !== "Life Reporting" &&
                                    isUserAvailable && (
                                        <Divider mb="16" mt="16" />
                                    )}
                                <div className="comingsoontext">
                                    Coming soon! Please continue to use Claims &
                                    Leave InSight (CLI) for the following
                                    reports:
                                    {disabledUrls.map((url) => (
                                        <React.Fragment key={url.id}>
                                            <div className="disable-report">
                                                {url.name}
                                            </div>
                                            <div className="disable-report-message">
                                                {url.msg1}
                                                <br />
                                                {url.msg2}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderExtractReport = () => (
        <div className="section-card ">
            {extractReport
                .filter((url) => url.url !== "")
                .map((url) => (
                    <>
                        {url.url === "" ? (
                            <div
                                className="section-subheading pb-20p"
                                style={{
                                    display: "grid",
                                    cursor: "pointer",
                                    color: "#025780",
                                }}
                                key={url.id}
                            >
                                <div className="customtooltip">
                                    <Link to={`/reports/${url.name}/${url.id}`}>
                                        <div
                                            className="text-bold"
                                            style={{ fontSize: 16 }}
                                        >
                                            {url.name}
                                        </div>
                                        <div className="customtooltip">
                                            {url.msg1}
                                        </div>
                                    </Link>
                                    <div className="customTooltipText">
                                        {url.msg}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="section-subheading pb-20p"
                                style={{
                                    display: "grid",
                                    cursor: "default",
                                    color: "#AAAAAA",
                                }}
                                key={url.id}
                            >
                                <div
                                    className="text-bold"
                                    style={{ fontSize: 16 }}
                                >
                                    {url.name}
                                </div>
                                <div className="customTooltipText">
                                    {url.msg}
                                </div>
                            </div>
                        )}
                    </>
                ))}
            <div className="comingsoontext ">
                Coming soon! Please continue to use Claims & Leave InSight (CLI)
                for the following reports:
                {extractReport
                    .filter((url) => url.url === "")
                    .map((url) => (
                        <>
                            <div key={url.id} className="disable-report">
                                {url.name}
                            </div>
                            <div className="customtooltip">{url.msg1}</div>
                        </>
                    ))}
            </div>
        </div>
    );

    return (
        <>
            <Breadcrumb links={links} />
            <RadioButtons selected={type} />
            <div className=" ">
                <div className="row">
                    <div className="col-md-12">
                        {type === "custom" && (
                            <div
                                className="section-card"
                                style={{ minHeight: "60vh" }}
                            ></div>
                        )}
                        {type === "extract" && renderExtractReport()}
                        {type === "standard" && renderStandardReports()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportSelection;
