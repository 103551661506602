import React from "react";
import NoData from "../../components/alerts/NoData";
import SectionSubheading from "../../components/SectionSubheading";
import Table from "../../components/table/Table";
import Divider from "../../components/ui/Divider";
import { sort } from "../../utils/helpers";

function AvailableTime({ print, data }) {
    const columns = React.useMemo(
        () => [
            {
                Header: "Available Time",
                columns: [
                    {
                        Header: " ",
                        accessor: () => "",
                        disableSortBy: true,
                        width: 35,
                    },
                    {
                        Header: "Protection Description",
                        accessor: (row) => row.ProtectionDescription,
                        disableSortBy: true,
                    },
                    {
                        Header: "Hours Taken",
                        accessor: (row) => row.HoursTaken,
                        disableSortBy: true,
                    },
                    {
                        Header: "Hours Available *",
                        accessor: (row) =>
                            row.HoursAvailable === "0.00" &&
                            row.ProtectionEntitlementQty === null
                                ? "N/A"
                                : row.HoursAvailable,
                        disableSortBy: true,
                    },
                ],
            },
        ],
        []
    );

    let controlHeight = "auto";

    if (!print && data.length > 10) {
        controlHeight = 269;
    }

    return (
        <>
            <SectionSubheading
                subheading="Available Time"
                className="px-20p mt-10p mb-20p"
            />

            {!Array.isArray(data) || data.length === 0 ? (
                <div className="">
                    <NoData className="pb-0" />
                    <Divider className="mt-0" mx="" />
                </div>
            ) : (
                <Table
                    columns={columns}
                    pagination={!print && data.length > 10}
                    data={sort(data, "ProtectionDescription")}
                    note="* Note: This field will display N/A when protection
                            availability is variable, undefined or indefinite"
                    className="pb-10p print-data available-time-table"
                    attribute1="available time"
                    pageName="claim & leave"
                    responsiveConfig={{
                        type: "",
                        striped: false,
                        screen: "md",
                        bordered: true,
                    }}
                    height={controlHeight}
                    defaultPageSize={5}
                />
            )}
        </>
    );
}

export default AvailableTime;
