import { createSlice } from "@reduxjs/toolkit";
import OrganizationAccess from "../models/OrganizationAccess";

const organizationSlice = createSlice({
    name: "organizations",
    initialState: {
        selectedOrganizations: {},

        organizationsAccess: {},
        lastSelectedOrganization: null,
        totalParentOrganizations: 0,
        currentSelectedOrganization: null,
        lastSelectedEmployee: null,
        organizationsCount: null,
        selectedOrganizationsId: {},
    },
    reducers: {
        setTotalParentOrganization(state, action) {
            state.totalParentOrganizations = action.payload;
        },
        setOrganizationsCount(state, action) {
            state.organizationsCount = action.payload;
        },
        addSelectedOrganization(state, action) {
            state.selectedOrganizations[action.payload.id] = {
                organization: action.payload.organization,
                related: action.payload.related,
            };
        },

        setSelectedOrgainzationsId(state, action) {
            state.selectedOrganizationsId = action.payload;
        },

        addOrganizationAccessInfo(state, action) {
            if (Array.isArray(action.payload)) {
                const accessInfos = action.payload;

                accessInfos.forEach((info) => {
                    state.organizationsAccess[info.OrganizationId] =
                        OrganizationAccess._format(info);
                });
            }
        },
        addLastSelectedOrganization(state, action) {
            state.lastSelectedOrganization = action.payload;
        },
        addLastSelectedEmployee(state, action) {
            state.lastSelectedEmployee = action.payload;
        },
        clearSelectedOrganizations(state, action) {
            state.selectedOrganizations = {};
            state.organizationsAccess = {};
            state.lastSelectedOrganization = null;
            state.totalParentOrganizations = 0;
            state.currentSelectedOrganization = null;
        },
        setCurrentSelectedOrganization(state, action) {
            state.currentSelectedOrganization =
                state.selectedOrganizations[action.payload]?.organization;
        },
    },
});

export const {
    setTotalParentOrganization,
    setOrganizationsCount,
    addSelectedOrganization,

    addOrganizationAccessInfo,
    addLastSelectedOrganization,
    clearSelectedOrganizations,
    setCurrentSelectedOrganization,
    addLastSelectedEmployee,
    setSelectedOrgainzationsId,
} = organizationSlice.actions;

export default organizationSlice.reducer;
