import React, { useState } from "react";
import manageViewsIcon from "../../assets/icons/manage-views-icon.svg";
import SectionHeading from "../../components/SectionHeading";
import Table from "../../components/table/Table";
import DataViewIcon from "./DataViewIcons";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/ui/Loader";
import GTM from "../../GTM";

function FrequentlyUsedViews({ data, isFetching, portalUserData, isError }) {
    const gtm = new GTM(null);
    const [showTable, setShowTable] = useState(true);

    const [activeButton, setActiveButton] = useState("mostUsed");

    const handelGtmTags = (label, attribute3) => {
        gtm.Event(
            "run reports",
            "button",
            "click",
            label,
            "reports",
            "frequently used views",
            attribute3,
            ""
        );
    };

    const navigate = useNavigate();

    const handleManageViewsClick = () => {
        navigate("/reportingcapabilities/manageviews", { state: { data ,portalUserData,isError} });
        handelGtmTags("manage my reports", "navigation_click");
    };

    const handleButtonClick = (buttonType, label) => {
        setShowTable(true);

        setActiveButton(buttonType);
        handelGtmTags(label, "field_interaction");
    };

    const renderTable = () => (
        <Table
            data={
                activeButton === "mostUsed"
                    ? getMostUsedData() || []
                    : getMostRecentData() || []
            }
            columns={getColumns()}
            pagination={false}
            className="frequently-used-data"
            attribute1="reports"
            pageName="frequently used reports"
            responsiveConfig={{
                type: "ROW",
                striped: false,
                screen: "md",
            }}
            defaultPageSize={10}
        />
    );

    const getMostUsedData = () => {
        const filteredData = data?.filter((item) => item.MostUsedOrder);

        const sortedData = filteredData?.sort(
            (a, b) => a.MostUsedOrder - b.MostUsedOrder
        );

        return sortedData?.slice(0, 10);
    };

    const getMostRecentData = () => {
        const filteredData = data?.filter((item) => item.MostRecentOrder);
        const sortedData = filteredData?.sort(
            (a, b) => a.MostRecentOrder - b.MostRecentOrder
        );

        return sortedData?.slice(0, 10);
    };
    const handleReportNameClick = (selectedName) => {
        const selectedReport = data.find((item) => item.Name === selectedName);
        const selectedReportTypeData = data.filter((obj) => {
            if (
                obj.DataViewType.includes(
                    selectedReport.DataViewType.slice(0, 4)
                )
            ) {
                return obj.Name;
            }
        });
        navigate(`/reportingcapabilities/${selectedReport.DataViewType==="Payments"?"PaymentHistory":selectedReport.DataViewType}`, {
            state: {
                selectedReport,
                selectedReportTypeData,
                portalUserData,
                isError,
            },
        });
        handelGtmTags("detail view", "navigation_click");
    };

    const getColumns = () => [
        {
            Header: "B",
            columns: [
                {
                    Header: "Report name",
                    accessor: (row) => (
                        <DataViewIcon
                            dataViewType={row}
                            onClick={handleReportNameClick}
                        />
                    ),
                    disableSortBy: true,
                    tabIndex: 0,
                    width: 50,
                },
                {
                    Header: "Type",
                    accessor: (row) => row.OwnerType,
                    disableSortBy: true,
                    tabIndex: 0,
                    width: 40,
                },
                {
                    Header: "Description",
                    accessor: (row) => row.Description,
                    disableSortBy: true,
                    tabIndex: 0,
                    width: 120,
                },
            ],
        },
    ];

    return (
        <div className="section-card frequently-used-views">
            <div className="d-flex justify-content-between p-4">
                <div className="d-flex align-items-center gap-3">
                    <SectionHeading heading="Frequently used" mb="0" />
                    <div className="button-group">
                        <div
                            className={`most-used ${
                                activeButton === "mostUsed"
                                    ? "most-used-active"
                                    : ""
                            }`}
                            onClick={() =>
                                handleButtonClick("mostUsed", "most used")
                            }
                        >
                            Most used
                        </div>
                        <div
                            className={`most-recent ${
                                activeButton === "mostRecent"
                                    ? "most-recent-active"
                                    : ""
                            }`}
                            onClick={() =>
                                handleButtonClick("mostRecent", "most recent")
                            }
                        >
                            Most recent
                        </div>
                    </div>
                </div>

                <div className="export-to-excel">
                    <div
                        className="manage-views-link"
                        onClick={handleManageViewsClick}
                        style={{ cursor: "pointer" }}
                    >
                        Manage my reports{" "}
                        <img
                            src={manageViewsIcon}
                            alt="manage my views"
                            width="20px"
                        />
                    </div>
                </div>
            </div>

            {isFetching ? <Loader /> : showTable && renderTable()}
        </div>
    );
}

export default FrequentlyUsedViews;
