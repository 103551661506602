import { createSlice } from "@reduxjs/toolkit";
import { recordsType } from "../constants";

const leaveClaimRecordsSlice = createSlice({
    name: "leaveClaimRecords",
    initialState: {
        selectedNo: null,
        selectedLeaveNos: [],
        selectedClaimNos: [],
        relatedLeaves: [],
        relatedClaims: [],
        type: "",
        lastUpdatedId: {
            id: "",
            timestamp: false,
        },
        selectedRecords: [],
    },
    reducers: {
        addLeaveNo(state, action) {
            const leaveNo = action.payload;

            if (state.selectedNo === null) {
                // Reset
                state.selectedClaimNos = [];
                state.selectedLeaveNos = [leaveNo];
                state.relatedClaims = [];
                state.relatedLeaves = [];
                state.type = recordsType.LEAVE;
                state.selectedNo = action.payload;
                state.selectedRecords = [];
                state.lastUpdatedId = {
                    id: "leave-container-" + action.payload,
                    timestamp: !state.lastUpdatedId.timestamp,
                };
            } else {
                if (state.relatedLeaves.includes(leaveNo)) {
                    if (!state.selectedLeaveNos.includes(leaveNo)) {
                        state.selectedLeaveNos.push(leaveNo);

                        state.selectedRecords.push({
                            no: leaveNo,
                            type: recordsType.LEAVE,
                        });
                    }
                    state.lastUpdatedId = {
                        id: "leave-container-" + leaveNo,
                        timestamp: !state.lastUpdatedId.timestamp,
                    };
                } else {
                    state.selectedClaimNos = [];
                    state.selectedLeaveNos = [leaveNo];
                    state.relatedClaims = [];
                    state.relatedLeaves = [];
                    state.type = recordsType.LEAVE;
                    state.selectedNo = leaveNo;
                    state.selectedRecords = [];
                    state.lastUpdatedId = {
                        id: "leave-container-" + leaveNo,
                        timestamp: !state.lastUpdatedId.timestamp,
                    };
                }
            }
        },
        addClaimNo(state, action) {
            const claimNo = action.payload;

            if (state.selectedNo === null) {
                // Reset
                state.selectedClaimNos = [claimNo];
                state.selectedLeaveNos = [];
                state.relatedClaims = [];
                state.relatedLeaves = [];
                state.selectedRecords = [];
                state.type = recordsType.CLAIM;
                state.selectedNo = action.payload;
                state.lastUpdatedId = {
                    id: "claim-container-" + action.payload,
                    timestamp: !state.lastUpdatedId.timestamp,
                };
            } else {
                if (state.relatedClaims.includes(claimNo)) {
                    if (!state.selectedClaimNos.includes(claimNo)) {
                        state.selectedClaimNos.push(claimNo);

                        state.selectedRecords.push({
                            no: claimNo,
                            type: recordsType.CLAIM,
                        });
                    }

                    state.lastUpdatedId = {
                        id: "claim-container-" + claimNo,
                        timestamp: !state.lastUpdatedId.timestamp,
                    };
                } else {
                    state.selectedClaimNos = [claimNo];
                    state.selectedLeaveNos = [];
                    state.relatedClaims = [];
                    state.relatedLeaves = [];
                    state.type = recordsType.CLAIM;
                    state.selectedNo = claimNo;
                    state.selectedRecords = [];
                    state.lastUpdatedId = {
                        id: "claim-container-" + claimNo,
                        timestamp: !state.lastUpdatedId.timestamp,
                    };
                }
            }
        },
        addRelatedNos(state, action) {
            const payload = action.payload;

            if (payload.type === recordsType.LEAVE) {
                const currentRelatedLeaves = [...state.relatedLeaves];
                const payloadRelatedLeaves = payload.nos;

                payloadRelatedLeaves.forEach((n) => {
                    if (!currentRelatedLeaves.includes(n)) {
                        currentRelatedLeaves.push(n);
                    }
                });

                state.relatedLeaves = currentRelatedLeaves;
            } else if (payload.type === recordsType.CLAIM) {
                const currentRelatedClaims = [...state.relatedClaims];
                const payloadRelatedClaims = payload.nos;

                payloadRelatedClaims.forEach((n) => {
                    if (!currentRelatedClaims.includes(n)) {
                        currentRelatedClaims.push(n);
                    }
                });

                state.relatedClaims = currentRelatedClaims;
            }
        },
        resetRelatedNos(state, _action) {
            state.selectedClaimNos = [];
            state.selectedLeaveNos = [];
            state.relatedClaims = [];
            state.relatedLeaves = [];
            state.type = "";
            state.selectedNo = null;
            state.selectedRecords = [];
            state.lastUpdatedId = {
                id: "",
                timestamp: false,
            };
        },
    },
});

export const { addLeaveNo, addClaimNo, addRelatedNos, resetRelatedNos } =
    leaveClaimRecordsSlice.actions;

export default leaveClaimRecordsSlice.reducer;
