import React from "react";

function SortIcon({ column }) {
    return (
        <div className="d-block text-center">
            {column.isSorted && (
                <i
                    style={{
                        color: "#025780",
                    }}
                    className={
                        "fa-solid " +
                        (column.isSortedDesc ? "fa-arrow-up" : "fa-arrow-down")
                    }
                ></i>
            )}
        </div>
    );
}

export default SortIcon;
