import { createSlice } from "@reduxjs/toolkit";

const historySlice = createSlice({
    name: "history",
    initialState: {},
    reducers: {
        persist(state, action) {
            state[action.payload.key] = {
                ...state[action.payload.key],
                ...action.payload,
            };
        },
        clearHistory(state, _) {
            for (let key in state) {
                delete state[key];
            }
        },
    },
});

export const { persist, clearHistory } = historySlice.actions;

export default historySlice.reducer;
