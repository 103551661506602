import React from "react";

export default function ReportSaveErrorNotification({ onCloseNotification }) {
    const handleCloseNotification = () => {
        onCloseNotification();
    };

    return (
        <div className="d-flex  justify-content-end">
            <div
                className="position-absolute top-0 end-1 m-3"
                style={{ right: 45 }}
            >
                <div className="mt-20">
                    <div
                        style={{
                            width: 368,
                            height: 147,
                            padding: 24,
                            background: "#FAEAEA",
                            boxShadow: "0px 2px 16px rgba(46, 46, 46, 0.15)",
                            borderRadius: 8,
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                            gap: 16,
                            display: "inline-flex",
                        }}
                    >
                        <div
                            style={{
                                alignSelf: "stretch",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                display: "inline-flex",
                            }}
                        >
                            <div
                                style={{
                                    color: "#b30303",
                                    fontSize: 20,
                                    fontFamily: "Open Sans",
                                    fontWeight: "700",
                                    wordWrap: "break-word",
                                }}
                            >
                                Error
                            </div>
                            <div
                                style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                <div
                                    style={{
                                        color: "#b30303",
                                        fontSize: 16,
                                        fontFamily: "Font Awesome 6 Pro",
                                        fontWeight: "900",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    <i
                                        className="fa-solid fa-circle-xmark cursor-pointer"
                                        onClick={handleCloseNotification}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                alignSelf: "stretch",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                display: "inline-flex",
                            }}
                        >
                            <div
                                style={{
                                    flex: "1 1 0",
                                    color: "#525252",
                                    fontSize: 16,
                                    fontFamily: "Open Sans",
                                    fontWeight: "400",
                                    wordWrap: "break-word",
                                }}
                            >
                                Something went wrong.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
