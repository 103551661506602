import React from "react";

function NoData({ className }) {
    return (
        <div className={"text-center " + className} tabIndex="0">
            <p className="mb-0 pb-3p ">No Data</p>
        </div>
    );
}

export default NoData;
