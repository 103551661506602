import React from "react";
import config from "../../config";
import { useNavigate, useSearchParams } from "react-router-dom";

const RadioButtons = ({ selected }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return (
        <>
            {" "}
            {(searchParams.get("reports") === "true" ||
                (config.REACT_APP_ENV !== "staging" &&
                    config.REACT_APP_ENV !== "prod")) && (
                <div className="section-card">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex custom-radio">
                                <div className="mr-20p">
                                    <input
                                        id="standard"
                                        type="radio"
                                        name="report"
                                        checked={selected === "standard"}
                                        onChange={() =>
                                            navigate(
                                                `/reports/standard?reports=${searchParams.get(
                                                    "reports"
                                                )}`
                                            )
                                        }
                                    />

                                    <label
                                        className="text-bold pb-0"
                                        htmlFor="standard"
                                    >
                                        Standard Reports
                                    </label>
                                </div>

                                <div className="mr-20p">
                                    <input
                                        id="custom"
                                        type="radio"
                                        name="report"
                                        checked={selected === "custom"}
                                        onChange={() =>
                                            navigate(
                                                `/reports/custom?reports=${searchParams.get(
                                                    "reports"
                                                )}`
                                            )
                                        }
                                    />

                                    <label
                                        className="text-bold pb-0"
                                        htmlFor="custom"
                                    >
                                        Custom Reports
                                    </label>
                                </div>
                                <div>
                                    <input
                                        id="extract"
                                        type="radio"
                                        name="report"
                                        checked={selected === "extract"}
                                        onChange={() =>
                                            navigate(
                                                `/reports/extract?reports=${searchParams.get(
                                                    "reports"
                                                )}`
                                            )
                                        }
                                    />

                                    <label
                                        className="text-bold pb-0"
                                        htmlFor="extract"
                                    >
                                        Data Extracts
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RadioButtons;
