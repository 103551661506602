import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config";
import { cacheDataForInSeconds } from "../constants";

let reactBaseurl = "";
const { user } = (state) => state.auth;
if (user !== null) {
    if (user?.isUserInternal === true) {
        reactBaseurl = reactBaseurl;
    } else if (user?.isUserInternal === false) {
        reactBaseurl = config.REACT_APP_EXTERNAL_PREFERENCE_API_URL;
    }
}

const userPreferenceApiSlice = createApi({
    reducerPath: "UserPreferences",
    keepUnusedDataFor: cacheDataForInSeconds ?? 300,
    refetchOnMountOrArgChange: cacheDataForInSeconds ?? 300,
    baseQuery: fetchBaseQuery({
        baseUrl: config.REACT_APP_API_URL,
        credentials: "include",
        prepareHeaders: (headers) => {
            headers.set("Accept", "application/json");
            headers.set("Content-Type", "application/json");

            const apiKey = process.env.REACT_APP_API_KEY;
            if (apiKey) headers.set("apikey", apiKey);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getUserPreferenceData: builder.query({
            query: ({ userId }) => ({
                url: `/user-data/${process.env.REACT_APP_API_VERSION}/${userId}${config.REACT_APP_ENV_NAME}`,
                method: "GET",
                params: "",
            }),
        }),
        updateUserData: builder.mutation({
            query: ({ userId, dataToUpdate }) => ({
                url: `/user-data/${process.env.REACT_APP_API_VERSION}/${userId}${config.REACT_APP_ENV_NAME}`,
                method: "PATCH",
                body: JSON.stringify(dataToUpdate),
            }),
        }),
    }),
});

export const {
    useUpdateUserDataMutation,
    useGetUserPreferenceDataQuery,
    useLazyGetUserPreferenceDataQuery,
} = userPreferenceApiSlice;

export default userPreferenceApiSlice;
