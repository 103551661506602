import React from "react";
import { BreadcrumbPortal } from "./ui/Breadcrumb";

function SubHeader() {
    return (
        <div className="subheader">
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{ padding: 0 }}>
                        <div className="breadcrumbs d-flex justify-content-between align-items-center px-24p">
                            <BreadcrumbPortal />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubHeader;
