import React from "react";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../constants";
import GTM from "../../GTM";

function Pagination({
    pageCount,
    gotoPage,
    setPageSize,
    pageSize,
    paginationClassName,
    fieldName,
    pageName,
    options = paginationOptions,
    setPageNumberOnRefresh,
}) {
    const [selectedPage, setSelectedPage] = React.useState(
        setPageNumberOnRefresh ? setPageNumberOnRefresh : 0
    );

    const gtm = new GTM(null);

    const handleChange = (
        pageName,
        category,
        action,
        label,
        fieldName,
        typeOfInteraction,
        selectedValue
    ) => {
        gtm.Event(
            pageName,
            category,
            action,
            label,
            fieldName,
            "",
            typeOfInteraction,
            selectedValue
        );
    };

    return (
        <div className={paginationClassName}>
            <div
                className={
                    "d-flex justify-content-between px-20p md-px-10p flex-col"
                }
                style={{
                    height: 24,
                }}
            >
                <div className="flex-1 md-mb-10p md-text-center" tabIndex={0}>
                    <span className="items fs-xs fs-14">Items per page</span>

                    <select
                        className="select-dropdown ml-10p md-ml-7p fs-xs md-sel-pagi"
                        tabIndex={0}
                        aria-label="Items per page"
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            gotoPage(0);
                            setSelectedPage(0);
                            handleChange(
                                pageName,
                                "dropdown",
                                "select",
                                "items per page",
                                fieldName,
                                "field_interaction",
                                `${e.target.value}`
                            );
                        }}
                        value={pageSize}
                        style={{
                            padding: "2px 10px",
                            paddingRight: 22,
                        }}
                    >
                        {options.map((paginationOption) => (
                            <option
                                key={paginationOption}
                                value={paginationOption}
                            >
                                {paginationOption}
                            </option>
                        ))}
                    </select>
                </div>

                <ReactPaginate
                    nextLabel={<NextLabel />}
                    pageCount={pageCount}
                    forcePage={selectedPage}
                    tabIndex={0}
                    onPageChange={(e) => {
                        gotoPage(e.selected);
                        setSelectedPage(e.selected);
                        handleChange(
                            pageName,
                            "button",
                            "click",
                            "pagination",
                            fieldName,
                            "navigation_click",
                            ""
                        );
                    }}
                    previousLabel={<PreviousLabel />}
                    pageClassName="page-item"
                    pageLinkClassName="page-link bg-none"
                    previousClassName="page-item"
                    previousLinkClassName="page-link bg-none"
                    nextClassName="page-item"
                    nextLinkClassName="page-link bg-none"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination flex-1"
                    activeClassName="active"
                />

                <div className="flex-1 md-d-none"></div>
            </div>
        </div>
    );
}

function PreviousLabel() {
    return (
        <span className="d-flex align-items-center" tabIndex={0}>
            <i className="fa-solid fa-chevron-left fs-body"></i>
            <span className="ml-7p md-d-none">Previous</span>
        </span>
    );
}

function NextLabel() {
    return (
        <span className="d-flex align-items-center" tabIndex={0}>
            <span className="mr-7p md-d-none">Next</span>
            <i className="fa-solid fa-chevron-right fs-body"></i>
        </span>
    );
}

export default Pagination;
