import { React, useEffect } from "react";
import GTM from "../../GTM";

function RequestFailed({ message, label, attribute1, attribute3, preferencePageError }) {
    const gtm = new GTM(null);

    useEffect(() => {
        setTimeout(() => {
            gtm.Event(
                label,
                "field level error",
                "error",
                label,
                attribute1,
                "",
                attribute3,
                `${message ??
                "Your request failed, please try again or contact us for further assistance."
                }`
            );
        }, 5000);
    }, []);
    return (
        <div className="notify-failed"
            style={{
                marginTop: preferencePageError ? 0 : "",
                marginBottom: preferencePageError ? 24 : ""
            }}
            tabIndex={0}>
            <p className="mb-0">
                {message ??
                    "Your request failed, please try again or contact us for further assistance."}

                <i className="fa-solid fa-triangle-exclamation alert-icon"></i>
            </p>
        </div>
    );
}

export default RequestFailed;
